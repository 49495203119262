import react, { useEffect, useState } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import './apollo.css';
import './Mountains.css';
import './Bikes.css';
import './kzn.css';
import Swal from 'sweetalert2'
import { useAuth } from './AuthProvider';


function Properties({ building, setBuildingList, apartment, setApartmentList }) {

    const [info, setInfo] = useState([]);
    const [isLoading, setLoading] = useState(true);
    const [admin, setAdmin] = useState({});
    const [error, setError] = useState(null);
    const [general, setGeneral] = useState(null);
    const { userId } = useAuth();
    const [activeSection, setActiveSection] = useState('userProfile');
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [filteredAdmins, setFilteredAdmins] = useState([]);
    const [filteredRoomsAvail, setFilteredRoomsAvail] = useState([]);
    const [filteredRooms, setFilteredRooms] = useState([]);

    const [cust, setCust] = useState([]);
    const [nonTenant, setNonTenant] = useState([]);
    const [expandedBuildings, setExpandedBuildings] = useState({});

    const [selectedBuilding, setSelectedBuilding] = useState('');
    const [filteredApartments, setFilteredApartments] = useState([]);

    const [isDialogVisible, setIsDialogVisible] = useState(false);

    const [buildingToDelete, setBuildingToDelete] = useState(null);
    const [buildingName, setBuildingName] = useState('');
    let passer = 0;
    const [associationCheck, setAssociationCheck] = useState([]);
    const [userCount, setUserCount] = useState(0);

    const [sectionHistory, setSectionHistory] = useState([]);
    
    const [selectedSetups, setSelectedSetups] = useState([]);
    const [expandedRoomType, setExpandedRoomType] = useState(null);

    const [expandedRoomType2, setExpandedRoomType2] = useState(null);


    const toggleExpand = (buildingId) => {
        setExpandedBuildings((prev) => ({
            ...prev,
            [buildingId]: !prev[buildingId],
        }));
    };


    const toggleRoomType = (roomType) => {
        // Check if any variation of the room type is already in selectedSetups
        const isSelected = selectedSetups.some(setup => setup.startsWith(roomType));

        if (isSelected) {
            // If the room type is already selected, remove all its variations
            const updatedSetups = selectedSetups.filter(setup => !setup.startsWith(roomType));

            setSelectedSetups(updatedSetups);

            // Update formData with the remaining selected setups
            setFormData((prevData) => ({
                ...prevData,
                Setup: updatedSetups
            }));

            // Collapse the room type
            setExpandedRoomType(null);
        } else {
            // If the room type is not selected, expand it to show variations
            setExpandedRoomType(expandedRoomType === roomType ? null : roomType);
        }
    };  // not used on buildings
    const confirmDelete = () => {
        // Handle the building deletion logic here using `buildingToDelete`
        console.log('Building to delete:', buildingToDelete);
        removeBuilding(buildingToDelete);
        // Assuming the deletion was successful, hide the dialog
        setIsDialogVisible(false);
        setBuildingToDelete(null);
    };

    const cancelDelete = () => {
        setIsDialogVisible(false); // Hide the dialog
        setBuildingToDelete(null); // Clear the selected building
    };

    

    const removeBuilding = (property) => {
        fetch(`https://backendjaes.passiontrials.co.za/building_tb/${property}`, {
            method: 'DELETE', // Use the DELETE method
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error(`Failed to delete building: ${response.statusText}`);
                }
                return response.json(); // Convert response to JSON if needed
            })
            .then((data) => {
                console.log('Building has been removed successfully:', data);
                // Optionally, refresh the list of buildings or update the UI
                fetchAllBuildings();

                const Toast = Swal.mixin({
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.onmouseenter = Swal.stopTimer;
                        toast.onmouseleave = Swal.resumeTimer;
                    }
                });
                Toast.fire({
                    icon: "success",
                    title: "Building has been removed!"
                });
                
            })
            .catch((error) => {
                console.error('An error occurred while deleting the building:', error);
            });
    };

    const showDialog = (buildingId) => {
        setBuildingToDelete(buildingId); // Set the building ID that will be deleted
        setIsDialogVisible(true); // Show the dialog
    };
    const [imagePreviews, setImagePreviews] = useState({
        BuildingInteriorImage1: building ? building.BuildingInteriorImage1 : '',
        BuildingInteriorImage2: building ? building.BuildingInteriorImage2 : '',
        BuildingInteriorImage3: building ? building.BuildingInteriorImage3 : '',
        BuildingExteriorImage4: building ? building.BuildingExteriorImage4 : '',
        BuildingExteriorImage5: building ? building.BuildingExteriorImage5 : '',
        BuildingExteriorImage6: building ? building.BuildingExteriorImage6 : ''
    });
    const [imagePreviewsOnUpload, setImagePreviewsOnUpload] = useState({
        ApartmentLoungeImage: null,
        ApartmentKitchenImage: null,
        ApartmentBathroomImage: null,
        ApartmentRoomImage: null,
    });
    const [formData, setFormData] = useState({
        BuildingName: building ? building.BuildingName : '',
        BuildingLocation: building ? building.BuildingLocation : '',
        BuildingTotalRooms: building ? building.BuildingTotalRooms : 10,
        BuildingInteriorImage1: building ? building.BuildingInteriorImage1 : '',
        BuildingInteriorImage2: building ? building.BuildingInteriorImage2 : '',
        BuildingInteriorImage3: building ? building.BuildingInteriorImage3 : '',
        BuildingExteriorImage4: building ? building.BuildingExteriorImage4 : '',
        BuildingExteriorImage5: building ? building.BuildingExteriorImage5 : '',
        BuildingExteriorImage6: building ? building.BuildingExteriorImage6 : '',
        BuildingManager: building ? building.BuildingManager : '',
        BuildingCity: building ? building.BuildingCity : '',
        BuildingDescript: building ? building.BuildingDescript : '',
        CreatedBy: building ? building.CreatedBy : '',
        CreatedDate: building ? building.CreatedDate : '',
        OwnerEmail: building ? building.OwnerEmail : '',
        Zone: building ? building.Zone : '',
        ZoneName: building ? building.ZoneName : '',
        // Setup: building ? building.Setup : '',
        Setup: building && Array.isArray(building.Setup) ? building.Setup : [],
        Best_Price: building ? building.Best_Price : 0,
        University: building ? building.University : '',
        Capacity: building ? building.Capacity : '',
    });
    const [formData2, setFormData2] = useState({
        BuildingId: apartment ? apartment.BuildingId : '',
        ApartmentNumber: apartment ? apartment.ApartmentNumber : '',
        ApartmentPrice: apartment ? apartment.ApartmentPrice : '',
        ApartmentType: apartment ? apartment.ApartmentType : '',
        ApartmentPreferedGender: apartment ? apartment.ApartmentPreferedGender : '',
        ApartmentDeposit: apartment ? apartment.ApartmentDeposit : '',
        ApartmentRating: apartment ? apartment.ApartmentRating : '',
        LivingArrangement: apartment ? apartment.LivingArrangement : '',
        BedRoomStatus: apartment ? apartment.BedRoomStatus : '',
        BedRoomCount: apartment ? apartment.BedRoomCount : '',
        Date: apartment ? apartment.Date : '',
        BuildingManager: apartment ? apartment.BuildingManager : '',
        Guest: apartment ? apartment.Guest : '',
        ApartmentLoungeImage: apartment ? apartment.ApartmentLoungeImage : '',
        ApartmentKitchenImage: apartment ? apartment.ApartmentKitchenImage : '',
        ApartmentBathroomImage: apartment ? apartment.ApartmentBathroomImage : '',
        ApartmentRoomImage: apartment ? apartment.ApartmentRoomImage : '',
        ApartmentSize: apartment ? apartment.ApartmentSize : '',
        LivingSpace: apartment ? apartment.LivingSpace : "",
        TotalNumOfBeds: apartment ? apartment.TotalNumOfBeds : "",
        CreatedBy: apartment ? apartment.CreatedBy : "",
        Capacity2: apartment ? apartment.Capacity2 : "",
        RoomIdentifier: apartment ? apartment.RoomIdentifier : "",
        Description: apartment ? apartment.Description : ""
    });
    const [apartments, setApartments] = useState([]);

    const [buildings, setBuildings] = useState([]);

    const [get, setGet] = useState(null);

    const [get2, setGet2] = useState(null);

    const [uBuilding, setUBuilding] = useState({
        BuildingName: "",
        BuildingLocation: "",
        BuildingTotalRooms: "",
        BuildingInteriorImage1: "",
        BuildingInteriorImage2: "",
        BuildingInteriorImage3: "",
        BuildingExteriorImage4: "",
        BuildingExteriorImage5: "",
        BuildingExteriorImage6: "",
        BuildingManager: "",
        BuildingCity: "",
        BuildingDescript: "",
        CreatedBy: "",
        CreatedDate: "",
        OwnerEmail: "",
        Zone: "",
        Capacity: "",
        PreferedGender: "",
        Setup: "",
        ZoneName: "",
        Best_Price: "",
    });

    const [uApartment, setUApartment] = useState({
        BuildingId: '',
        ApartmentNumber: '',
        ApartmentType: '',
        ApartmentPreferedGender: '',
        ApartmentDeposit: '',
        ApartmentPrice: '',
        ApartmentRating: '',
        LivingArrangement: '',
        BedRoomStatus: '',
        BedRoomCount: '',
        Date: '',
        BuildingManager: '',
        Guest: '',
        ApartmentLoungeImage: '',
        ApartmentKitchenImage: '',
        ApartmentBathroomImage: '',
        ApartmentRoomImage: '',
        ApartmentSize: '',
        LivingSpace: "",
        TotalNumOfBeds: "",
        CreatedBy: '', Capacity2: '',
        RoomIdentifier: "",
        Description: ""


    });

    const navigation = useNavigate();


    const handleRoomTypeSelect = (roomType) => {
        setSelectedSetups((prevSelectedSetups) => {
            if (prevSelectedSetups.includes(roomType)) {
                console.log("the types", `${roomType} `);
                // If room type is already selected, deselect it
                return prevSelectedSetups.filter((type) => type !== roomType);
            } else {
                // Otherwise, add the room type to the selected list
                console.log("the types", `${roomType} `);
                return [...prevSelectedSetups, roomType];
            }
        });
        console.log("the types", `${roomType} `);
        setFormData((prevData) => ({
            ...prevData,
            Setup: selectedSetups.includes(roomType)
                ? selectedSetups.filter((type) => type !== roomType)
                : [...selectedSetups, roomType]
        }));
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        // Check if the user is changing the ZoneName field
        if (name === 'ZoneName') {
            let zone = '';
            switch (value) {
                case 'Westridge':
                case 'Manor Gardens Area':
                    zone = 1;
                    break;
                case 'Glenmore':
                case 'Carrington Heights Area':
                    zone = 4;
                    break;
                case 'Umbilo':
                case 'Lower Glenwood Area':
                    zone = 3;
                    break;
                case 'Glenwood':
                    zone = 2;
                    break;
                default:
                    zone = ''; // Set empty if no match
            }

            setFormData((prevState) => ({
                ...prevState,
                ZoneName: value,
                Zone: zone
            }));
        } else {
            // Handle other changes
            setFormData((prevState) => ({
                ...prevState,
                [name]: value
            }));
        }

        // setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        let updatedSetup = [...(formData.Setup || [])];

        if (checked) {
            updatedSetup.push(value);
        } else {
            updatedSetup = updatedSetup.filter((item) => item !== value);
        }

        setFormData({
            ...formData,
            Setup: updatedSetup,
        });
    };

    const handleCheckboxChange2 = (e) => {
        const { value, checked } = e.target;
        let updatedSetup = [...(uBuilding.Setup || [])];

        if (checked) {
            updatedSetup.push(value);
        } else {
            updatedSetup = updatedSetup.filter((item) => item !== value);
        }

        setUBuilding({
            ...uBuilding,
            Setup: updatedSetup,
        });
    };

    const handleChange2 = (e) => {
        setFormData2({ ...formData2, [e.target.name]: e.target.value });
    };
    // const handleImageChange = (e) => {
    //     const { name } = e.target;
    //     const file = e.target.files[0];
    //     setFormData({ ...formData, [name]: file });

    // };
    const handleImageChange = (e, imageKey) => {
        const file = e.target.files[0];
        if (file) {
            const previewUrl = URL.createObjectURL(file); // Create a URL for the image preview
            setImagePreviews(prev => ({
                ...prev,
                [imageKey]: previewUrl // Update the preview state with the new image URL
            }));

            // Store the file in the formData for backend submission
            setFormData(prev => ({
                ...prev,
                [imageKey]: file
            }));
        }
    };

    // const handleImageChange2 = (e) => {
    //     const { name } = e.target;
    //     const file = e.target.files[0];
    //     setFormData2({ ...formData2, [name]: file });

    // };

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedApartmentId, setSelectedApartmentId] = useState(null);
    const handleDeleteClick = (apartmentId) => {
        setSelectedApartmentId(apartmentId);
        setShowDeleteDialog(true);
    };

    const confirmDeleteForRoom = () => {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/${selectedApartmentId}`, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(() => {
                setApartments(prev => prev.filter(apartment => apartment.ApartmentId !== selectedApartmentId));
                setShowDeleteDialog(false);
                setSelectedApartmentId(null);
               // alert('Apartment deleted!');
               Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Apartment has been deleted!",
                showConfirmButton: false,
                timer: 1500
            });
            })
            .catch(error => console.error('Error:', error));
    };

    const cancelDeleteForRoom = () => {
        setShowDeleteDialog(false);
        setSelectedApartmentId(null);
    };

    const handleImageChange2 = (event) => {
        const { name, files } = event.target;

        if (files && files[0]) {
            const file = files[0];

            // Update formData with the file
            setFormData2((prevFormData) => ({
                ...prevFormData,
                [name]: file
            }));

            // Create a preview URL
            const previewUrl = URL.createObjectURL(file);

            // Update image previews
            setImagePreviewsOnUpload((prevPreviews) => ({
                ...prevPreviews,
                [name]: previewUrl
            }));
        }
    };
    useEffect(() => {
        if (uApartment.BuildingId) {
            const building = buildings.find(b => b.BuildingId === uApartment.BuildingId);
            setBuildingName(building ? building.BuildingName : 'Apartment');
        }
    }, [uApartment.BuildingId, buildings]);

    const handleStatusOnUpdateSection = (room_id) => {
        if (!room_id) return;

        fetch('https://backendjaes.passiontrials.co.za/user-apartments_tb', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    const associatedUsers = data.filter(item => item.ApartmentId === room_id);
                    setUserCount(associatedUsers.length);
                } else {
                    console.log('No user association data found');
                    setUserCount(0);
                }
            })
            .catch(err => {
                console.log('There was an error while fetching users:', err);
                setUserCount(0);
            });
    }




    const handleSubmit = (e) => {
        e.preventDefault();

        const formDataToSend = new FormData();
        formData.BuildingManager = filteredAdmins ? filteredAdmins.AdminName + " " + filteredAdmins.AdminSurname : "not specified";
        formData.CreatedBy = filteredAdmins ? filteredAdmins.AdminEmail : "not specified";
        formData.OwnerEmail = filteredAdmins ? filteredAdmins.AdminEmail : "not specified";
        Object.entries(formData).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });

        const method = building ? 'PUT' : 'POST';
        const url = building ? `https://backendjaes.passiontrials.co.za/new_building_tb/${building.BuildingId}` : 'https://backendjaes.passiontrials.co.za/new_building_tb';

        fetch(url, {
            method: method,

            body: formDataToSend,
        })
            .then(res => res.json())
            .then(() => {
               // alert('Building saved!');
               fetchAllBuildings();
               
               Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Building has been deleted!",
                showConfirmButton: false,
                timer: 1500
            });
                // navigation('/');
                setFormData({
                    BuildingName: "",
                    BuildingLocation: "",
                    BuildingTotalRooms: "",
                    BuildingInteriorImage1: "",
                    BuildingInteriorImage2: "",
                    BuildingInteriorImage3: "",
                    BuildingExteriorImage4: "",
                    BuildingExteriorImage5: "",
                    BuildingExteriorImage6: "",
                    BuildingManager: "",
                    BuildingCity: "",
                    BuildingDescript: "",
                    CreatedBy: "",
                    CreatedDate: "",
                    OwnerEmail: "",
                    Zone: "",
                    Capacity: "",
                    PreferedGender: "",
                    Setup: "",
                    ZoneName: "",
                    Best_Price: "",
                });
            })
            .catch(error => {
                console.error('Error:', error);
               // alert(`Failed to save building: ${error.message}`);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Failed to save building! ${error.message}`,
                    timer: 1500
                });

            });
    };

    const handleSubmit2 = (e) => {
        e.preventDefault();

        // Ensure that LivingSpace has a value based on LivingArrangement before sending
        formData2.LivingSpace = formData2.LivingSpace ||
            (formData2.LivingArrangement === 'Single - Deluxe' || formData2.LivingArrangement === 'Single - Flatlet' || formData2.LivingArrangement === 'Single - Kitchenette' || formData2.LivingArrangement === 'Single - Ensuite' || formData2.LivingArrangement === 'Single - Standard' || formData2.LivingArrangement === 'Bachelor - Deluxe' || formData2.LivingArrangement === 'Bachelor - Flatlet' ? 'Private' :
                (formData2.LivingArrangement === 'Double - Deluxe' || formData2.LivingArrangement === 'Double - Flatlet' || formData2.LivingArrangement === 'Double - Ensuite' || formData2.LivingArrangement === 'Double - Kitchenette' || formData2.LivingArrangement === 'Double - Standard' ||
                    formData2.LivingArrangement === 'Twin - Deluxe' || formData2.LivingArrangement === 'Twin - Flatlet' || formData2.LivingArrangement === 'Twin - Kitchenette' || formData2.LivingArrangement === 'Twin - Ensuite' || formData2.LivingArrangement === 'Twin - Standard'
                    ? 'Shared' : ''));

        formData2.TotalNumOfBeds = formData2.TotalNumOfBeds ||
            (formData2.LivingArrangement === 'Single - Deluxe' || formData2.LivingArrangement === 'Single - Flatlet' || formData2.LivingArrangement === 'Single - Kitchenette' || formData2.LivingArrangement === 'Single - Ensuite' || formData2.LivingArrangement === 'Single - Standard' || formData2.LivingArrangement === 'Bachelor - Deluxe' ||
                formData2.LivingArrangement === 'Bachelor - Flatlet' ? 1 :
                (formData2.LivingArrangement === 'Double - Deluxe' || formData2.LivingArrangement === 'Double - Flatlet' || formData2.LivingArrangement === 'Double - Ensuite' || formData2.LivingArrangement === 'Double - Kitchenette' || formData2.LivingArrangement === 'Double - Standard' ||
                    formData2.LivingArrangement === 'Twin - Deluxe' || formData2.LivingArrangement === 'Twin - Flatlet' || formData2.LivingArrangement === 'Twin - Kitchenette' || formData2.LivingArrangement === 'Twin - Ensuite' ||
                    formData2.LivingArrangement === 'Twin - Standard' ? 2 :

                    (formData2.LivingArrangement === 'Triple' ? 3 :
                        (formData2.LivingArrangement === 'Quad' ? 4 : 0))
                ));

        formData2.Capacity2 = formData2.Capacity2 ||
            (formData2.LivingArrangement === 'Single - Deluxe' || formData2.LivingArrangement === 'Single - Flatlet' || formData2.LivingArrangement === 'Single - Kitchenette' || formData2.LivingArrangement === 'Single - Ensuite' || formData2.LivingArrangement === 'Single - Standard' || formData2.LivingArrangement === 'Bachelor - Deluxe' || formData2.LivingArrangement === 'Bachelor - Flatlet' ? 1 :
                (formData2.LivingArrangement === 'Double - Deluxe' || formData2.LivingArrangement === 'Double - Flatlet' || formData2.LivingArrangement === 'Double - Ensuite' || formData2.LivingArrangement === 'Double - Kitchenette' || formData2.LivingArrangement === 'Double - Standard' ||
                    formData2.LivingArrangement === 'Twin - Deluxe' || formData2.LivingArrangement === 'Twin - Flatlet' || formData2.LivingArrangement === 'Twin - Kitchenette' || formData2.LivingArrangement === 'Twin - Ensuite' || formData2.LivingArrangement === 'Twin - Standard' ? 2 :
                    (formData2.LivingArrangement === 'Triple' ? 3 :
                        (formData2.LivingArrangement === 'Quad' ? 4 : 0))));

        const formDataToSend = new FormData();
        formData2.BuildingManager = filteredAdmins ? filteredAdmins.AdminName + " " + filteredAdmins.AdminSurname : "not specified";
        formData2.CreatedBy = filteredAdmins ? filteredAdmins.AdminEmail : "not specified";
        formData2.ApartmentDeposit = formData2.ApartmentDeposit || 0;
        formData2.ApartmentRating = formData2.ApartmentRating || '0';
        formData2.BedRoomCount = formData2.BedRoomCount || 0;
        formData2.ApartmentType = formData2.ApartmentType || 'not used';
        formData2.Guest = formData2.Guest || 0;
        formData2.Capacity2 = formData2.Capacity2 || 0;
        formData2.Description = formData2.Description || 'nothing yet';
        formData2.RoomIdentifier = formData2.RoomIdentifier || 'nothing yet';
        formData2.BedRoomStatus = 'Available';
        Object.entries(formData2).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });

        const method = apartment ? 'PUT' : 'POST';
        const url = apartment
            ? `https://backendjaes.passiontrials.co.za/new_apartment_tb/${apartment.ApartmentId}`
            : 'https://backendjaes.passiontrials.co.za/new_apartment_tb';

        console.log("submit two", apartment);

        fetch(url, {
            method: method,
            body: formDataToSend,
        })
            .then((res) => res.json())
            .then(() => {
               // alert('Apartment Saved!');
               fetchAllApartments();
               Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Apartment Saved!",
                showConfirmButton: false,
                timer: 1500
            });
                setFormData2({
                    // BuildingId: '',
                    ApartmentNumber: '',
                    ApartmentPrice: '',
                    ApartmentType: '',
                    ApartmentPreferedGender: '',
                    ApartmentDeposit: '',
                    ApartmentRating: '',
                    LivingArrangement: '',
                    BedRoomStatus: '',
                    BedRoomCount: '',
                    Date: '',
                    BuildingManager: '',
                    Guest: '',
                    ApartmentLoungeImage: '',
                    ApartmentKitchenImage: '',
                    ApartmentBathroomImage: '',
                    ApartmentRoomImage: '',
                    ApartmentSize: '',
                    LivingSpace: '',
                    TotalNumOfBeds: ''
                });
            })
            .catch((error) => console.error('error : ', error));
    };



    // const handleChange3 = (event) => {
    //     const { name, value } = event.target;
    //     setUBuilding((prevState) => ({
    //         ...prevState,
    //         [name]: value,
    //     }));
    // };
    const handleChange3 = (event) => {
        const { name, value, files } = event.target; // Destructure files for handling file inputs

        // Check if the field is an image field
        const imageFields = ['BuildingInteriorImage1', 'BuildingInteriorImage2', 'BuildingInteriorImage3', 'BuildingExteriorImage4', 'BuildingExteriorImage5', 'BuildingExteriorImage6'];

        if (imageFields.includes(name) && files && files[0]) {
            // Handle file input (images)
            const file = files[0];
            console.log('File selected:', file); // Debugging log for selected file
            setUBuilding((prevState) => ({
                ...prevState,
                [name]: file, // Store the file in the uBuilding state
            }));
        } else {
            // Handle regular text input
            setUBuilding((prevState) => ({
                ...prevState,
                [name]: value, // Store the value for non-file fields
            }));
        }
    };



    // const handleChange4 = (event) => {
    //     const { name, value } = event.target;
    //     setUApartment((prevState) => ({
    //         ...prevState, [name]: value,
    //     }));
    // };
    const handleChange4 = (event) => {
        const { name, value, files } = event.target; // Destructure files to handle file input

        // Check if the field is an image field
        const imageFields = ['ApartmentLoungeImage', 'ApartmentKitchenImage', 'ApartmentBathroomImage', 'ApartmentRoomImage'];

        if (imageFields.includes(name) && files && files[0]) {
            // Handle file input (images)
            const file = files[0];
            console.log('File selected:', file); // Debugging log for selected file
            setUApartment((prevState) => ({
                ...prevState,
                [name]: file, // Store the file in the uApartment state
            }));
        } else {   setUApartment((prevState) => {
                const updatedApartment = {
                    ...prevState,
                    [name]: value,
                };

                console.log(`Updating field: ${name} with value: ${value}`);

                // Update based on LivingArrangement
                if (name === "LivingArrangement") {
                    updatedApartment.LivingSpace = value.startsWith("Single") || value.startsWith("Bachelor") ? 'Private' : 'Shared';
                    updatedApartment.TotalNumOfBeds = value.startsWith("Single") || value.startsWith("Bachelor") ? 1 : 2;
                    updatedApartment.Capacity2 = updatedApartment.TotalNumOfBeds;

                    console.log('Updated based on LivingArrangement:', {
                        LivingSpace: updatedApartment.LivingSpace,
                        TotalNumOfBeds: updatedApartment.TotalNumOfBeds,
                        Capacity2: updatedApartment.Capacity2,
                    });
                } else if (name === "LivingSpace") {
                    // Set LivingArrangement based on LivingSpace change
                    updatedApartment.LivingArrangement = value === 'Private' ? 'Single - Standard' : 'Double - Standard';
                    updatedApartment.TotalNumOfBeds = value === 'Private' ? 1 : 2;
                    updatedApartment.Capacity2 = updatedApartment.TotalNumOfBeds;

                    console.log('Updated based on LivingSpace:', {
                        LivingArrangement: updatedApartment.LivingArrangement,
                        TotalNumOfBeds: updatedApartment.TotalNumOfBeds,
                        Capacity2: updatedApartment.Capacity2,
                    });
                } else if (name === "TotalNumOfBeds" || name === "Capacity2") {
                    const beds = parseInt(value, 10);
                    updatedApartment.LivingArrangement = beds === 1 ? 'Single - Standard' : 'Double - Standard';
                    updatedApartment.LivingSpace = beds === 1 ? 'Private' : 'Shared';
                    updatedApartment.TotalNumOfBeds = beds;
                    updatedApartment.Capacity2 = beds;

                    console.log('Updated based on TotalNumOfBeds/Capacity2:', {
                        LivingArrangement: updatedApartment.LivingArrangement,
                        LivingSpace: updatedApartment.LivingSpace,
                    });
                }

                return updatedApartment;
            });
        }
    };


    const handleChange5 = (event) => {
        const { name, value } = event.target;
        setAdmin((prevState) => ({
            ...prevState, [name]: value,
        }))
    };
    const handleLivingArrangementSelection = (roomType, variation) => {
        const selectedLivingArrangement = `${roomType} - ${variation}`;

        setFormData2((prevData) => {
            const updatedData = {
                ...prevData,
                LivingArrangement: selectedLivingArrangement, // Only one selection for LivingArrangement
            };

            // Update LivingSpace, TotalNumOfBeds, and Capacity2 based on the selected LivingArrangement
            if (roomType === "Single" || roomType === "Bachelor") {
                updatedData.LivingSpace = 'Private';
                updatedData.TotalNumOfBeds = 1;
                updatedData.Capacity2 = 1;
            } else if (roomType === "Double" || roomType === "Twin") {
                updatedData.LivingSpace = 'Shared';
                updatedData.TotalNumOfBeds = 2;
                updatedData.Capacity2 = 2;
            } else {
                // Default values if roomType is neither "Single", "Bachelor", "Double", nor "Twin"
                updatedData.LivingSpace = '';
                updatedData.TotalNumOfBeds = 0;
                updatedData.Capacity2 = 0;
            }

            // Debugging logs to verify updates
            console.log("add apartment - Selected Living Arrangement:", updatedData.LivingArrangement);
            console.log("add apartment - Updated Living Space:", updatedData.LivingSpace);
            console.log("add apartment - Updated TotalNumOfBeds:", updatedData.TotalNumOfBeds);
            console.log("add apartment - Updated Capacity2:", updatedData.Capacity2);

            return updatedData;
        });

        // Close the variations list for a tidy interface
        setExpandedRoomType(null);
    };

    const handleLivingArrangementSelection2 = (roomType, variation) => {
        const selectedLivingArrangement = `${roomType} - ${variation}`;

        setUApartment((prevData) => {
            const updatedData = {
                ...prevData,
                LivingArrangement: selectedLivingArrangement, // Set only one selection for LivingArrangement
            };

            // Update LivingSpace, TotalNumOfBeds, and Capacity2 based on the selected LivingArrangement
            if (roomType === "Single" || roomType === "Bachelor") {
                updatedData.LivingSpace = 'Private';
                updatedData.TotalNumOfBeds = 1;
                updatedData.Capacity2 = 1;
            } else if (roomType === "Double" || roomType === "Twin") {
                updatedData.LivingSpace = 'Shared';
                updatedData.TotalNumOfBeds = 2;
                updatedData.Capacity2 = 2;
            } else {
                updatedData.LivingSpace = ''; // Default for other cases if needed
                updatedData.TotalNumOfBeds = 0;
                updatedData.Capacity2 = 0;
            }

            // Debugging logs to verify updates
            console.log("Selected Living Arrangement:", updatedData.LivingArrangement);
            console.log("Updated Living Space:", updatedData.LivingSpace);
            console.log("Updated TotalNumOfBeds:", updatedData.TotalNumOfBeds);
            console.log("Updated Capacity2:", updatedData.Capacity2);

            return updatedData;
        });

        // Close the variations list for a tidy interface
        setExpandedRoomType(null);
    };

     // Toggle room type to show/hide its variations
     const toggleRoomType2 = (roomType) => {
        setExpandedRoomType2(expandedRoomType2 === roomType ? null : roomType);
    };
    const handleImageChange3 = (e, imageKey) => {
        const file = e.target.files[0];
        if (file) {
            // Update the preview in the form state
            const previewUrl = URL.createObjectURL(file);

            setUBuilding({
                ...uBuilding,
                [imageKey]: previewUrl, // Set the new image preview URL in state
            });

            // Update formData with the new image file (to send to the backend)
            setFormData({
                ...formData,
                [imageKey]: file, // Store the new file in formData
            });
        }
    };
    const handleSubmit3 = (event) => {
        event.preventDefault();

        const formDataToSend = new FormData();
        Object.entries(uBuilding).forEach(([key, value]) => {
            formDataToSend.append(key, value);
        });

        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb/${get}`, {
            method: "PUT",
            body: formDataToSend,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP status ${res.status}`);
                }
                return res.json();
            })
            .then(() => {
               // alert('Building updated successfully');
               fetchSpecificBuilding(get);
               fetchAllBuildings();
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Building has been saved!",
                    showConfirmButton: false,
                    timer: 1500
                });
                // navigation('/');
            })
            .catch((error) => {
                console.error('Failed to update building:', error);
              //  alert(`Failed to update building: ${error.message}`);
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Failed to update building!",
                timer: 1500
            });

            });

    };

    const handleSubmit4 = (event) => {
        event.preventDefault();

        const formDataToSend = new FormData();
        uApartment.BuildingManager = filteredAdmins ? filteredAdmins.AdminName + " " + filteredAdmins.AdminSurname : "not specified";
        uApartment.CreatedBy = filteredAdmins ? filteredAdmins.AdminEmail : "not specified";
        uApartment.ApartmentDeposit = uApartment.ApartmentDeposit || 0;
        uApartment.ApartmentRating = uApartment.ApartmentRating || '0';
        uApartment.BedRoomCount = uApartment.BedRoomCount || 0;
        uApartment.ApartmentType = uApartment.ApartmentType || 'not used';
        uApartment.Guest = uApartment.Guest || 0;

        uApartment.Capacity2 = uApartment.Capacity2 || 0;
        uApartment.Description = uApartment.Description || 'nothing yet';
        uApartment.RoomIdentifier = uApartment.RoomIdentifier || 'nothing yet';
        Object.entries(uApartment).forEach(([key, value]) => {
            console.log(`${key}: ${value}`);
            formDataToSend.append(key, value); // Append all form data, including files
        });

        fetch(`https://backendjaes.passiontrials.co.za/new_apartment_tb/${get2}`, {
            method: 'PUT',
            body: formDataToSend,
        })
            .then(res => {
                if (!res.ok) {
                    throw new Error(`HTTP status ${res.status}`);
                }
                return res.json();
            })
            .then(() => {
               // alert('Apartment updated successfully');
               fetchSpecificApartment(get2);
               fetchAllApartments();
               Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Apartment has been Saved!",
                showConfirmButton: false,
                timer: 1500
            });
            
                //setActiveSection('Apartments');
               // goToSection('Apartments');
            })
            .catch((error) => {
                console.error('Failed to update apartment: ', error);
               // alert(`Failed to update apartment: ${error.message}`);
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: `Failed to update apartment: ${error.message}`,
                    timer: 1500
                });

            });
    };



    const handleSubmit5 = (event) => {
        event.preventDefault();
        fetch(`https://backendjaes.passiontrials.co.za/admin_tb`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(admin),
        })
            .then(res => res.json())
            .then(() => {
             //   alert('Admin has been created');
             fetchAllAdmins();
             Swal.fire({
                position: "top-end",
                icon: "success",
                title: "Admin has been created",
                showConfirmButton: false,
                timer: 1500
            });

            setAdmin({
                AdminUsername: '',
                AdminPassword: "",
                AdminName: "",
                AdminSurname: "",
                AdminEmail: "",
                Role: "",


            })


            })
            .catch(error => console.error('error: ', error));
    };

    useEffect(() => {
        fetch('https://backendjaes.passiontrials.co.za/user_tb', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data) {


                    setCust(data);
                } else {
                    console.log('No users found');
                }
            })
            .catch(err => {
                console.log('There was an error while fetching users:', err);
                setError('There was an error with fetching users');
            });
    }, [])



    // useEffect(() => {



    //     if (filteredAdmins) {
    //         fetch('https://backendjaes.passiontrials.co.za/new_all_user_full_details')
    //             .then(res => res.json())
    //             .then(data => {

    //                 const filter = data.filter((tenant) => tenant.CreatedBy == filteredAdmins.AdminEmail);
    //                 // console.log('this is the tenant info', filter);
    //                 // setTenantDetails(filter); // Store detailed information for all tenants
    //                 // Extract unique buildings from the data

    //                 const actualUsers = cust.filter((user) => { return !data.some(tenant => tenant.UserId == user.UserId); });
    //                 const allUsers = [...actualUsers, ...filter];
    //                 const allUniqueUsers = Array.from(new Set(allUsers.map(user => user.UserId))).map(id => allUsers.find(user => user.UserId === id));
    //                 // console.log('non tenant', actualUsers);

    //                 //setDisplayUsers(allUniqueUsers);
    //                 // console.log('the final array', displayUsers)
    //                 //  console.log('the tenants', filter)
    //                 setNonTenant(actualUsers);
    //                 // console.log('these are no associated users', actualUsers);
    //                 //const uniqueBuildings = [...new Set(data.map(user => user.BuildingName))];
    //                 //const theBuildings = Array.from(new Set(filter.map(t => t.BuildingName)));

    //                 // setBuildings(uniqueBuildings);
    //                 //console.log("this are the buildings in the list", theBuildings);
    //             })
    //             .catch(err => console.error('Error fetching all user details:', err));
    //     }
    // }, [filteredAdmins, cust])


    useEffect(() => {
        // fetch(`https://backendjaes.passiontrials.co.za/apartment_tb`)
        //     .then(res => res.json())
        //     .then(data => setApartments(data))
        //     .catch(error => console.error('error :', error));


        // fetch('https://backendjaes.passiontrials.co.za/new_building_tb')
        //     .then(res => res.json())
        //     .then(data => setBuildings(data))
        //     .catch(error => { console.error('error:', error) });


        // fetch('https://backendjaes.passiontrials.co.za/admin_tb')
        //     .then(res => res.json())
        //     .then(data => setInfo(data))
        //     .catch(err => { console.log('An error occured:', err) });
fetchAllAdmins();
    }, []);
    useEffect(() => {  // 12 nov 2024
        fetchAllApartments();
    }, []);

    useEffect(() => { // 11 nov 2024

        fetchAllBuildings();
    }, []);


    const fetchAllAdmins = async () => {  // 12 nov 2024
        try {

            const response = await fetch(`https://backendjaes.passiontrials.co.za/admin_tb`);
            const data = await response.json();
            setInfo(data);

        } catch (error) {
            console.error('An error occured fetching admins', error);
        }
    };

    const fetchAllApartments = async () => { //12 nov 2024
        try {

            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb`);
            const data = await response.json();
            setApartments(data);

        } catch (error) {
            console.error('Error fetching all apartment', error);
        }
    };

    const fetchAllBuildings = async () => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_building_tb');
            const data = await response.json();
            setBuildings(data);
        } catch (error) {
            console.error('Error fetching all buildings:', error);
        }
    };


    useEffect(() => {
        if (userId && info) {



            const filtered = info.filter((admin) => admin.AdminId == userId);
            setFilteredAdmins(filtered[0]);
            // console.log('this is the admin info', filteredAdmins);
        } else {
            console.log('this is the missing information', userId, info);
        }

    }, [userId, info]);

    
    useEffect(() => {
        if (selectedBuilding) {
            // Filter apartments based on selected building
            const filtered = apartments.filter(apartment => apartment.BuildingId === selectedBuilding);
            setFilteredApartments(filtered);
        }
    }, [selectedBuilding, apartments]);


    // useEffect(() => {
    //     if (filteredRooms && apartments.length > 0) {

    //         const filtered = apartments;
    //         const subFilter = filtered.filter((available) => available.Capacity2 > 0);
    //         setFilteredRooms(filtered);
    //         setFilteredRoomsAvail(subFilter);

    //         console.log('this is the filtered room', subFilter);
    //     }
    // }, [filteredRooms, apartments])




    const fetchSpecificApartment = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/single/${id}`);
            const data = await response.json();
            setUApartment(data.data[0]);
            console.log('lone A update ', data);
        } catch (error) {
            console.error('Error fetching Apartment inside update page data:', error);
        }
    };
 const fetchSpecificBuilding = async (id) => {  // 11 nov 2024
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/building_tb/single/${id}`);
            const data = await response.json();
            setUBuilding(data[0]);
            console.log('lone B update ', data);
        } catch (error) {
            console.error('Error fetching building inside update page data:', error);
        }
    };


    const Uselect = (id) => {
        fetch(`https://backendjaes.passiontrials.co.za/building_tb/single/${id}`)
            .then((response) => response.json())
            .then((data) => {
                console.log("Fetched data:", data[0]);
                setUBuilding((buildin) => ({ ...buildin, ...data[0] }));
                // console.log(building);
                setLoading(false);
            })
            .catch((error) => {
                console.error("Failed to load building data:", error);
                setLoading(false);
            });
    }

    const Uselect2 = (id) => {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/single/${id}`)
            .then((res) => res.json())
            .then((data) => {
                console.log("fetched apartment data:", data.data[0]);
                const apartmentData = data.data[0];
                setUApartment((u) => ({ ...u, ...apartmentData }));
                setLoading(false);

            })
            .catch((error) => {
                console.log('Failed to load apartment data: ', error);
                setLoading(false);
            });
    }

    function handleDelete(apartmentId) {
        fetch(`https://backendjaes.passiontrials.co.za/apartment_tb/${apartmentId}`, {
            method: 'DELETE',
        })
            .then(res => res.json())
            .then(() => {
                setApartments(prev => prev.filter(apartment => apartment.ApartmentId !== apartmentId));
                //alert('Apartment deleted!');
                Swal.fire({
                    position: "top-end",
                    icon: "success",
                    title: "Apartment has been deleted!",
                    showConfirmButton: false,
                    timer: 1500
                });
            })
            .catch(error => console.error('error:', error));
    }

    const handleTabsClicked = (tabs) => {
        setSelectedPayment(tabs);
    };

    const generalInfo = (id) => {


    };

    const updateSection = (id) => {
        Uselect(id);
        setGet(id);
        console.log(`this is the passed value:${id}`);

        //setActiveSection('updateBuilding');
        goToSection('updateBuilding');
    };

    const updateApartment = (id, id2) => {
        Uselect2(id);
        setGet2(id, id2);
        passer = id;
        console.log('This is the apartment id:', get2, `these are the parameters ${id}:${id2} `);
       // setActiveSection('updateApartment');
       goToSection('updateApartment');

    };
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedApartment, setSelectedApartment] = useState('');
    const [assignments, setAssignments] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [selectedPlan, setSelectedPlan] = useState('');
    const [paymentMethod, setPaymentMethod] = useState('');

    const planPrices = {
        A: 3250,
        B: 8500,
    };

    const calculateTotalCost = (plan, apartmentId) => {
        const apartment = filteredRoomsAvail.find((room) => room.ApartmentId.toString() === apartmentId);
        const apartmentPrice = apartment ? apartment.ApartmentPrice : 0;
        const planPrice = planPrices[plan] || 0;

        return planPrice + apartmentPrice;
    };

    const handlePlanChange = (e) => {
        const plan = e.target.value;
        setSelectedPlan(plan);

        const newTotalCost = calculateTotalCost(plan, selectedApartment);
        setTotalCost(newTotalCost);
    };
    const handleApartmentChange = (e) => {
        const apartmentId = e.target.value;
        setSelectedApartment(apartmentId);

        const newTotalCost = calculateTotalCost(selectedPlan, apartmentId);
        setTotalCost(newTotalCost);
    };

    const handleAssign = () => {
        if (!selectedUser || !selectedApartment || !selectedPlan || !paymentMethod) {
           // alert("Please select a user, apartment, payment plan, and payment method.");
            Swal.fire({
                position: "top-end",
                icon: "warning",
                title: "Please select a user, apartment, payment plan, and payment method.",
                showConfirmButton: false,
                timer: 1500
            });
            return;
        }

        const newAssignment = {
            userId: selectedUser,
            apartmentId: selectedApartment,
            userName: nonTenant.find(user => user.UserId.toString() === selectedUser)?.Name,
            apartmentNumber: filteredRoomsAvail.find(apartment => apartment.ApartmentId.toString() === selectedApartment)?.ApartmentNumber,
            roomDetails: filteredRoomsAvail.find(apartment => apartment.ApartmentId.toString() === selectedApartment),
            plan: {
                name: selectedPlan === 'A' ? 'Plan A' : 'Plan B',
                serviceFee: selectedPlan === 'A' ? 3250.00 : 8500.00,
            },
            totalCost: selectedPlan === 'A' ? 3250.00 : 8500.00 + parseFloat(filteredRoomsAvail.find(apartment => apartment.ApartmentId.toString() === selectedApartment)?.ApartmentPrice),
            paymentMethod,

        };

        setAssignments([...assignments, newAssignment]);
        assignRecord(newAssignment);
        walletRecord(newAssignment);
        console.log('This is the apartment id:', newAssignment);
        markApartmentAsOccupied(newAssignment)
      //  alert(`User assigned to apartment with a total cost of R${totalCost}!`);
      Swal.fire({
        position: "top-end",
        icon: "info",
        title: `User assigned to apartment with a total cost of R${totalCost}!`,
        showConfirmButton: false,
        timer: 2500
    });
        setSelectedUser('');
        setSelectedApartment('');
        setSelectedPlan('');
        setPaymentMethod('');
        setTotalCost(0);

        fetchNonTenantsAndAvailableRooms();

    };



    useEffect(() => {
        fetch('https://backendjaes.passiontrials.co.za/user_tb', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data) {


                    setCust(data);
                } else {
                    console.log('No users found');
                }
            })
            .catch(err => {
                console.log('There was an error while fetching users:', err);
                setError('There was an error with fetching users');
            });
    }, [])

    useEffect(() => {
        if (passer) {
            handleStatusOnUpdateSection(passer);
        }
    }, [passer]);


    // useEffect(() => {



    //     if (filteredAdmins) {
    //         fetch('https://backendjaes.passiontrials.co.za/new_all_user_full_details')
    //             .then(res => res.json())
    //             .then(data => {

    //                 const filter = data.filter((tenant) => tenant.CreatedBy == filteredAdmins.AdminEmail);
    //                 // console.log('this is the tenant info', filter);
    //                 // setTenantDetails(filter); // Store detailed information for all tenants
    //                 // Extract unique buildings from the data

    //                 const actualUsers = cust.filter((user) => { return !data.some(tenant => tenant.UserId == user.UserId); });
    //                 const allUsers = [...actualUsers, ...filter];
    //                 const allUniqueUsers = Array.from(new Set(allUsers.map(user => user.UserId))).map(id => allUsers.find(user => user.UserId === id));
    //                 // console.log('non tenant', actualUsers);

    //                 //setDisplayUsers(allUniqueUsers);
    //                 // console.log('the final array', displayUsers)
    //                 //  console.log('the tenants', filter)
    //                 setNonTenant(actualUsers);
    //                 // console.log('these are no associated users', actualUsers);
    //                 //const uniqueBuildings = [...new Set(data.map(user => user.BuildingName))];
    //                 //const theBuildings = Array.from(new Set(filter.map(t => t.BuildingName)));

    //                 // setBuildings(uniqueBuildings);
    //                 //console.log("this are the buildings in the list", theBuildings);
    //             })
    //             .catch(err => console.error('Error fetching all user details:', err));
    //     }
    // }, [filteredAdmins, cust])

    useEffect(() => {//11 nov 2024
        if (filteredAdmins) {
            fetchNonTenantsAndAvailableRooms();
        }
    }, [filteredAdmins]);

    const fetchNonTenantsAndAvailableRooms = async () => {  //11 nov 2024
        try {
            // Fetch non-tenant users
            const nonTenantResponse = await fetch('https://backendjaes.passiontrials.co.za/new_all_user_full_details');
            const allUsersData = await nonTenantResponse.json();


            const userResponse = await fetch('https://backendjaes.passiontrials.co.za/user_tb');
            const userData = await userResponse.json();

            const actualUsers1 = userData.filter((user) => { return !allUsersData.some(tenant => tenant.UserId == user.UserId); });
            const actualUsers = allUsersData.filter((user) => !assignments.some((assign) => assign.userId === user.UserId));
            setNonTenant(actualUsers1);

            // Fetch available apartments

            const allRoomsResponse = await fetch("https://backendjaes.passiontrials.co.za/apartment_tb");
            const availableData = await allRoomsResponse.json();
            const availableRooms = availableData.filter(room => room.Capacity2 > 0);
            setFilteredRoomsAvail(availableRooms);

        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const handleAvailable = (room) => {
        if (!room) return;

        if (room.Capacity2 < 1) {
            return 'Not Available';
        } else {
            // Handle specific Single room types
            if (room.Capacity2 === 1 &&
                ['Single - Standard', 'Single - Ensuite', 'Single - Kitchenette', 'Single - Flatlet', 'Single - Deluxe'].includes(room.LivingArrangement.trim())) {
                return `${room.Capacity2} Room(s) available`;
            }
            // Handle specific Double room types
            else if (room.LivingArrangement.trim().startsWith('Double')) {
                return `${room.Capacity2} beds available`;
            }
            // Handle specific Twin room types
            else if (room.LivingArrangement.trim().startsWith('Twin')) {
                return `${room.Capacity2} beds available`;
            }
            // Handle specific Bachelor room types
            else if (room.Capacity2 === 1 &&
                ['Bachelor - Flatlet', 'Bachelor - Deluxe'].includes(room.LivingArrangement.trim())) {
                return `${room.Capacity2} Room available`;
            }
            // Default case for other room types
            else {
                return `${room.Capacity2} beds available`;
            }
        }
    };

    const markApartmentAsOccupied = async (roomId) => {
        if (roomId && roomId.apartmentId) {
            const apartmentId = parseInt(roomId.apartmentId); // Ensure it's a valid integer
            if (!isNaN(apartmentId)) {
                try {
                    const decrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/decrement-capacity/${apartmentId}`, {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                    });

                    if (!decrementCapacity.ok) {
                        const errorText = await decrementCapacity.text();
                        throw new Error(`Failed to decrement apartment capacity: ${errorText}`);
                    }

                    console.log('Apartment capacity has been decremented successfully');
                } catch (error) {
                    console.error('Error decrementing apartment capacity:', error);
                }
            } else {
                console.error(`Invalid ApartmentId: ${roomId.ApartmentId}`);
            }
        } else {
            console.error('RoomId or ApartmentId is missing');
        }
    };


    const assignRecord = async (val) => {

        const moveInDate_ = new Date();
        const moveOutDate_ = new Date(moveInDate_.getFullYear(), moveInDate_.getMonth() + 1, 0);

        const formatedMoveOutDate = moveOutDate_.toLocaleString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true,
        });

        const payload = {
            UserId: parseInt(val.userId),
            ApartmentId: parseInt(val.apartmentId),
            RentAmount: val.roomDetails.ApartmentPrice,
            MoveInDate: new Date().toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }),// add time here 
            MoveOutDate: formatedMoveOutDate,
            LeaseTerm: '1 Month',
            DepositPaid: true,
            IsActive: true,
            ApartmentRating: val.roomDetails.ApartmentRating,
            Guest: val.roomDetails.Guest,
            Notes: 'Cash Payment',
            TenantAgreementType: "Month-to-Month",
            ServiceFee: val.plan.serviceFee,//need these values
            ChosenPlan: val.plan.name,
            PayableRent: val.roomDetails.ApartmentPrice,
            RentCost: val.roomDetails.ApartmentPrice
            // Include other fields as necessary
        };
        console.log('Assigning record payload:', payload);

        const response = await fetch('https://backendjaes.passiontrials.co.za/user_apartment_tb', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        });

        if (!response.ok) {
            throw new Error(`Failed to associate user with apartment: ${await response.text()}`);
        }

        return await response.json();  // 
    }

    const walletRecord = async (wal) => {
        const now = new Date();
        const formatDate = (date) => {
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        };
        const paymentDate = formatDate(now);

        const walletData = {
            UserId: parseInt(wal.userId),
            ApartmentId: parseInt(wal.apartmentId),
            PaymentDate: paymentDate,
            AmountPaid: wal.totalCost,
            MonthYear: new Date().toLocaleDateString('en-US', { month: 'long', year: 'numeric' }), // does user chooses or the system does ?
            DailyRate: 0,    // need a fucntion here 
            DaysPaidFor: 30,// as well as here 
            PaymentMethod: wal.paymentMethod,

        };

        const response = await fetch('https://backendjaes.passiontrials.co.za/wallet', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(walletData)
        });

        if (!response.ok) {
            throw new Error(`Failed to update wallet: ${await response.text()}`);
        }
    }

    const backToManage = () => {
       // setActiveSection('Buildings');
       fetchAllBuildings();
       goToSection('Buildings');
    }

    const backToManage2 = () => {
        //setActiveSection('Apartments');
        fetchAllApartments();
        goToSection('Apartments');
    }
    const roomTypesWithVariations = {
        "Single": ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
        "Double": ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
        "Twin": ["Standard", "Ensuite", "Kitchenette", "Flatlet", "Deluxe"],
        "Bachelor": ["Flatlet", "Deluxe"]

    }


const goToSection = (section) => {
    setSectionHistory((prevHistory) => [...prevHistory, activeSection]); // Add current section to history
    setActiveSection(section); // Set the new active section
};

const goBack = () => {
    setSectionHistory((prevHistory) => {
        const newHistory = [...prevHistory];
        const previousSection = newHistory.pop(); // Get the previous section
        setActiveSection(previousSection || 'userProfile'); // Go to previous or default if history is empty
        fetchAllAdmins();
        fetchAllApartments();
        fetchAllBuildings();
        return newHistory; // Update the history
    });
};
    if (error) return <p>There is an error:{error}</p>;
    // if (info.length === 0) return <p>This user has no account records</p>;

    return (
        <div style={{ display: 'flex' }}>
            <div style={{ width: '15%', padding: '20px', borderRight: '1px solid #ddd' }}>
                <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>Manage Properties</h2>
                <button type="button" onClick={goBack} className="apollo-button">Back</button>
                <ul style={{ listStyleType: 'none', padding: '0' }}>
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-user-cog" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('userProfile')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Sub Admins
                        </Link>
                    </li>
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-user-plus" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('paymentHistory')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Create an Admin
                        </Link>
                    </li>

                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-user-plus" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('AssignUser')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Assign
                        </Link>
                    </li>
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-building" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('AddBuilding')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Add Building
                        </Link>
                    </li>
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-door-open" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('AddApartment')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Add Apartment
                        </Link>
                    </li>
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-building" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('Apartments')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Apartments
                        </Link>
                    </li>
                    <li style={{ marginBottom: '15px', display: 'flex', alignItems: 'center' }}>
                        <i className="fas fa-city" style={{ marginRight: '10px' }}></i>
                        <Link
                            to="#"
                            onClick={() => goToSection('Buildings')}
                            style={{ fontSize: '18px', textDecoration: 'none', color: '#333' }}
                        >
                            Buildings
                        </Link>
                    </li>
                </ul>
            </div>
            <div style={{ width: '80%', padding: '10px' }} >
                {activeSection === 'paymentHistory' && (
                    <div>
                        <h2>Create a new admin</h2>
                        <form onSubmit={handleSubmit5}>
                            <select

                                name="BuildingId"
                                value={admin.BuildingId}
                                onChange={handleChange5}
                            >
                                <option value=''>Select Building</option>
                                {buildings.map((building) => (
                                    <option key={building.BuildingId} value={building.BuildingId}>
                                        {building.BuildingName}
                                    </option>
                                ))}
                            </select>
                            <br></br>
                            {/* <input
                                type='text'
                                name='AdminUsername'
                                value={admin.AdminUsername}
                                onChange={handleChange5}
                                placeholder='username'
                            />
                            <br></br> */}
                            <input
                                type="text"
                                name="AdminName"
                                value={admin.AdminName}
                                onChange={handleChange5}
                                placeholder="Admin name"
                            />
                            <br></br>
                            <input
                                type='text'
                                name='AdminSurname'
                                value={admin.AdminSurname}
                                onChange={handleChange5}
                                placeholder='Admin surname'
                            />

                            <br></br>

                            <select
                                name="Role"
                                value={admin.Role}
                                onChange={handleChange5}
                            >
                                <option value=''>Select Role</option>
                                <option value='Admin'> Admin</option>
                                <option value='Super Admin'>Super Admin</option>
                            </select>
                            <br></br>
                            <input
                                type='email'
                                name='AdminEmail'
                                value={admin.AdminEmail}
                                onChange={handleChange5}
                                placeholder='Email'
                                autoComplete='off'
                            />
                            <br></br>
                            <input
                                type='password'
                                name='AdminPassword'
                                value={admin.AdminPassword}
                                onChange={handleChange5}
                                placeholder='Password'
                                autoComplete="new-password"

                            />
                            <br></br>
                            <button type='submit'>Create Admin</button>
                            <br></br>
                            <button type='button' onClick={goBack}>Back</button>
                        </form>
                    </div>
                )}

                {activeSection === 'userProfile' && (
                    <div className="gauteng-container">
                        <h2 className="gauteng-title">All Admins</h2>
                        <div className="gauteng-content">
                            <div className="kzn-sidebar">
                                <ul className="kzn-list">
                                    {info.map(payment => (
                                        <li key={payment.AdminId} className="kzn-list-item">
                                            <button
                                                onClick={() => handleTabsClicked(payment)}
                                                className="kzn-button" style={{ color: 'black' }}
                                            >
                                                {payment.AdminEmail}
                                            </button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            <div className="western-cape-details">
                                {selectedPayment && (
                                    <div className="western-cape-info">
                                        <p><strong>Created:</strong> {selectedPayment.AccountCreatedDate}</p>
                                        <p><strong>Admin Name:</strong> {selectedPayment.AdminName}</p>
                                        <p><strong>Admin Surname:</strong> {selectedPayment.AdminSurname}</p>
                                        <p><strong>Last Logged in:</strong> {selectedPayment.AdminLastLogin}</p>
                                        <p><strong>Email Address:</strong> {selectedPayment.AdminEmail}</p>
                                        <p>
                                            <strong>Role:</strong>
                                            <span className={`status ${selectedPayment.Role.toLowerCase()} eastern-cape-role`}>
                                                {selectedPayment.Role}
                                            </span>
                                        </p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

{activeSection === 'AddBuilding' && (
                    <div className="apollo-container">
                        <h1 className="apollo-heading">Add a New Building</h1>
                        <form onSubmit={handleSubmit} className="apollo-form">
                            <div className="apollo-form-group">
                                <input
                                    type="text"
                                    name="BuildingName"
                                    value={formData.BuildingName}
                                    onChange={handleChange}
                                    placeholder="Building Name"
                                    className="apollo-input"
                                    required
                                />

                                <input
                                    type="text"
                                    name="BuildingLocation"
                                    value={formData.BuildingLocation}
                                    onChange={handleChange}
                                    placeholder="Building Location"
                                    className="apollo-input"
                                    required
                                />
                            </div>

                            <label className="apollo-label">Total Rooms</label>
                            <input
                                type="text"
                                name="BuildingTotalRooms"
                                value={formData.BuildingTotalRooms}
                                onChange={handleChange}
                                placeholder="Total Rooms"
                                className="apollo-input"
                                required
                            />

                            <label className="apollo-label">Building Description</label>
                            <input
                                type="text"
                                name="BuildingDescript"
                                value={formData.BuildingDescript}
                                onChange={handleChange}
                                placeholder="Building Description"
                                className="apollo-input"
                                required
                            />
 {/* <label className="apollo-label">University</label>
 <select
                                name="University"
                                value={formData.University}
                                onChange={handleChange}
                                className="apollo-select"
                            >
                                <option value="">Select University</option>
                                <option value="Ukzn Howard">Ukzn Howard</option>
                                <option value="Dut">Dut</option>
                                <option value="Ukzn Nelson">Ukzn Nelson</option>
                            
                            </select> */}

                            <label className="apollo-label">Capacity</label>
                            <input
                                type="number"
                                name="Capacity"
                                value={formData.Capacity}
                                onChange={handleChange}
                                placeholder="Capacity"
                                className="apollo-input"
                                required
                            />

                            <label className="apollo-label">Your cheapest room</label>
                            <input
                                type="number"
                                name="Best_Price"
                                value={formData.Best_Price}
                                onChange={handleChange}
                                placeholder="Best Price"
                                className="apollo-input"
                                required
                            />

                            <label className="apollo-label">City</label>
                            <select
                                name="BuildingCity"
                                value={formData.BuildingCity}
                                onChange={handleChange}
                                className="apollo-select"
                                required
                            >
                                <option value="">Select City</option>
                                <option value="Durban">Durban</option>
                                <option value="Pietermaritzburg">Pietermaritzburg</option>
                                <option value="Johannesburg">Johannesburg</option>
                                <option value="Cape Town">Cape Town</option>
                                <option value="Pretoria">Pretoria</option>
                            </select>

                            <label className="apollo-label">Preferred Gender</label>
                            <select
                                name="PreferedGender"
                                value={formData.PreferedGender}
                                onChange={handleChange}
                                className="apollo-select"
                                required
                            >
                                <option value="">Select Gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Male and Female">Male and Female</option>
                            </select>

                            <label className="apollo-label">Zone Area</label>
                            <select
                                name="ZoneName"
                                value={formData2.ZoneName}
                                onChange={handleChange}
                                className="apollo-select"
                                required
                            >
                                <option value="">Select Zone</option>
                                <option value="Westridge">Westridge</option>
                                <option value="Manor Gardens Area">Manor Gardens Area</option>
                                <option value="Glenmore">Glenmore</option>
                                <option value="Carrington Heights Area">Carrington Heights Area</option>
                                <option value="Umbilo">Umbilo</option>
                                <option value="Glenwood Area">Glenwood Area</option>
                                <option value="Lower Glenwood Area">Lower Glenwood Area</option>
                            </select>

                            <label className="apollo-label apollo-label-center">Types of Apartments Offered (Room Types)</label>
                            {/* <div className="apollo-checkbox-group">
                                {['Single', 'Double', 'Triple', 'Quad', 'Bachelor'].map((roomType) => (
                                    <label key={roomType} className={`apollo-checkbox ${formData.Setup?.includes(roomType) ? 'selected' : ''}`}>
                                        <input
                                            type="checkbox"
                                            name="Setup"
                                            value={roomType}
                                            onChange={handleCheckboxChange}
                                            checked={formData.Setup?.includes(roomType)}
                                            className="apollo-checkbox-input"
                                        />
                                        {roomType}
                                    </label>
                                ))}
                            </div> */}

                            <div className="apollo-checkbox-group">
                                {Object.keys(roomTypesWithVariations).map((roomType) => (
                                    <div key={roomType}>
                                        {/* Main room type label */}
                                        <label
                                            onClick={() => handleRoomTypeSelect(roomType)}
                                            className={`apollo-checkbox ${selectedSetups.includes(roomType) ? 'selected' : 'deselected'}`}
                                            
                                        >
                                            {roomType}
                                        </label>
                                    </div>
                                ))}
                            </div>
                            <div className="apollo-form-group">
                                <div className="apollo-input-wrapper">
                                    <label className="apollo-label">Building Interior Image 1:</label>
                                    <input
                                        type="file"
                                        name="BuildingInteriorImage1"
                                        onChange={(e) => handleImageChange(e, 'BuildingInteriorImage1')}
                                        className="apollo-file-input"
                                    />
                                </div>
                                <div className="apollo-input-wrapper">
                                    <label className="apollo-label">Building Interior Image 2:</label>
                                    <input
                                        type="file"
                                        name="BuildingInteriorImage2"
                                        onChange={(e) => handleImageChange(e, 'BuildingInteriorImage2')}
                                        className="apollo-file-input"
                                    />
                                </div>
                                <div className="apollo-input-wrapper">
                                    <label className="apollo-label">Building Interior Image 3:</label>
                                    <input
                                        type="file"
                                        name="BuildingInteriorImage3"
                                        onChange={(e) => handleImageChange(e, 'BuildingInteriorImage3')}
                                        className="apollo-file-input"
                                    />
                                </div>
                            </div>

                            <div className="apollo-form-group">
                                <div className="apollo-input-wrapper">
                                    <label className="apollo-label">Building Exterior Image 4:</label>
                                    <input
                                        type="file"
                                        name="BuildingExteriorImage4"
                                        onChange={(e) => handleImageChange(e, 'BuildingExteriorImage4')}
                                        className="apollo-file-input"
                                    />
                                </div>
                                <div className="apollo-input-wrapper">
                                    <label className="apollo-label">Building Exterior Image 5:</label>
                                    <input
                                        type="file"
                                        name="BuildingExteriorImage5"
                                        onChange={(e) => handleImageChange(e, 'BuildingExteriorImage5')}
                                        className="apollo-file-input"
                                    />
                                </div>
                                <div className="apollo-input-wrapper">
                                    <label className="apollo-label">Building Exterior Image 6:</label>
                                    <input
                                        type="file"
                                        name="BuildingExteriorImage6"
                                        onChange={(e) => handleImageChange(e, 'BuildingExteriorImage6')}
                                        className="apollo-file-input"
                                    />
                                </div>
                            </div>

                            <button type="submit" className="apollo-button">Save Building</button>
                            <button type='button' onClick={goBack}>Back</button>

                            <div className="apollo-image-previews">
                                {imagePreviews.BuildingInteriorImage1 && (
                                    <div className="apollo-image-preview">
                                        <label>Building Interior Image 1:</label>
                                        <img src={imagePreviews.BuildingInteriorImage1} alt="Building Interior 1 Preview" className="apollo-image" />
                                    </div>
                                )}
                                {/* Add similar sections for other image previews */}
                            </div>
                        </form>
                    </div>
                )}

{activeSection === 'AddApartment' && (
                    <div className="ducati-container">
                        <h1 className="ducati-title">Add a new apartment</h1>
                        <form onSubmit={handleSubmit2} className="ducati-form">
                            <div className="yamaha-field">
                                <label htmlFor="BuildingId" className="yamaha-label">Select Building</label>
                                <select
                                    id="BuildingId"
                                    name="BuildingId"
                                    value={formData2.BuildingId}
                                    required
                                    onChange={handleChange2}
                                    className="yamaha-select"
                                
                                >
                                    <option value="">Select Building</option>
                                    {buildings.map((building) => (
                                        <option key={building.BuildingId} value={building.BuildingId}>
                                            {building.BuildingName}
                                        </option>
                                    ))}
                                </select>
                            </div>

                            <div className="kawasaki-field">
                                <input
                                    type="text"
                                    name="ApartmentNumber"
                                    value={formData2.ApartmentNumber}
                                    required
                                    onChange={handleChange2}
                                    placeholder="Room Name (e.g., Room A1)"
                                    className="kawasaki-input"
                                    
                                />
                            </div>
                            <div className="kawasaki-field">
                                <input
                                    type="text"
                                    name="RoomIdentifier"
                                    value={formData2.RoomIdentifier}
                                    required
                                    onChange={handleChange2}
                                    placeholder="Room Identifier (e.g.,  081_BWN_A02)"
                                    className="kawasaki-input"

                                />
                            </div>

                            <div className="kawasaki-field">
                                <input
                                    type="number"
                                    name="ApartmentPrice"
                                    value={formData2.ApartmentPrice}
                                    onChange={handleChange2}
                                    required
                                    placeholder="Price"
                                    className="kawasaki-input"
                                />
                            </div>

                            <div className="harley-field">
                                <label htmlFor="ApartmentPreferedGender" className="harley-label">Preferred Gender</label>
                                <select
                                    id="ApartmentPreferedGender"
                                    name="ApartmentPreferedGender"
                                    value={formData2.ApartmentPreferedGender}
                                    onChange={handleChange2}
                                    required
                                    className="harley-select"
                                >
                                    <option value="">Select Gender</option>
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Male & Female">Male & Female</option>
                                </select>
                            </div>

                            {/* <div className="triumph-field">
                                <label htmlFor="LivingArrangement" className="triumph-label">Living Arrangement</label>
                                <select
                                    id="LivingArrangement"
                                    name="LivingArrangement"
                                    value={formData2.LivingArrangement}
                                    onChange={handleChange2}
                                    required
                                    className="triumph-select"
                                >
                                    <option value="">Select Living Arrangement</option>
                                    <option value="Single">Single Room</option>
                                    <option value="Double">Sharing Double</option>
                                    <option value="Triple">Sharing Triple</option>
                                    <option value="Quad">Sharing Quad</option>
                                    <option value="Bachelor">Bachelor Pod</option>
                                </select>
                            </div> */}

                            <div className="triumph-field">
                                <label className="triumph-label">Select a Type for the Apartment (Room Types)</label>
                                <div className="apollo-checkbox-group">
                                    {Object.keys(roomTypesWithVariations).map((roomType) => (
                                        <div key={roomType}>
                                            {/* Main room type label */}
                                            <label
                                                onClick={() => toggleRoomType(roomType)}
                                                className={`apollo-checkbox ${formData2.LivingArrangement?.startsWith(roomType) ? 'selected' : ''}`}
                                            >
                                                {roomType}
                                            </label>

                                            {/* Show variations if this room type is expanded */}
                                            {expandedRoomType === roomType && (
                                                <div className="apollo-variation-group">
                                                    {roomTypesWithVariations[roomType].map((variation) => (
                                                        <label
                                                            key={`${roomType}-${variation}`}
                                                            onClick={() => handleLivingArrangementSelection(roomType, variation)}
                                                            className={`apollo-checkbox ${formData2.LivingArrangement === `${roomType} - ${variation}` ? 'selected' : ''}`}
                                                        >
                                                            <input
                                                                type="radio" // Optional: visually indicate single selection
                                                                name="LivingArrangement"
                                                                value={`${roomType} - ${variation}`}
                                                                checked={formData2.LivingArrangement === `${roomType} - ${variation}`}
                                                                readOnly
                                                                className="apollo-checkbox-input"                                    
                                                            />
                                                            {variation}
                                                        </label>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                    ))}
                                </div>


                            </div>

                            <div className="suzuki-field">
                                <label htmlFor="ApartmentRoomImage" className="suzuki-label">Apartment Room Image:</label>
                                <input
                                    type="file"
                                    name="ApartmentRoomImage"
                                    onChange={handleImageChange2}
                                    className="suzuki-input"
                                />
                            </div>

                            <button type="submit" className="ducati-button">Save Apartment</button>
                            <button type='button' onClick={goBack}>Back</button>


                            <div className="bmw-previews">
                                {imagePreviewsOnUpload.ApartmentLoungeImage && (
                                    <div className="bmw-preview">
                                        <label className="bmw-label">Lounge Image Preview:</label>
                                        <img src={imagePreviewsOnUpload.ApartmentLoungeImage} alt="Lounge Preview" className="bmw-image" />
                                    </div>
                                )}
                                {imagePreviewsOnUpload.ApartmentKitchenImage && (
                                    <div className="bmw-preview">
                                        <label className="bmw-label">Kitchen Image Preview:</label>
                                        <img src={imagePreviewsOnUpload.ApartmentKitchenImage} alt="Kitchen Preview" className="bmw-image" />
                                    </div>
                                )}
                                {imagePreviewsOnUpload.ApartmentBathroomImage && (
                                    <div className="bmw-preview">
                                        <label className="bmw-label">Bathroom Image Preview:</label>
                                        <img src={imagePreviewsOnUpload.ApartmentBathroomImage} alt="Bathroom Preview" className="bmw-image" />
                                    </div>
                                )}
                                {imagePreviewsOnUpload.ApartmentRoomImage && (
                                    <div className="bmw-preview">
                                        <label className="bmw-label">Room Image Preview:</label>
                                        <img src={imagePreviewsOnUpload.ApartmentRoomImage} alt="Room Preview" className="bmw-image" />
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                )}

                {/* {activeSection === 'Apartments' && (
                    <div>
                        <h1>Apartments</h1>
                        {apartments.map(apartment => (
                            <div key={apartment.ApartmentId}>
                                <h3>{apartment.ApartmentNumber}</h3>
                                <p>{`Building ID: ${apartment.BuildingId}, Price: ${apartment.ApartmentPrice}`}</p>
                                <button onClick={() => updateApartment(apartment.ApartmentId, apartment.BuildingId)}>Edit</button>
                                <button>Delete</button>
                            </div>
                        ))}
                    </div>
                )} */}
                {activeSection === 'Apartments' && (
                    <div>
                        <h1>View All Apartments</h1>
                        {apartments.length > 0 ? (
                            Object.entries(
                                apartments.reduce((groups, apartment) => {
                                    const { BuildingId } = apartment;
                                    if (!groups[BuildingId]) {
                                        groups[BuildingId] = [];
                                    }
                                    groups[BuildingId].push(apartment);
                                    return groups;
                                }, {})
                            ).map(([buildingId, buildingApartments]) => {
                                // Find the building name that matches the buildingId
                                const building = buildings.find(b => b.BuildingId === parseInt(buildingId));
                                const buildingName = building ? building.BuildingName : `Building ID: ${buildingId}`;

                                return (
                                    <div key={buildingId} style={{ marginBottom: '30px' }}>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                            <button
                                                onClick={() => toggleExpand(buildingId)}
                                                style={{
                                                    padding: '10px 15px',
                                                    backgroundColor: '#ff5a5f',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '15px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                {expandedBuildings[buildingId]
                                                    ? `Minimize : ${buildingName}`
                                                    : `Expand : ${buildingName}`}
                                            </button>
                                        </div>
                                        {expandedBuildings[buildingId] && (
                                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                                                {buildingApartments.map((apartment) => (
                                                    <div
                                                        key={apartment.ApartmentId}
                                                        className="mountain-card"
                                                        style={{
                                                            backgroundImage: `url(https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage})`,
                                                        }}
                                                    >
                                                        <div className="mountain-content">
                                                            <h3 className="mountain-title">{apartment.ApartmentNumber}</h3>
                                                            <h3 className="mountain-title">{apartment.LivingArrangement}</h3>
                                                            <h3 className="mountain-title">{apartment.PreferedGender}</h3>
                                                            <h3 className="mountain-title">
                                                                {apartment.Capacity2 === 0
                                                                    ? "Full"
                                                                    : `${apartment.Capacity2} bed${apartment.Capacity2 > 1 ? 's' : ''} available`}
                                                            </h3>
                                                            <p className="mountain-price">{`Price: R${apartment.ApartmentPrice}`}</p>
                                                            <button
                                                                onClick={() => updateApartment(apartment.ApartmentId, apartment.BuildingId)}
                                                                className="mountain-button"
                                                            >
                                                                Edit
                                                            </button>
                                                            <button
                                                                onClick={() => handleDeleteClick(apartment.ApartmentId)}
                                                                className="mountain-button delete"
                                                            >
                                                                Delete
                                                            </button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                        {showDeleteDialog && (
                                            <div style={{
                                                position: 'fixed',
                                                top: '50%',
                                                left: '50%',
                                                transform: 'translate(-50%, -50%)',
                                                backgroundColor: '#fff',
                                                padding: '20px',
                                                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                                borderRadius: '8px',
                                                zIndex: '999',
                                            }}>
                                                <h3>Are you sure you want to delete this apartment?</h3>
                                                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                                    <button
                                                        onClick={confirmDeleteForRoom}
                                                        style={{
                                                            padding: '10px',
                                                            backgroundColor: '#dc3545',
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                            marginRight: '10px',
                                                        }}
                                                    >
                                                        Confirm
                                                    </button>
                                                    <button
                                                        onClick={cancelDeleteForRoom}
                                                        style={{
                                                            padding: '10px',
                                                            backgroundColor: '#6c757d',
                                                            color: '#fff',
                                                            border: 'none',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            })
                        ) : (
                            <p>No Apartments added by you yet!</p>
                        )}
                    </div>
                )}


                {/* {activeSection === 'Buildings' && (

                    <div>
                        <h1>Buildings</h1>
                        {buildings.map(build => (
                            <div key={build.BuildingId}>
                                <h3>{build.BuildingName}</h3>
                                <p>{`Building ID: ${build.BuildingId},City: ${build.BuildingCity}`}</p>
                                <button onClick={() => updateSection(build.BuildingId)}>Edit</button>
                                <button>Delete</button>
                            </div>
                        ))}

                    </div>
                )} */}

                {activeSection === 'Buildings' && (
                    <div>
                        <h1>All Buildings</h1>
                        {buildings.length > 0 ? (
                            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', gap: '20px' }}>
                                {buildings.map((build) => (
                                    <div
                                        key={build.BuildingId}
                                        style={{
                                            width: '30%',
                                            backgroundColor: '#f8f9fa',
                                            border: '1px solid #ddd',
                                            borderRadius: '8px',
                                            padding: '20px',
                                            margin: '10px',
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            backgroundImage: `url(https://backendjaes.passiontrials.co.za/src${build.BuildingInteriorImage1})`, // Replace 'ImageURL' with the correct key from your building object
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            color: '#333',
                                        }}
                                    >
                                        <h3 style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>
                                            {build.BuildingName}
                                        </h3>
                                        <h3 style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>
                                            {build.PreferedGender}
                                        </h3>
                                        <h3 style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>
                                            <span>{build.Capacity > 0 ? `${build.Capacity} Beds Available` : 'No Beds Available'}</span>

                                        </h3>
                                        <h3 style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px' }}>
                                            From {build.Best_Price} per room
                                        </h3>
                                        <p style={{ backgroundColor: 'rgba(255, 255, 255, 0.5)', padding: '5px', borderRadius: '5px', color: 'black' }}>
                                            {` ${build.BuildingLocation}`}
                                        </p>
                                        <div style={{
                                            marginTop: '10px',
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                            padding: '10px',
                                            backgroundColor: 'rgba(255, 255, 255, 0.5)',
                                            borderRadius: '0 0 12px 12px'
                                        }}>
                                            <button
                                                onClick={() => updateSection(build.BuildingId)}
                                                style={{
                                                    padding: '10px',
                                                    backgroundColor: '#00aaff',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    flexGrow: '1',
                                                    marginRight: '5px',
                                                    transition: 'background-color 0.3s',
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#0077cc')}
                                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#00aaff')}
                                            >
                                                Edit
                                            </button>
                                            <button
                                                onClick={() => showDialog(build.BuildingId)}
                                                style={{
                                                    padding: '10px',
                                                    backgroundColor: '#ff5a5f',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '8px',
                                                    cursor: 'pointer',
                                                    flexGrow: '1',
                                                    transition: 'background-color 0.3s',
                                                }}
                                                onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#cc494c')}
                                                onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = '#ff5a5f')}
                                            >
                                                Delete
                                            </button>
                                        </div>
                                    </div>
                                ))}

                                {isDialogVisible && (
                                    <div style={{
                                        position: 'fixed',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        backgroundColor: '#fff',
                                        padding: '20px',
                                        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
                                        borderRadius: '8px',
                                        zIndex: '999',
                                    }}>
                                        <h3>Are you sure you want to delete this building?</h3>
                                        <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
                                            <button
                                                onClick={confirmDelete}
                                                style={{
                                                    padding: '10px',
                                                    backgroundColor: '#dc3545',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    marginRight: '10px',
                                                }}
                                            >
                                                Confirm
                                            </button>
                                            <button
                                                onClick={cancelDelete}
                                                style={{
                                                    padding: '10px',
                                                    backgroundColor: '#6c757d',
                                                    color: '#fff',
                                                    border: 'none',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                }}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p>No properties added by you yet!</p>
                        )}
                    </div>
                )}



                {/* {activeSection === 'updateBuilding' && (

                    <div>
                        <h1>Update this building</h1>
                        <form onSubmit={handleSubmit3}>
                            {Object.entries(uBuilding).map(
                                ([key, value]) =>
                                    key !== 'BuildingId' && (
                                        <label key={key}>
                                            {key
                                                .replace(/([A-Z])/g, '$1')
                                                .replace(/^./, (str) => str.toUpperCase())

                                            }
                                            :

                                            <input type='text' name={key} value={value || ''} onChange={handleChange3} />
                                            <br></br>
                                        </label>

                                    )
                            )}
                            <button type='submit'>Update Building</button>
                            <br></br>
                            <button onClick={backToManage}>Return back</button>
                        </form>
                    </div>
                )} */}
               {activeSection === 'updateBuilding' && (
                    <div>
                        <h1>Update this building</h1>
                        <form onSubmit={handleSubmit3}>
                            <label>Building Name</label>
                            <input
                                type="text"
                                name="BuildingName"
                                value={uBuilding.BuildingName}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            />
                            <br />



                            <label>Building Location</label>
                            <input
                                type="text"
                                name="BuildingLocation"
                                value={uBuilding.BuildingLocation}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            />
                            <br />

                            <label>City</label>
                            <select
                                name="BuildingCity"
                                value={uBuilding.BuildingCity || ''}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {uBuilding.BuildingCity ? (
                                    <option value={uBuilding.BuildingCity}>Current: {uBuilding.BuildingCity}</option>
                                ) : (
                                    <option value="">Select City</option>
                                )}
                                <option value="Durban">Durban</option>
                                <option value="Pietermaritzburg">Pietermaritzburg</option>
                                <option value="Johannesburg">Johannesburg</option>
                                <option value="Cape Town">Cape Town</option>
                                <option value="Pretoria">Pretoria</option>
                            </select>
                            <br />
                            <label>Building Description</label>
                            <input
                                type="text"
                                name="BuildingDescript"
                                value={uBuilding.BuildingDescript}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            />
                            <br />
                            <label>Preferred Gender</label>
                            <select
                                name="PreferedGender"
                                value={uBuilding.PreferedGender || ''}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {uBuilding.PreferedGender ? (
                                    <option value={uBuilding.PreferedGender}>Current: {uBuilding.PreferedGender}</option>
                                ) : (
                                    <option value="">Select Gender</option>
                                )}
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Male & Female">Male & Female</option>
                            </select>
                            <br />

                            {/* Room Types (Setup) - Checkboxes */}
                            <label style={{ display: 'block', marginTop: '10px' }}>Types of Apartments offered (Room Types)</label>
                            <div style={{ marginBottom: '15px' }}>
                                <label style={{
                                    marginRight: '15px',
                                    backgroundColor: uBuilding.Setup?.includes('Single') ? '#00BFFF' : '#f0f0f0', // Color for checked
                                    color: uBuilding.Setup?.includes('Single') ? '#fff' : '#000', // Change text color
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    <input
                                        type="checkbox"
                                        name="Setup"
                                        value="Single"
                                        onChange={handleCheckboxChange2}
                                        checked={uBuilding.Setup?.includes('Single')}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Single Room
                                </label>
                                <label style={{
                                    marginRight: '15px',
                                    backgroundColor: uBuilding.Setup?.includes('Double') ? '#00BFFF' : '#f0f0f0',
                                    color: uBuilding.Setup?.includes('Double') ? '#fff' : '#000',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    <input
                                        type="checkbox"
                                        name="Setup"
                                        value="Double"
                                        onChange={handleCheckboxChange2}
                                        checked={uBuilding.Setup?.includes('Double')}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Sharing Double
                                </label>
                                <label style={{
                                    marginRight: '15px',
                                    backgroundColor: uBuilding.Setup?.includes('Twin') ? '#00BFFF' : '#f0f0f0',
                                    color: uBuilding.Setup?.includes('Twin') ? '#fff' : '#000',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    <input
                                        type="checkbox"
                                        name="Setup"
                                        value="Twin"
                                        onChange={handleCheckboxChange2}
                                        checked={uBuilding.Setup?.includes('Twin')}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Sharing Twin
                                </label>
                                {/* <label style={{
                                    marginRight: '15px',
                                    backgroundColor: uBuilding.Setup?.includes('Quad') ? '#00BFFF' : '#f0f0f0',
                                    color: uBuilding.Setup?.includes('Quad') ? '#fff' : '#000',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    <input
                                        type="checkbox"
                                        name="Setup"
                                        value="Quad"
                                        onChange={handleCheckboxChange2}
                                        checked={uBuilding.Setup?.includes('Quad')}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Sharing Quad
                                </label> */}
                                <label style={{
                                    marginRight: '15px',
                                    backgroundColor: uBuilding.Setup?.includes('Bachelor') ? '#00BFFF' : '#f0f0f0',
                                    color: uBuilding.Setup?.includes('Bachelor') ? '#fff' : '#000',
                                    padding: '5px 10px',
                                    borderRadius: '5px'
                                }}>
                                    <input
                                        type="checkbox"
                                        name="Setup"
                                        value="Bachelor"
                                        onChange={handleCheckboxChange2}
                                        checked={uBuilding.Setup?.includes('Bachelor')}
                                        style={{ marginRight: '5px' }}
                                    />
                                    Bachelor Pod
                                </label>
                            </div>

                            <label>Zone</label>
                            <select
                                name="ZoneName"
                                value={uBuilding.ZoneName || ''}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {uBuilding.ZoneName ? (
                                    <option value={uBuilding.ZoneName}>Current: {uBuilding.ZoneName}</option>
                                ) : (
                                    <option value="">Select Zone</option>
                                )}
                                <option value="Westridge">Westridge</option>
                                <option value="Manor Gardens Area">Manor Gardens Area</option>
                                <option value="Glenmore">Glenmore</option>
                                <option value="Carrington Heights Area">Carrington Heights Area</option>
                                <option value="Umbilo">Umbilo</option>
                                <option value="Lower Glenwood Area">Lower Glenwood Area</option>
                            </select>
                            <br />

                            {/* Capacity */}
                            <label>Capacity</label>
                            <input
                                type="number"
                                name="Capacity"
                                value={uBuilding.Capacity}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            />
                            <br />

                            {/* Best Price */}
                            <label>Best Price</label>
                            <input
                                type="number"
                                name="Best_Price"
                                value={uBuilding.Best_Price}
                                onChange={handleChange3}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            />
                            <br />

                            {/* Image Uploads */}
                            <label>Building Interior Image 1:</label>

                            <input
                                type="file"
                                name="BuildingInteriorImage1"
                                accept="image/*"
                                onChange={handleChange3}
                                style={{ display: 'block', marginBottom: '10px' }}
                            />

                            <br />
                            <label>Building Interior Image 2:</label>
                            <input
                                type="file"
                                name="BuildingInteriorImage2"
                                accept="image/*"
                                onChange={handleChange3}
                                style={{ display: 'block', marginBottom: '10px' }}
                            />

                            <br />
                            <label>Building Interior Image 3:</label>
                            <input
                                type="file"
                                name="BuildingInteriorImage3"
                                accept="image/*"
                                onChange={handleChange3}
                                style={{ display: 'block', marginBottom: '10px' }}
                            />

                            <br />

                            <label>Building Exterior Image 4:</label>
                            <input
                                type="file"
                                name="BuildingExteriorImage4"
                                accept="image/*"
                                onChange={handleChange3}
                                style={{ display: 'block', marginBottom: '10px' }}
                            />

                            <br />
                            <label>Building Exterior Image 5:</label>
                            <input
                                type="file"
                                name="BuildingExteriorImage5"
                                accept="image/*"
                                onChange={handleChange3}
                                style={{ display: 'block', marginBottom: '10px' }}
                            />

                            <br />
                            <label>Building Exterior Image 6:</label>
                            <input
                                type="file"
                                name="BuildingExteriorImage6"
                                accept="image/*"
                                onChange={handleChange3}
                                style={{ display: 'block', marginBottom: '10px' }}
                            />

                            <br />
                            <button type="submit" style={{ padding: '10px 20px', marginTop: '20px', color: '#fff', borderRadius: '5px' }}>Update Building</button>
                            <br />
                            <button onClick={backToManage} style={{ padding: '10px 20px', marginTop: '10px', color: '#fff', backgroundColor: '#6c757d', borderRadius: '5px' }}>Return back</button>
                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '20px' }}>
                                {/* Building Interior Image 1 */}
                                {(imagePreviews.BuildingInteriorImage1 || uBuilding.BuildingInteriorImage1) && (
                                    <>
                                        <div>
                                            <label>Building Interior Image 1:</label>
                                            {imagePreviews.BuildingInteriorImage1 ? (
                                                <img
                                                    src={imagePreviews.BuildingInteriorImage1}
                                                    alt="Building Interior 1 Preview"
                                                    style={{ width: '150px', height: '150px', marginTop: '10px' }}
                                                />
                                            ) : (
                                                uBuilding.BuildingInteriorImage1 && (
                                                    <img
                                                        src={`https://backendjaes.passiontrials.co.za/src${uBuilding.BuildingInteriorImage1}`}
                                                        alt="Building Interior 1"
                                                        style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
                                                    />
                                                )
                                            )}
                                        </div>
                                    </>
                                )}
                                <br />

                                {/* Building Interior Image 2 */}
                                {(imagePreviews.BuildingInteriorImage2 || uBuilding.BuildingInteriorImage2) && (
                                    <>
                                        <label>Building Interior Image 2:</label>
                                        {imagePreviews.BuildingInteriorImage2 ? (
                                            <img
                                                src={imagePreviews.BuildingInteriorImage2}
                                                alt="Building Interior 2 Preview"
                                                style={{ width: '150px', height: '150px', marginTop: '10px' }}
                                            />
                                        ) : (
                                            uBuilding.BuildingInteriorImage2 && (
                                                <img
                                                    src={`https://backendjaes.passiontrials.co.za/src${uBuilding.BuildingInteriorImage2}`}
                                                    alt="Building Interior 2"
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                                <br />

                                {/* Building Interior Image 3 */}
                                {(imagePreviews.BuildingInteriorImage3 || uBuilding.BuildingInteriorImage3) && (
                                    <>
                                        <label>Building Interior Image 3:</label>
                                        {imagePreviews.BuildingInteriorImage3 ? (
                                            <img
                                                src={imagePreviews.BuildingInteriorImage3}
                                                alt="Building Interior 3 Preview"
                                                style={{ width: '150px', height: '150px', marginTop: '10px' }}
                                            />
                                        ) : (
                                            uBuilding.BuildingInteriorImage3 && (
                                                <img
                                                    src={`https://backendjaes.passiontrials.co.za/src${uBuilding.BuildingInteriorImage3}`}
                                                    alt="Building Interior 3"
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                                <br />

                                {/* Building Exterior Image 4 */}
                                {(imagePreviews.BuildingExteriorImage4 || uBuilding.BuildingExteriorImage4) && (
                                    <>
                                        <label>Building Exterior Image 4:</label>
                                        {imagePreviews.BuildingExteriorImage4 ? (
                                            <img
                                                src={imagePreviews.BuildingExteriorImage4}
                                                alt="Building Exterior 4 Preview"
                                                style={{ width: '150px', height: '150px', marginTop: '10px' }}
                                            />
                                        ) : (
                                            uBuilding.BuildingExteriorImage4 && (
                                                <img
                                                    src={`https://backendjaes.passiontrials.co.za/src${uBuilding.BuildingExteriorImage4}`}
                                                    alt="Building Exterior 4"
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                                <br />

                                {/* Building Exterior Image 5 */}
                                {(imagePreviews.BuildingExteriorImage5 || uBuilding.BuildingExteriorImage5) && (
                                    <>
                                        <label>Building Exterior Image 5:</label>
                                        {imagePreviews.BuildingExteriorImage5 ? (
                                            <img
                                                src={imagePreviews.BuildingExteriorImage5}
                                                alt="Building Exterior 5 Preview"
                                                style={{ width: '150px', height: '150px', marginTop: '10px' }}
                                            />
                                        ) : (
                                            uBuilding.BuildingExteriorImage5 && (
                                                <img
                                                    src={`https://backendjaes.passiontrials.co.za/src${uBuilding.BuildingExteriorImage5}`}
                                                    alt="Building Exterior 5"
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                                <br />

                                {/* Building Exterior Image 6 */}
                                {(imagePreviews.BuildingExteriorImage6 || uBuilding.BuildingExteriorImage6) && (
                                    <>
                                        <label>Building Exterior Image 6:</label>
                                        {imagePreviews.BuildingExteriorImage6 ? (
                                            <img
                                                src={imagePreviews.BuildingExteriorImage6}
                                                alt="Building Exterior 6 Preview"
                                                style={{ width: '150px', height: '150px', marginTop: '10px' }}
                                            />
                                        ) : (
                                            uBuilding.BuildingExteriorImage6 && (
                                                <img
                                                    src={`https://backendjaes.passiontrials.co.za/src${uBuilding.BuildingExteriorImage6}`}
                                                    alt="Building Exterior 6"
                                                    style={{ width: '150px', height: '150px', objectFit: 'cover', marginTop: '10px' }}
                                                />
                                            )
                                        )}
                                    </>
                                )}
                            </div>

                        </form>
                    </div>
                )}

{activeSection === 'updateApartment' && (
                    <div>
                        <h2>{`Update ${buildingName} Apartment`}</h2>


                        <form onSubmit={handleSubmit4}>
                            <input
                                type="hidden"
                                name="BuildingManager"
                                value={uApartment.BuildingManager || ''}
                                onChange={handleChange4}
                                placeholder="Building Manager"
                            />
                            {/* <input
                                type="text"
                                name="Guest"
                                value={uApartment.Guest}
                                onChange={handleChange4}
                                placeholder="Guest"
                            /> */}
                            <input
                                type="text"
                                name="ApartmentNumber"
                                value={uApartment.ApartmentNumber}
                                onChange={handleChange4}
                                placeholder="Apartment Number"
                            />
                            <input
                                type="number"
                                name="ApartmentPrice"
                                value={uApartment.ApartmentPrice}
                                onChange={handleChange4}
                                placeholder="Apartment Price"
                            />
                            <input
                                type="hidden"
                                name="ApartmentType"
                                value={uApartment.ApartmentType}


                            />
                            <br></br>
                            <label>Preferred Gender</label>
                            <select
                                name="ApartmentPreferedGender"
                                value={uApartment.ApartmentPreferedGender || ''}
                                onChange={handleChange4}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {/* Display the currently selected gender */}
                                {uApartment.ApartmentPreferedGender ? (
                                    <option value={uApartment.ApartmentPreferedGender}>
                                        Current: {uApartment.ApartmentPreferedGender}
                                    </option>
                                ) : (
                                    <option value="">Select Gender</option>
                                )}

                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Male & Female">Male & Female</option>
                            </select>
                            <br></br>
                            <input
                                type="hidden"
                                name="ApartmentDeposit"
                                value={uApartment.ApartmentDeposit}

                            />
                            <input
                                type="hidden"
                                name="ApartmentRating"
                                value={uApartment.ApartmentRating}

                            />
                            <label>Living Arrangement</label>

                            {/* <select
                                name="LivingArrangement"
                                value={uApartment.LivingArrangement || ''}
                                onChange={handleChange4}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {uApartment.LivingArrangement ? (
                                    <option value={uApartment.LivingArrangement}>Current: {uApartment.LivingArrangement}</option>
                                ) : (
                                    <option value="">Select Living Arrangement</option>
                                )}
                                <option value="Single">Single Room</option>
                                <option value="Single + Ensuite">Single with Ensuite</option>
                                <option value="Single + Kitchenette">Single with Kitchenette</option>
                                <option value="Double">Sharing Double</option>
                                <option value="Triple">Sharing Triple</option>
                                <option value="Quad">Sharing Quad</option>

                                <option value="Bachelor">Bachelor Pad</option>
                            </select> */}

                            <div className="apollo-checkbox-group">
                                {Object.keys(roomTypesWithVariations).map((roomType) => (
                                    <div key={roomType}>
                                        {/* Main room type label */}
                                        <label
                                            onClick={() => toggleRoomType2(roomType)}
                                            className={`apollo-checkbox ${uApartment.LivingArrangement?.startsWith(roomType) ? 'selected' : ''}`}
                                        >
                                            {roomType}
                                        </label>

                                        {/* Show variations if this room type is expanded */}
                                        {expandedRoomType2 === roomType && (
                                            <div className="apollo-variation-group">
                                                {roomTypesWithVariations[roomType].map((variation) => (
                                                    <label
                                                        key={`${roomType}-${variation}`}
                                                        onClick={() => handleLivingArrangementSelection2(roomType, variation)}
                                                        className={`apollo-checkbox ${uApartment.LivingArrangement === `${roomType} - ${variation}` ? 'selected' : ''}`}
                                                    >
                                                        {variation}
                                                    </label>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                            <br></br>

                            <label>Status</label>
                            <select
                                name="Status"
                                value={uApartment.Status || ''}
                                onChange={handleChange4}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {uApartment.Status ? (
                                    <option value={uApartment.Status}>Current: {uApartment.Status}</option>
                                ) : (
                                    <option value="">Select Status</option>
                                )}
                                <option value="Vacant">Vacant</option>
                                <option value="Occupied">Occupied</option>
                                <option value="Not Available">Not Available</option>
                            </select>
                            <span onClick={handleStatusOnUpdateSection(uApartment.ApartmentId)}></span>
                            {userCount > 0 && (
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
                                    <i className="fa fa-exclamation-circle" aria-hidden="true" style={{ color: 'green', marginRight: '8px' }}></i>
                                    <p style={{ color: 'green', margin: 0 }}>{`Number of users associated with this apartment: ${userCount}`}</p>
                                </div>

                            )}
                            <br></br>
                            <input
                                type="hidden"
                                name="BedRoomCount"
                                value={uApartment.BedRoomCount}

                            />
                            <input
                                type="hidden"
                                name="Date"
                                value={new Date().toLocaleDateString('en-US', {
                                    day: "numeric", month: 'short', year: 'numeric', hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                    hour12: true,
                                })}
                            />
                            <br></br>
                            <input
                                type="hidden"
                                name="ApartmentSize"
                                value={uApartment.ApartmentSize}
                            />
                            <br></br>

                            <label>Living Space</label>
                            <select
                                name="LivingSpace"
                                disabled
                                value={uApartment.LivingSpace || ''}
                                onChange={handleChange4}
                                style={{ padding: '10px', margin: '10px 0', width: '100%' }}
                            >
                                {uApartment.LivingSpace ? (
                                    <option value={uApartment.LivingSpace}>Current: {uApartment.LivingSpace}</option>
                                ) : (
                                    <option value="">Select Living Space</option>
                                )}
                                <option value="Shared">Shared</option>
                                <option value="Private">Private</option>

                            </select>
                            <br></br>
                            <label style={{ display: 'block', marginBottom: '5px' }}>TotalNumOfBeds:</label>

                            <input
                                type="number"
                                disabled
                                name="TotalNumOfBeds"
                                value={uApartment.TotalNumOfBeds}
                                onChange={handleChange4}
                                placeholder="Total Number of Beds"
                            />
                            <br></br>
                            <input
                                type="text"

                                name="RoomIdentifier"
                                value={uApartment.RoomIdentifier}
                                onChange={handleChange4}
                                placeholder="Room reference number"
                            />


                            {/* <br></br>
                            <label style={{ display: 'block', marginBottom: '5px' }}>Apartment Lounge Image:</label>
                         <input
                                type="file"
                                name="ApartmentLoungeImage"
                                accept="image/*"
                                onChange={handleChange4} // Handling image input without preview
                                style={{ display: 'block', marginBottom: '10px' }}
                            />
                            <br />
                            <label style={{ display: 'block', marginBottom: '5px' }}>Apartment Kitchen Image:</label>
                            <input
                                type="file"
                                name="ApartmentKitchenImage"
                                accept="image/*"
                                onChange={handleChange4} // Handling image input without 
                                style={{ display: 'block', marginBottom: '10px' }}
                            />
                            <br />
                            <label style={{ display: 'block', marginBottom: '5px' }}>Apartment Bathroom Image:</label>

                            <input
                                type="file"
                                name="ApartmentBathroomImage"
                                accept="image/*"
                                onChange={handleChange4} // Handling image input without preview
                                style={{ display: 'block', marginBottom: '10px' }}
                            />
                            <br /> */}
                            <label style={{ display: 'block', marginBottom: '5px' }}>Apartment Room Image:</label>
                            <input
                                type="file"
                                name="ApartmentRoomImage"
                                accept="image/*"
                                onChange={handleChange4} // Handling image input without preview
                                style={{ display: 'block', marginBottom: '10px' }}
                            />
                            <br />

                            <button type="submit">Update Apartment</button>
                            <button type='button' onClick={goBack}>Back</button>

                            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px', gap: '20px' }}>
                                {/* <div>
                                    <label>Apartment Lounge Image:</label>

                                    <img
                                        src={`https://backendjaes.passiontrials.co.za/src${uApartment.ApartmentLoungeImage}`}
                                        alt="Apartment Lounge"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </div> */}

                                {/* Display Apartment Kitchen Image */}
                                {/* <div>
                                    <label>Apartment Kitchen Image:</label>
                                    <img
                                        src={`https://backendjaes.passiontrials.co.za/src${uApartment.ApartmentKitchenImage}`}
                                        alt="Apartment Lounge"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </div> */}

                                {/* Display Apartment Bathroom Image */}
                                {/* <div>
                                    <label>Apartment Bathroom Image:</label>
                                    <img
                                        src={`https://backendjaes.passiontrials.co.za/src${uApartment.ApartmentBathroomImage}`}
                                        alt="Apartment Lounge"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </div> */}

                                {/* Display Apartment Room Image */}
                                <div>
                                    <label>Apartment Room Image:</label>
                                    <img
                                        src={`https://backendjaes.passiontrials.co.za/src${uApartment.ApartmentRoomImage}`}
                                        alt="Apartment Lounge"
                                        style={{ width: '150px', height: '150px', objectFit: 'cover' }}
                                    />
                                </div>
                            </div>
                        </form>

                    </div>
                )}

                {activeSection === 'AssignUser' && (
                    <div>
                        <div style={{ padding: '20px' }}>
                            <h2>Assign User to Apartment</h2>
                            <div>
                                <h3>Select User</h3>
                                <select onChange={(e) => setSelectedUser(e.target.value)} value={selectedUser}>
                                    <option value="">Select a User</option>
                                    {nonTenant.map(user => (
                                        <option key={user.UserId} value={user.UserId}>{user.Name} {user.Surname}</option>
                                    ))}
                                </select>
                            </div>
                            <div>
                                <h3>Select Plan</h3>
                                <select onChange={handlePlanChange} value={selectedPlan}>
                                    <option value="">Select Plan</option>
                                    <option value="A">Plan A (R3250)</option>
                                    <option value="B">Plan B (R8500)</option>
                                </select>
                            </div>
                            <div>
                                <h3>Select Apartment</h3>
                                <select onChange={handleApartmentChange} value={selectedApartment}>
                                    <option value="">Select an Apartment</option>
                                    {filteredRoomsAvail.map((apartment) => {
                                        // Find the corresponding building for the apartment using its BuildingId
                                        const building = buildings.find(
                                            (b) => b.BuildingId === apartment.BuildingId
                                        );
                                        return (
                                            <option key={apartment.ApartmentId} value={apartment.ApartmentId}>
                                                <span style={{ font: '' }}>
                                                    {building?.BuildingName}
                                                </span> | {apartment.ApartmentNumber} {apartment.LivingArrangement} -- R{apartment.ApartmentPrice} {handleAvailable(apartment)}
                                            </option>
                                        );
                                    })}
                                </select>
                            </div>

                            <div>
                                <h3>Select Payment Method</h3>
                                <select onChange={(e) => setPaymentMethod(e.target.value)} value={paymentMethod}>
                                    <option value="">Select Payment Method</option>
                                    <option value="EFT">EFT</option>
                                    <option value="YOCO Machine">YOCO Machine</option>
                                </select>
                            </div>
                            <div>

                                <button onClick={handleAssign}>
                                    {totalCost > 0 ? `Assign - Total: R${totalCost}` : 'Assign'}
                                </button>
                            </div>

                            <div style={{ marginTop: '20px' }}>
                                <h3>Assignments</h3>
                                {assignments.length > 0 ? (
                                    <ul>
                                        {assignments.map((assignment, index) => (
                                            <li key={index}>
                                                {assignment.userName} assigned to {assignment.apartmentNumber}
                                            </li>
                                        ))}
                                    </ul>
                                ) : <p>No assignments made yet.</p>}
                            </div>
                        </div>

                    </div>
                )}



            </div>
        </div >
    )


}

export default Properties;