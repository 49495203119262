import { useParams, Link, useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef } from 'react';
import { useAuth } from './AuthProvider'; // Ensure to import the useAuth hook

import './Allcard.css';
import './map.css';
import './amber.css';
import logo from './logowhitefooter.png';
import offer1 from './offer1.jpg';
import offer2 from './offer2.jpg';
import offer3 from './offer3.jpg';
import homeimage from './emmanuel-ikwuegbu-MSX3O-Sqa8U-unsplash.jpg';
import './Apartmentslide.css';
import './Search.css';
import LeafletMap from './components/LeafletMap.js'; // Import the LeafletMap component

const AllApartments = () => {
    const { buildingId } = useParams(); // Get buildingId from the URL
    const [apartments, setApartments] = useState([]);
    const [building, setBuilding] = useState(null); // To store building info, including image
    const [gender, setGender] = useState("");
    const [apartmentType, setApartmentType] = useState("");
    const [maxPrice, setMaxPrice] = useState("");
    const [selectedSuburb, setSelectedSuburb] = useState(null); // To store selected suburb
    const [selectedUniversity, setSelectedUniversity] = useState(null); // To store selected university
    const navigate = useNavigate();
    const sliderRef = useRef(null); // Ref to track the slider div
    const [showDropdown, setShowDropdown] = useState(false);
    const [buildings, setBuildings] = useState([]); // To store the list of buildings
    const { isLoggedIn, role } = useAuth(); // Use the isLoggedIn and role from useAuth

    const [sortOrder, setSortOrder] = useState('price_desc'); // Sort by price, high to low
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [roomType, setRoomType] = useState(''); // Room type
    const [budget, setBudget] = useState(''); // Budget
    const [buildingGender, setBuildingGender] = useState('');
    const userDashboard = () => {
  if (isLoggedIn) {
    // Redirect to the appropriate dashboard based on the role
    if (role === 'User') {
      navigate('/userDashboard');
    }  else if (role === 'reserve') {
      navigate('/ReserveUserDashboard');
    }else if (role === 'Admin') {
      navigate('/RegularAdmin');
    } else if (role === 'TransportOnly') {
      navigate('/TransportuserDashboard');
    } else if (role === 'Super Admin') {
      navigate('/NewAdminDashboard');
    } else if (role === 'Transport') {
      navigate('/transport-admin');
    } else if (role === 'Media') {
      navigate('/event-details');
    } else {
      console.log("Unknown role");
    }
  } else {
    // If not logged in, navigate to login page
    navigate('/login');
  }
};






useEffect(() => {
  fetchFilteredBuildings(); // Fetch data whenever a filter changes
}, [selectedSuburb, selectedUniversity, budget, roomType]);

    // Helper function to filter out duplicates
    const getUniqueValues = (arr, key) => {
        return [...new Map(arr.map(item => [item[key], item])).values()];
    };
 // Sort Apartments by Price
 const handleSort = (order) => {
  const sortedApartments = [...buildings].sort((a, b) => {
      if (order === 'price_asc') {
          return a.Best_Price - b.Best_Price;
      } else {
          return b.Best_Price - a.Best_Price;
      }
  });
  setBuildings(sortedApartments);
  
  setSortOrder(order); // Update the sort order state
};

const [isPopupVisible, setIsPopupVisible] = useState(false);

  const toggleFilterPopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

// Clear All Filters
const handleClearAll = () => {
  setSelectedSuburb(null);
  setSelectedUniversity(null);
  setBudget('');
  setRoomType('');
  setBuildingGender('');
  fetchApartments(); // Reset to all apartments
};
    const fetchBuildingBuilding = () => {
        fetch(`https://backendjaes.passiontrials.co.za/buildings`) // Assuming you have an endpoint for building info
            .then((response) => response.json())
            .then((data) => {
                setBuildings(data); // Store building data in state
            })
            .catch((error) => console.error('Failed to load building info:', error));
    };

    useEffect(() => {
        fetchBuildingInfo();
        fetchApartments();
        fetchbuilding();
        fetchBuildingBuilding();
        window.scrollTo(0, 190);
    }, [buildingId, gender, apartmentType, maxPrice]);

    const fetchBuildingInfo = () => {
        fetch(`https://backendjaes.passiontrials.co.za/building_image/${buildingId}`)
            .then((response) => response.json())
            .then((data) => {
                setBuilding(data);
            })
            .catch((error) => console.error('Failed to load building info:', error));
    };

    const handleTypeChange = (e) => {
      const selectedType = e.target.value;
      setType(selectedType);
      setRoomType(selectedType && description ? `${selectedType} ${description}` : '');
    };
  
    const handleDescriptionChange = (e) => {
      const selectedDescription = e.target.value;
      setDescription(selectedDescription);
      setRoomType(type && selectedDescription ? `${type} ${selectedDescription}` : '');
    };
 // Fetch filtered buildings based on selected filters
 const fetchFilteredBuildings = () => {
  let url = `https://backendjaes.passiontrials.co.za/buildingsfilter?`;
  
  if (selectedSuburb) url += `suburb=${selectedSuburb}&`;
  if (selectedUniversity) url += `university=${selectedUniversity}&`;
  if (budget) url += `budget=${budget}&`;
  if (roomType) url += `roomType=${roomType}&`;
  if (buildingGender) url += `buildingGender=${buildingGender}`;

  url = url.endsWith('&') ? url.slice(0, -1) : url;

  fetch(url)
    .then(response => response.json())
    .then(data => {
      if (data.message) {
        // handle "no buildings found" scenario
      } else {
        setBuildings(data);
      }
    })
    .catch(error => console.error('Failed to load buildings:', error));
};

const fetchFilteredApartments = () => {
  let url = `https://backendjaes.passiontrials.co.za/apartments?`;

  if (selectedSuburb) url += `suburb=${selectedSuburb}&`;
  if (selectedUniversity) url += `university=${selectedUniversity}&`;
  if (budget) url += `budget=${budget}&`;
  if (roomType) url += `roomType=${roomType}`;

  fetch(url)
    .then(response => response.json())
    .then(data => {
      setApartments(data);
    })
    .catch(error => console.error('Failed to load apartments:', error));
};

useEffect(() => {
  fetchFilteredBuildings();
  fetchFilteredApartments();
}, [selectedSuburb, selectedUniversity, budget, roomType,buildingGender]);

  useEffect(() => {
    fetchFilteredApartments();
}, [selectedSuburb, selectedUniversity, budget, roomType]);
    const fetchApartments = () => {
        let url = `https://backendjaes.passiontrials.co.za/apartment_tb`;
    
        fetch(url)
            .then(response => response.json())
            .then(data => {
                setApartments(data);
            })
            .catch(error => console.error('Failed to load apartments:', error));
    };

    const fetchbuilding = () => {
      let url = `https://backendjaes.passiontrials.co.za/buildings_tb`;
  
      fetch(url)
          .then(response => response.json())
          .then(data => {
              setBuildings(data);
          })
          .catch(error => console.error('Failed to load apartments:', error));
  };

    // Handle suburb click
    const handleSuburbClick = (suburb) => {
        setSelectedSuburb(suburb);
        setSelectedUniversity(null); // Reset university selection
        fetchFilteredBuildings(); // Fetch buildings based on suburb
    };

    // Handle university click
    const handleUniversityClick = (university) => {
        setSelectedUniversity(university);
        setSelectedSuburb(null); // Reset suburb selection
        fetchFilteredBuildings(); // Fetch buildings based on university
    };

    const handleNextPage = (id) => {
        navigate(`/ApartmentDetails/${id}`);
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
        }
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
        }
    };

    const handleFocus = () => {
        setShowDropdown(true);
    };

    const handleBlur = () => {
        setTimeout(() => {
            setShowDropdown(false);
        }, 200);
    };

    // Get unique cities and universities from the buildings data
    const uniqueCities = getUniqueValues(buildings, 'Suburb');
    const uniqueUniversities = getUniqueValues(buildings, 'University'); // Assuming you have a University field

    return (
        <div style={{ backgroundColor: 'white' }}>
           <div id="section-featured" class="HeroSectionStyles-module__sectionFeaturedContainer" style={{height: "450px"}}>
           <div class="HeaderDesktop-module__container HeaderDesktop-module__transparentBg" id="header">
            <div class="HeaderDesktop-module__warpper">
              <div class="HeaderDesktop-module__leftSection"><a aria-label="home" href="/"><img
                    height="44" width="100"
                    src={logo}
                    alt="amber-logo-dark" data-testid="Amber-logo"
                    class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/></a></div>
              <div class="HeaderDesktop-module__rightSection">
                {/* <div data-testid="Download App" class="HeaderDesktop-module__tabItem HeaderDesktop-module__tabItemTbg"
                  role="button" aria-label="Download App" tabindex="0"><img height="20" width="20"
                    src="./Discover the Best Student Accommodation and Housing _ Amber_files/App_Download_White_Nav_bar_2.gif"
                    data-testid="Download-App-image" class="Image-module__animateOpacity Image-module__show"
                    aria-hidden="true"/>Download App</div> */}
                {/* <div class="MenuDropdown-module__container" data-testid="topnavbar-support-button">
                  <div role="button" tabindex="0">
                    <div data-testid="[object Object]"
                      class="HeaderDesktop-module__tabItem HeaderDesktop-module__tabItemTbg" role="button"
                      aria-label="[object Object]" tabindex="0"><svg width="18" height="19" viewBox="0 0 18 18"
                        fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_50830)">
                          <path
                            d="M1.125 9C1.125 4.65117 4.65117 1.125 9 1.125C13.3488 1.125 16.875 4.65117 16.875 9V14.066C16.875 14.9977 16.1191 15.7535 15.1875 15.7535L12.2801 15.75C12.048 15.0961 11.4223 14.625 10.6875 14.625H8.4375C7.50586 14.625 6.75 15.3809 6.75 16.3125C6.75 17.2441 7.50586 18 8.4375 18H10.6875C11.4223 18 12.048 17.5289 12.2801 16.875L15.1875 16.8785C16.7414 16.8785 18.0035 15.6199 18.0035 14.066V9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9V10.6875C0 10.9969 0.253125 11.25 0.5625 11.25C0.871875 11.25 1.125 10.9969 1.125 10.6875V9ZM11.25 16.3125C11.25 16.6219 10.9969 16.875 10.6875 16.875H8.4375C8.12813 16.875 7.875 16.6219 7.875 16.3125C7.875 16.0031 8.12813 15.75 8.4375 15.75H10.6875C10.9969 15.75 11.25 16.0031 11.25 16.3125ZM5.0625 7.875H5.625V12.375H5.0625C4.13086 12.375 3.375 11.6191 3.375 10.6875V9.5625C3.375 8.63086 4.13086 7.875 5.0625 7.875ZM2.25 9.5625V10.6875C2.25 12.2414 3.50859 13.5 5.0625 13.5H5.625C6.24727 13.5 6.75 12.9973 6.75 12.375V7.875C6.75 7.25273 6.24727 6.75 5.625 6.75H5.0625C3.50859 6.75 2.25 8.00859 2.25 9.5625ZM12.375 7.875H12.9375C13.8691 7.875 14.625 8.63086 14.625 9.5625V10.6875C14.625 11.6191 13.8691 12.375 12.9375 12.375H12.375V7.875ZM12.9375 6.75H12.375C11.7527 6.75 11.25 7.25273 11.25 7.875V12.375C11.25 12.9973 11.7527 13.5 12.375 13.5H12.9375C14.4914 13.5 15.75 12.2414 15.75 10.6875V9.5625C15.75 8.00859 14.4914 6.75 12.9375 6.75Z"
                            fill="#fff"></path>
                        </g>
                        <defs>
                          <clippath id="clip0_1_50830">
                            <rect width="18" height="18" fill="white"></rect>
                          </clippath>
                        </defs>
                      </svg>Support <svg viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg" width="18"
                        height="18">
                        <path
                          d="M3.13523 6.15803C3.3241 5.95657 3.64052 5.94637 3.84197 6.13523L7.5 9.56464L11.158 6.13523C11.3595 5.94637 11.6759 5.95657 11.8648 6.15803C12.0536 6.35949 12.0434 6.67591 11.842 6.86477L7.84197 10.6148C7.64964 10.7951 7.35036 10.7951 7.15803 10.6148L3.15803 6.86477C2.95657 6.67591 2.94637 6.35949 3.13523 6.15803Z"
                          fill="currentColor" fill-rule="evenodd" clip-rule="evenodd"></path>
                      </svg></div>
                  </div>
                </div> */}
                {/* <div data-testid="Shortlist" class="HeaderDesktop-module__tabItem HeaderDesktop-module__tabItemTbg"
                  role="button" aria-label="Shortlist" tabindex="0"><svg width="16" viewBox="0 0 18 16" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M8.71875 15.875L7.70921 14.9907C6.17961 13.6643 4.91768 12.5221 3.92344 11.5641C2.92919 10.6062 2.14145 9.7549 1.5602 9.01034C0.978947 8.26637 0.573602 7.58842 0.344161 6.9765C0.11472 6.36516 0 5.74263 0 5.1089C0 3.85617 0.439914 2.80594 1.31974 1.95821C2.19896 1.11107 3.28865 0.6875 4.58882 0.6875C5.38421 0.6875 6.14137 0.867893 6.86028 1.22868C7.57919 1.59005 8.19868 2.10972 8.71875 2.78766C9.23882 2.10972 9.85831 1.59005 10.5772 1.22868C11.2961 0.867893 12.0533 0.6875 12.8487 0.6875C14.1488 0.6875 15.2385 1.11107 16.1178 1.95821C16.9976 2.80594 17.4375 3.85617 17.4375 5.1089C17.4375 5.74263 17.3228 6.36516 17.0933 6.9765C16.8639 7.58842 16.4586 8.26637 15.8773 9.01034C15.2961 9.7549 14.5083 10.6062 13.5141 11.5641C12.5198 12.5221 11.2579 13.6643 9.72829 14.9907L8.71875 15.875ZM8.71875 14.0843C10.1872 12.8169 11.3956 11.7263 12.3439 10.8125C13.2923 9.89874 14.0418 9.10643 14.5924 8.43556C15.1431 7.76527 15.5255 7.17222 15.7396 6.65639C15.9538 6.14056 16.0609 5.62473 16.0609 5.1089C16.0609 4.22462 15.7549 3.48772 15.1431 2.8982C14.5312 2.30868 13.7664 2.01392 12.8487 2.01392C12.1298 2.01392 11.4604 2.21288 10.8406 2.61081C10.2214 3.00873 9.73594 3.56141 9.38413 4.26883H8.05337C7.68627 3.54667 7.19679 2.99016 6.58495 2.59931C5.97311 2.20905 5.30773 2.01392 4.58882 2.01392C3.67105 2.01392 2.90625 2.30868 2.29441 2.8982C1.68257 3.48772 1.37664 4.22462 1.37664 5.1089C1.37664 5.62473 1.48372 6.14056 1.69786 6.65639C1.91201 7.17222 2.29441 7.76527 2.84507 8.43556C3.39572 9.10643 4.14523 9.89491 5.09359 10.801C6.04194 11.7077 7.25033 12.8021 8.71875 14.0843Z"
                      fill="#fff"></path>
                  </svg>Shortlist</div> */}
                <div class="HeaderDesktop-module__outlineTabsWrap">
                 <div  onClick={userDashboard}
                    class="HeaderDesktop-module__tabItem HeaderDesktop-module__outline HeaderDesktop-module__tabItemTbg"
                    role="button" data-testid="topnavbar-Login" tabindex="0"><svg xmlns="http://www.w3.org/2000/svg"
                      height="18" width="18" fill="#fff" viewBox="0 0 512 512">
                      <path
                        d="M352 256c0-4.094-1.562-8.188-4.688-11.31l-144-144c-6.25-6.25-16.38-6.25-22.62 0s-6.25 16.38 0 22.62L297.4 240H16C7.156 240 0 247.2 0 256s7.156 16 16 16h281.4l-116.7 116.7c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0l144-144C350.4 264.2 352 260.1 352 256zM432 32h-96C327.2 32 320 39.16 320 48S327.2 64 336 64h96C458.5 64 480 85.53 480 112v288c0 26.47-21.53 48-48 48h-96c-8.844 0-16 7.156-16 16s7.156 16 16 16h96c44.13 0 80-35.88 80-80v-288C512 67.88 476.1 32 432 32z">
                      </path>
                    </svg>Login</div>
                  {/* <div class="MenuDropdown-module__container">
                    <div role="button" tabindex="0">
                      <div class="HeaderDesktop-module__tabItem HeaderDesktop-module__outline"
                        data-testid="topnavbar-hamburger-menu" role="button" tabindex="0"><i
                          class="HeaderDesktop-module__user"><svg width="16" height="16" viewBox="0 0 22 22" fill="none"
                            xmlns="http://www.w3.org/2000/svg">
                            <g id="user">
                              <path id="Vector"
                                d="M11 11C12.4587 11 13.8576 10.4205 14.8891 9.38909C15.9205 8.35764 16.5 6.95869 16.5 5.5C16.5 4.04131 15.9205 2.64236 14.8891 1.61091C13.8576 0.579463 12.4587 0 11 0C9.54131 0 8.14236 0.579463 7.11091 1.61091C6.07946 2.64236 5.5 4.04131 5.5 5.5C5.5 6.95869 6.07946 8.35764 7.11091 9.38909C8.14236 10.4205 9.54131 11 11 11ZM9.03633 13.0625C4.80391 13.0625 1.375 16.4914 1.375 20.7238C1.375 21.4285 1.94648 22 2.65117 22H19.3488C20.0535 22 20.625 21.4285 20.625 20.7238C20.625 16.4914 17.1961 13.0625 12.9637 13.0625H9.03633Z"
                                fill="#6B7280"></path>
                            </g>
                          </svg></i><svg width="16" height="14" viewBox="0 0 18 14" fill="none"
                          xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M0.25 0.75C0.25 0.40625 0.53125 0.125 0.875 0.125H17.125C17.4688 0.125 17.75 0.40625 17.75 0.75C17.75 1.09375 17.4688 1.375 17.125 1.375H0.875C0.53125 1.375 0.25 1.09375 0.25 0.75ZM0.25 7C0.25 6.65625 0.53125 6.375 0.875 6.375H17.125C17.4688 6.375 17.75 6.65625 17.75 7C17.75 7.34375 17.4688 7.625 17.125 7.625H0.875C0.53125 7.625 0.25 7.34375 0.25 7ZM17.75 13.25C17.75 13.5938 17.4688 13.875 17.125 13.875H0.875C0.53125 13.875 0.25 13.5938 0.25 13.25C0.25 12.9062 0.53125 12.625 0.875 12.625H17.125C17.4688 12.625 17.75 12.9062 17.75 13.25Z"
                            fill="#fff"></path>
                        </svg></div>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <img height="100%" width="100%"
          
            // src={homeimage}
            src="https://images.unsplash.com/photo-1622295023876-0cdf583c41f6?q=80&w=1469&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            data-testid="hero-image"
            class="Image-module__animateOpacity HeroSectionStyles-module__heroImg Image-module__show"
            aria-hidden="true"/>
          <div class="HeroSectionStyles-module__overlayBg"></div>
          <div class="HeroSectionStyles-module__content">
            <div class="HeroSectionStyles-module__contentInner">
              <div class="HeroSectionStyles-module__heroTitleSection">
               
            <h2 className="HeroSectionStyles-module__heroTitle" data-testid="hero-image-main-text">
            Home away from home. 
            </h2>
            <h1 className="HeroSectionStyles-module__heroSubTitle" data-testid="hero-image-sub-text">
            Book student accommodations near top universities and cities across the country
            </h1>
                     {/* Inline CSS */}
            <style jsx="true">{`
                .search-input-wrapper {
                    position: relative;
                    width: 100%;
                }

                .search-input {
                    width: 100%;
                    padding: 10px;
                    font-size: 16px;
                   
                    border-radius: 4px;
                }

                .search-button {
                    position: absolute;
                    right: 0px;
                    top: 50%;
                    transform: translateY(-50%);
                    background-color: #ff5a5f;
                    border: none;
                    cursor: pointer;
                    font-size: 18px;
                }

                @media (max-width: 768px) {
                    .HeroSectionStyles-module__heroTitle {
                        font-size: 1.5rem; /* Reduce font size for <h2> on mobile */
                    }

                    .HeroSectionStyles-module__heroSubTitle {
                        display: none; /* Hide the <h1> on mobile */
                    }
                }
            `}</style>
                <div class="SmallFeatures-module__smallFeaturesContainer">
                  <div class="SmallFeatures-module__smallFeature" data-testid="heroImage-smallFeatures"><span
                      class="SmallFeatures-module__icon"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20"
                        viewBox="0 0 14 14" fill="none">
                        <g clip-path="url(#clip0_491_80888)">
                          <path
                            d="M6.43398 9.05898C6.26445 9.23125 5.98555 9.23125 5.81602 9.05898L4.06602 7.30898C3.89375 7.13945 3.89375 6.86055 4.06602 6.69102C4.23555 6.51875 4.51445 6.51875 4.68398 6.69102L6.125 8.13203L9.31602 4.94102C9.48555 4.76875 9.76445 4.76875 9.93398 4.94102C10.1062 5.11055 10.1062 5.38945 9.93398 5.55898L6.43398 9.05898ZM14 7C14 10.8664 10.8664 14 7 14C3.13359 14 0 10.8664 0 7C0 3.13359 3.13359 0 7 0C10.8664 0 14 3.13359 14 7ZM7 0.875C3.61758 0.875 0.875 3.61758 0.875 7C0.875 10.3824 3.61758 13.125 7 13.125C10.3824 13.125 13.125 10.3824 13.125 7C13.125 3.61758 10.3824 0.875 7 0.875Z"
                            fill="#fff"></path>
                        </g>
                        <defs>
                          <clippath id="clip0_491_80888">
                            <rect width="14" height="14" fill="white"></rect>
                          </clippath>
                        </defs>
                      </svg></span><span data-testid="Verified Properties">Verified Properties</span></div>
                  <div class="SmallFeatures-module__smallFeature" data-testid="heroImage-smallFeatures"><span
                      class="SmallFeatures-module__icon"><svg width="20" height="20" viewBox="0 0 18 18" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_1_50830)">
                          <path
                            d="M1.125 9C1.125 4.65117 4.65117 1.125 9 1.125C13.3488 1.125 16.875 4.65117 16.875 9V14.066C16.875 14.9977 16.1191 15.7535 15.1875 15.7535L12.2801 15.75C12.048 15.0961 11.4223 14.625 10.6875 14.625H8.4375C7.50586 14.625 6.75 15.3809 6.75 16.3125C6.75 17.2441 7.50586 18 8.4375 18H10.6875C11.4223 18 12.048 17.5289 12.2801 16.875L15.1875 16.8785C16.7414 16.8785 18.0035 15.6199 18.0035 14.066V9C18 4.02891 13.9711 0 9 0C4.02891 0 0 4.02891 0 9V10.6875C0 10.9969 0.253125 11.25 0.5625 11.25C0.871875 11.25 1.125 10.9969 1.125 10.6875V9ZM11.25 16.3125C11.25 16.6219 10.9969 16.875 10.6875 16.875H8.4375C8.12813 16.875 7.875 16.6219 7.875 16.3125C7.875 16.0031 8.12813 15.75 8.4375 15.75H10.6875C10.9969 15.75 11.25 16.0031 11.25 16.3125ZM5.0625 7.875H5.625V12.375H5.0625C4.13086 12.375 3.375 11.6191 3.375 10.6875V9.5625C3.375 8.63086 4.13086 7.875 5.0625 7.875ZM2.25 9.5625V10.6875C2.25 12.2414 3.50859 13.5 5.0625 13.5H5.625C6.24727 13.5 6.75 12.9973 6.75 12.375V7.875C6.75 7.25273 6.24727 6.75 5.625 6.75H5.0625C3.50859 6.75 2.25 8.00859 2.25 9.5625ZM12.375 7.875H12.9375C13.8691 7.875 14.625 8.63086 14.625 9.5625V10.6875C14.625 11.6191 13.8691 12.375 12.9375 12.375H12.375V7.875ZM12.9375 6.75H12.375C11.7527 6.75 11.25 7.25273 11.25 7.875V12.375C11.25 12.9973 11.7527 13.5 12.375 13.5H12.9375C14.4914 13.5 15.75 12.2414 15.75 10.6875V9.5625C15.75 8.00859 14.4914 6.75 12.9375 6.75Z"
                            fill="#fff"></path>
                        </g>
                        <defs>
                          <clippath id="clip0_1_50830">
                            <rect width="18" height="18" fill="white"></rect>
                          </clippath>
                        </defs>
                      </svg></span><span data-testid="24x7 Assistance">24x7 Assistance</span></div>
                  <div class="SmallFeatures-module__smallFeature" data-testid="heroImage-smallFeatures"><span
                      class="SmallFeatures-module__icon"><svg width="20" height="20" viewBox="0 0 19 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g clip-path="url(#clip0_3908_1183)">
                          <path
                            d="M15.5 3.0947C15.5 4.18971 16.3969 5.07999 17.5 5.07999V3.0947H15.5ZM14.5 3.0947H4.5C4.5 4.73877 3.15625 6.07264 1.5 6.07264V10.0432C3.15625 10.0432 4.5 11.3771 4.5 13.0212H14.5C14.5 11.3771 15.8438 10.0432 17.5 10.0432V6.07264C15.8438 6.07264 14.5 4.73877 14.5 3.0947ZM1.5 13.0212H3.5C3.5 11.9262 2.60313 11.0359 1.5 11.0359V13.0212ZM17.5 11.0359C16.3969 11.0359 15.5 11.9262 15.5 13.0212H17.5V11.0359ZM1.5 3.0947V5.07999C2.60313 5.07999 3.5 4.18971 3.5 3.0947H1.5ZM0.5 2.10205H1.5H17.5H18.5V3.0947V13.0212V14.0138H17.5H1.5H0.5V13.0212V3.0947V2.10205ZM11.5 8.05793C11.5 7.5314 11.2893 7.02643 10.9142 6.65412C10.5391 6.2818 10.0304 6.07264 9.5 6.07264C8.96957 6.07264 8.46086 6.2818 8.08579 6.65412C7.71071 7.02643 7.5 7.5314 7.5 8.05793C7.5 8.58447 7.71071 9.08943 8.08579 9.46175C8.46086 9.83406 8.96957 10.0432 9.5 10.0432C10.0304 10.0432 10.5391 9.83406 10.9142 9.46175C11.2893 9.08943 11.5 8.58447 11.5 8.05793ZM6.5 8.05793C6.5 7.26813 6.81607 6.51068 7.37868 5.95221C7.94129 5.39374 8.70435 5.07999 9.5 5.07999C10.2956 5.07999 11.0587 5.39374 11.6213 5.95221C12.1839 6.51068 12.5 7.26813 12.5 8.05793C12.5 8.84773 12.1839 9.60518 11.6213 10.1637C11.0587 10.7221 10.2956 11.0359 9.5 11.0359C8.70435 11.0359 7.94129 10.7221 7.37868 10.1637C6.81607 9.60518 6.5 8.84773 6.5 8.05793Z"
                            fill="#fff"></path>
                        </g>
                        <defs>
                          <clippath id="clip0_3908_1183">
                            <rect width="18" height="15.8824" fill="white" transform="translate(0.5 0.117676)"></rect>
                          </clippath>
                        </defs>
                      </svg></span><span data-testid="Lowest Price Guarantee">Lowest Price Guarantee</span></div>
                </div>
              </div>
              <div class="HeroSectionStyles-module__heroSearchSection">
              <div className="search-container">
    <div className="search-input-wrapper">
        <input
            type="text"
            readOnly               
            placeholder="Search by Suburb, University or Property"
            className="search-input"
            onFocus={handleFocus}
            onBlur={handleBlur}
        />
        <button className="search-button">
            <span role="img" aria-label="search">
                🔍
            </span>
        </button>
    </div>

    {showDropdown && (
        <div className="search-dropdown">
            <div className="top-cities">
                <h4>TOP SUBURBS ⚡</h4>
                <div className="city-list">
                    {uniqueCities.length > 0 ? (
                        uniqueCities.map((city, index) => (
                            <p 
                                key={index} 
                                onClick={() => handleSuburbClick(city.Suburb)} // Fetch filtered apartments based on suburb
                                style={{ cursor: 'pointer', color: selectedSuburb === city.Suburb ? 'blue' : 'black' }}
                            >
                                {city.Suburb}
                            </p> // Display unique suburb names and highlight if selected
                        ))
                    ) : (
                        <p>Loading cities...</p>
                    )}
                </div>
            </div>

            <div className="top-universities">
                <h4>TOP UNIVERSITIES ⚡</h4>
                <div className="university-list">
                    {uniqueUniversities.length > 0 ? (
                        uniqueUniversities.map((university, index) => (
                            <p 
                                key={index} 
                                onClick={() => handleUniversityClick(university.University)} // Fetch filtered apartments based on university
                                style={{ cursor: 'pointer', color: selectedUniversity === university.University ? 'blue' : 'black' }}
                            >
                                {university.University}
                            </p> // Display unique university names and highlight if selected
                        ))
                    ) : (
                        <p>Loading universities...</p>
                    )}
                </div>
            </div>
        </div>
    )}
</div>

              </div>
              <div class="">
                <div class="RecentlySearched-module__recentlyVisitedContainerInner"></div>
              </div>
            </div>
          </div>
        </div>
        <style>{`
        .filterSection-module__containerDesktop {
  overflow-x: auto;
  white-space: nowrap;
}

.filterSection-module__filterListDesktop {
  display: flex;
  flex-wrap: nowrap;
}

.badge-module__container {
  margin-right: 8px; /* Adjust margin as needed */
}

.filterSection-module__containerDesktop::-webkit-scrollbar {
  display: none; /* Hide scrollbar for better aesthetics */
}

.filterSection-module__containerDesktop {
  -ms-overflow-style: none;  /* Hide scrollbar in IE and Edge */
  scrollbar-width: none;  /* Hide scrollbar in Firefox */
}
`}</style>

    {/* Main Filter Button (always visible on mobile) */}
    <button type="button" className="mainFilterButton" onClick={toggleFilterPopup}>
      <span>Filter</span>
    </button>

    {/* Filter Popup (only visible on mobile and controlled by state) */}
    {isPopupVisible && (
      <div id="filterPopup" className="filterPopup">
        <div className="filterContent">
          <div className="filterOptions">
            {/* Sort Option */}
            <button
              onClick={() => handleSort(sortOrder === 'price_desc' ? 'price_asc' : 'price_desc')}
              type="button"
              className="badge-module__container FiltersBadgeGroupDesktop-module__badge"
            >
              Sort Price {sortOrder === 'price_desc' ? 'Low to High' : 'High to Low'}
            </button>

            {/* University Filter */}
            {selectedUniversity && (
              <div className="MenuDropdown-module__container">
                <button type="button" className="badge-module__container badge-module__isActive">
                  {selectedUniversity}
                </button>
              </div>
            )}

                {/* Select type filter */}
                  <select
        id="type"
        value={type}
        onChange={handleTypeChange}
        className="badge-module__container FiltersBadgeGroupDesktop-module__badge"
      >
        <option value="">Select Type</option>
        <option value="Single">Single</option>
        <option value="Sharing Double">Sharing Double</option>
        <option value="Sharing Twin">Sharing Twin</option>
        <option value="Bachelor Pad">Bachelor Pad</option>
      </select>
      <p style={{fontSize:'20px'}}><strong>+</strong> </p>
   {/* Descrption  filter */}
      <select
        id="description"
        value={description}
        onChange={handleDescriptionChange}
        className="badge-module__container FiltersBadgeGroupDesktop-module__badge"
      >
        <option value="">Select Description</option>
        <option value="- Standard">Standard</option>
        <option value="- Ensuite">Ensuite</option>
        <option value="- Kitchenette">Kitchenette</option>
        <option value="- Flatlet">Flatlet</option>
        <option value="- Deluxe">Deluxe</option>
      </select>
            {/* Suburb Filter */}
            {selectedSuburb && (
              <div className="MenuDropdown-module__container">
                <button type="button" className="badge-module__container badge-module__isActive">
                  {selectedSuburb}
                </button>
              </div>
            )}


            {/* Budget Filter */}
            <div className="MenuDropdown-module__container">
              <input
                type="number"
                placeholder="Max Budget"
                value={budget}
                onChange={(e) => setBudget(e.target.value)}
                className="badge-module__container FiltersBadgeGroupDesktop-module__badge"
              />
            </div>


     {/* Gender filter */}
            <div role="button" tabindex="0" data-testid="Room Type">
              <select class="badge-module__container FiltersBadgeGroupDesktop-module__badge" value={buildingGender} onChange={(e) => setBuildingGender(e.target.value)}>
                <option value="">pick gender</option>

                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Male & Female">Male & Female</option>


              </select>
            </div>
   
            
          </div>
{/* Close Button for Mobile Popup */}
<button type="button" className="closeFilterPopup" onClick={toggleFilterPopup}>
            Apply
          </button>
          {/* Clear All Button */}
          <button onClick={handleClearAll} type="button" className="clearAllButton">
            Clear All
          </button>

          
        </div>
      </div>
    )}


        <div class="filterSection-module__container filter filterSection-module__filterListDesktop">
          <div class="filterSection-module__filterList filterSection-module__filterListDesktop ">
            <div class="MenuDropdown-module__container" data-testid="search-filters">
              <div role="button" tabindex="0" data-testid="Sort">
                <button                                 onClick={() => handleSort(sortOrder === 'price_desc' ? 'price_asc' : 'price_desc')}
 type="button" class="badge-module__container FiltersBadgeGroupDesktop-module__badge"><svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0px"}}>
                    <path d="M9.41406 9.88235L10.1904 9.10604L12.1592 11.0748V0H13.2572V11.0748L15.226 9.10604L16.0023 9.88235L12.7082 13.1765L9.41406 9.88235Z" fill="#374151"></path>
                    <path d="M0 0.941162H8.47059V1.88234H0V0.941162Z" fill="#374151"></path>
                    <path d="M1.88281 4.70605H8.47105V5.64723H1.88281V4.70605Z" fill="#374151"></path>
                    <path d="M4.70312 7.5293H8.46783V8.47047H4.70312V7.5293Z" fill="#374151"></path>
                  </svg><span data-testid="Property-Room-Type-filter-Sort">Sort Price {sortOrder === 'price_desc' ? 'Low to High' : 'High to Low'}
                  </span>
                  </button></div>
            </div>
            {selectedUniversity &&  <div class="MenuDropdown-module__container" data-testid="search-filters">
              <div role="button" tabindex="0" data-testid="University"><button type="button" class="badge-module__container badge-module__isActive FiltersBadgeGroupDesktop-module__badge">
                <span data-testid="Property-Room-Type-filter-University">
                  {selectedUniversity} </span>
                </button>
                </div>
            </div>}
            
            {selectedSuburb &&  <div class="MenuDropdown-module__container" data-testid="search-filters">
              <div role="button" tabindex="0" data-testid="University"><button type="button" class="badge-module__container badge-module__isActive FiltersBadgeGroupDesktop-module__badge">
                <span data-testid="Property-Room-Type-filter-University">
                  {selectedSuburb} </span>
                 
                </button>
                </div>
            </div>}
            
            <div class="MenuDropdown-module__container" data-testid="search-filters">
              <div role="button" tabindex="0" data-testid="Budget">
                
              <input 
               class="badge-module__container FiltersBadgeGroupDesktop-module__badge"
                    type="number" 
                    placeholder="Max Budget" 
                    value={budget} 
                    onChange={(e) => setBudget(e.target.value)} 
                />
              </div>
            </div>
            <div className="MenuDropdown-module__container" data-testid="search-filters">
      <select
        id="type"
        value={type}
        onChange={handleTypeChange}
        className="badge-module__container FiltersBadgeGroupDesktop-module__badge"
      >
        <option value="">Select Type</option>
        <option value="Single">Single</option>
        <option value="Sharing Double">Sharing Double</option>
        <option value="Sharing Twin">Sharing Twin</option>
        <option value="Bachelor Pad">Bachelor Pad</option>
      </select>

    </div>
    <p style={{fontSize:'20px'}}><strong>+</strong> </p>
    <div className="MenuDropdown-module__container" data-testid="search-filters">
    

      <select
        id="description"
        value={description}
        onChange={handleDescriptionChange}
        className="badge-module__container FiltersBadgeGroupDesktop-module__badge"
      >
        <option value="">Select Description</option>
        <option value="- Standard">Standard</option>
        <option value="- Ensuite">Ensuite</option>
        <option value="- Kitchenette">Kitchenette</option>
        <option value="- Flatlet">Flatlet</option>
        <option value="- Deluxe">Deluxe</option>
      </select>
      
      {/* <p>Selected Room Type: {roomType}</p> */}
    </div>
    <div class="MenuDropdown-module__container" data-testid="search-filters">
            <div role="button" tabindex="0" data-testid="Room Type">
              <select class="badge-module__container FiltersBadgeGroupDesktop-module__badge" value={buildingGender} onChange={(e) => setBuildingGender(e.target.value)}>
                <option value="">pick gender</option>

                <option value="Female">Female</option>
                <option value="Male">Male</option>
                <option value="Male & Female">Male & Female</option>


              </select>
            </div>
          </div>
            {/* <div class="MenuDropdown-module__container" data-testid="search-filters">
              <div role="button" tabindex="0" data-testid="Room Type">
              <select  class="badge-module__container FiltersBadgeGroupDesktop-module__badge" value={roomType} onChange={(e) => setRoomType(e.target.value)}>
                    <option value="">Select Room Type</option>
                    <option value="Single">Single Room</option>
                    <option value="Double">Sharing Double</option>
                    <option value="Triple">Sharing Triple</option>
                    <option value="Quad">Sharing Quad</option>
                    <option value="Bachelor">Bachelor Pod</option>
                </select>
              </div>
            </div> */}
            
            {/* <button type="button" class="badge-module__container" data-testid="filters-button"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" style={{margin: "0px"}}>
                <g clip-path="url(#clip0_10275_207589)">
                  <path d="M5.24855 4H4.21467L4.8566 4.81045L8.5991 9.53545L8.99026 10.0293L9.38239 9.53621L13.1399 4.81121L13.785 4H12.7485H5.24855ZM7.49855 9.75L7.89332 9.44316L7.89323 9.44304L7.89284 9.44254L7.89132 9.44058L7.88528 9.43281L7.86156 9.40228L7.77017 9.28469L7.43253 8.85029L6.30511 7.40024C5.4167 6.25786 4.33887 4.87261 3.58118 3.90111C3.45432 3.7359 3.57027 3.5 3.77855 3.5H14.211C14.4193 3.5 14.5353 3.73587 14.4084 3.90108C13.6506 4.87279 12.5745 6.25824 11.6879 7.40064C11.2444 7.97212 10.8483 8.48287 10.563 8.85076L10.2262 9.28519L10.1351 9.40279L10.1114 9.43332L10.1054 9.44109L10.1039 9.44306L10.1035 9.44355L10.1034 9.44367L10.4985 9.75L10.1033 9.44371L9.99855 9.57893V9.75V14.25C9.99855 14.3864 9.8849 14.5 9.74855 14.5H8.24855C8.11219 14.5 7.99855 14.3864 7.99855 14.25V9.75V9.57857L7.89336 9.4432L7.49855 9.75Z" fill="#374151" stroke="#374151"></path>
                </g>
                <defs>
                  <clipPath id="clip0_10275_207589">
                    <rect width="18" height="18" fill="white"></rect>
                  </clipPath>
                </defs>
              </svg>
              <span data-testid="Property-Room-Type-filter-Filter">Filter </span></button> */}
              
              
              <button onClick={handleClearAll} type="button" class="Button-module__btn Button-module__subtle filterSection-module__clearAllBtn" data-testid="filter-clearAll-button" role="button" aria-selected="false">
              <div role="button" class="Ripple-module__container" tabindex="0" data-testid="tab"></div><span class="Button-module__btnInnerContent">Clear All</span>
            </button>
          </div>
        </div>
        <div class="SearchHeadingContent-module__container SearchHeadingContent-module__containerDesktop">
        {selectedUniversity && <div class="SearchHeadingContent-module__headingWrap">
  <h1>Student Accommodations near&nbsp;<strong data-testid="search-page-region-heading">
  {selectedUniversity}</strong></h1><span>&nbsp; | &nbsp; Showing&nbsp;<strong data-testid="search-page-heading-numberOfProperties">{buildings.length}</strong>&nbsp;places</span>
</div>}
{selectedSuburb && <div class="SearchHeadingContent-module__headingWrap">
  <h1>Student Accommodations near&nbsp;<strong data-testid="search-page-region-heading">
  {selectedSuburb}</strong></h1><span>&nbsp; | &nbsp; Showing&nbsp;<strong data-testid="search-page-heading-numberOfProperties">{buildings.length}</strong>&nbsp;places</span>
</div>}

              </div>
            {/* Display the selected suburb or university
            {selectedSuburb && <h3>Rooms in: {selectedSuburb} Suburb</h3>}
            {selectedUniversity && <h3>Rooms in: {selectedUniversity} University</h3>} */}
            <div className="container">
    <div className="apartments-list">
    <div className="dsasdssa" id="dsasdssa" ref={sliderRef}>
                {buildings.map((build) => (
                    build.BuildingId !== 49 && (
                    <div className="dsasdssa-item" key={build.BuildingId}> <Link to={`/Apartments/${build.BuildingId}`} className="view-link">
                                   
                        <div className="room-card">
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src${build.BuildingInteriorImage1}`}
                                alt="Building Exterior"
                                className="room-card-image"
                            />
                            <div className="room-card-content">
                                <h2 className="room-title">{build.BuildingName}</h2>
                                <p className="b-type">
                                    <i className="fas fa-map-marker-alt me-1"></i>
                                    {build.ZoneName}  
                                </p>
                                <div className="plan-options">
                                    <button className="plan-option">{build.PreferedGender}</button>
                                </div>
                                <i className="fas fa-bed me-1"></i>{build.Setup}
                              
                                <div className="room-details">
                                    <div className="detail-item">
                                        <p>From R{build.Best_Price} per room</p>
                                        <span>{build.Capacity > 0 ? `${build.Capacity} Beds Available` : 'No Beds Available'}</span>
                                    </div>
                                </div>
                            </div>  
                        </div></Link>
                    </div>
                    )
                ))} 
            </div>
        <div className="fdsdsfdsdsvApartment">
                <button className="dsdssAllApartment prev" onClick={handlePrev}>&#10094;</button>
                <button className="dsdssAllApartment nextAll" onClick={handleNext}> &#10095;</button>
            </div>
    </div>

    <div className="map-container">
        {buildings.length > 0 && <LeafletMap apartments={buildings} />}
    </div>
</div>  
<div data-testid="Amber Referral Program and Offers"
          class="[object Object] subsection-module__subsectionContainer subsection-module__bgColor subsection-module__desktop">
          <div class="subsection-module__header subsection-module__headerDesktop">
            <h4 class="subsection-module__title">JAES Referral Program and Offers</h4>
            <h5 class="subsection-module__subtitle">Several promotions, deals and special offers crafted just for you.
            </h5>
          </div>
          <div>
            <div class="HorizontalScroll-module__hrContainer">
              <div
                class="ReferralProgram-module__horizontalScroll ReferralProgram-module__desktop HorizontalScroll-module__horizontalScroll">
              
                <div class="ReferralProgramCard-module__cardOuter" role="none" data-testid="referral-program-card">
                  <div class="ReferralProgramCard-module__cardInner1 ReferralProgramCard-module__desktop"
                    data-testid="referral-program-card-Refer"><img height="500" width="200"
                      src={offer1}
                      data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                    <div>
                      <div class="ReferralProgramCard-module__cardTitle"  style={{textAlign: "left", width:'70%'}}>Buy tickets and access rewards with us</div>
                      <div class="ReferralProgramCard-module__cardSubTitle"  style={{textAlign: "left"}}>Turn connections into rewards.</div>
                    </div><button type="button"
                      class="Button-module__btn Button-module__primary ReferralProgramCard-module__cta"
                      data-testid="amber-refer-offer-section-Refer-card-Refer Now-button" role="button"
                      aria-selected="false">
                        <Link to="/Event_Ticket"> 
                      <div role="button" class="Ripple-module__container" tabindex="0" data-testid="tab"></div><span
                       style={{color:'white'}}   class="Button-module__btnInnerContent">Buy Ticket</span>
                        </Link>
                    </button>
                  </div>
                </div>
             
                <div class="ReferralProgramCard-module__cardOuter" role="none" data-testid="referral-program-card">
                  <div class="ReferralProgramCard-module__cardInner3 ReferralProgramCard-module__desktop"
                    data-testid="referral-program-card-Amber-plus"><img height="500" width="200"
                    src={offer2}
                      data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                    <div>
                      <div class="ReferralProgramCard-module__cardTitle"  style={{textAlign: "left", width:'60%'}}>Save up with Jaes<span></span></div>
                      <div class="ReferralProgramCard-module__cardSubTitle"  style={{textAlign: "left", width:'80%'}}>Get exclusive transport from trusted
                       drivers to and from campus</div>
                    </div><button type="button"
                      class="Button-module__btn Button-module__primary ReferralProgramCard-module__cta"
                      data-testid="amber-refer-offer-section-Amber-plus-card-v-button" role="button"
                      aria-selected="false">
                         <Link to="/Transport"> 
                      <div role="button"class="Ripple-module__container" tabindex="0" data-testid="tab"></div><span  style={{color:'white'}} 
                        class="Button-module__btnInnerContent">Ride now</span>
                        </Link>
                    </button>
                  </div>
                </div>
                <div class="ReferralProgramCard-module__cardOuter" role="none" data-testid="referral-program-card">
                  <div class="ReferralProgramCard-module__cardInner3 ReferralProgramCard-module__desktop"
                    data-testid="referral-program-card-Scholarships"><img height="500" width="200"
                    src={offer3}                      data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                    <div>
                      <div class="ReferralProgramCard-module__cardTitle"  style={{textAlign: "left", width:'80%'}}>Want students to live in your home</div>
                      <div class="ReferralProgramCard-module__cardSubTitle"  style={{textAlign: "left", width:'60%'}}>List your home with us !</div>
                    </div><button type="button"
                      class="Button-module__btn Button-module__primary ReferralProgramCard-module__cta"
                      data-testid="amber-refer-offer-section-Scholarships-card-Apply Now-button" role="button"
                      aria-selected="false">
                              <Link to="/"> 
                      <div role="button" class="Ripple-module__container" tabindex="0" data-testid="tab"></div><span
                        style={{color:'white'}}  class="Button-module__btnInnerContent">List your home</span>
                              </Link>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div data-testid="Book your place in 3 easy steps"
          class="[object Object] subsection-module__subsectionContainer [object Object] subsection-module__desktop">
          <div class="subsection-module__header subsection-module__headerDesktop">
            <h4 class="subsection-module__title">Book your place in 3 easy steps</h4>
            <h5 class="subsection-module__subtitle">Book places in major cities and universities across the country</h5>
          </div>
          <div>
          <div class="HorizontalScroll-module__hrContainer ReferralProgram-module__horizontalScroll ">
              <div class="StepCard-module__stepCardOuter">
                <div class="StepCard-module__stepCardInnerDesktop" data-testid="Discover and Finalise">
                  <div class="StepCard-module__stepNumber">1</div><img height="46" width="46"
                    src="./Discover the Best Student Accommodation and Housing _ Amber_files/bookings-steps-hd-1.svg"
                    data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                  <h4 class="StepCard-module__title">Discover and Finalise</h4>
                  <div class="StepCard-module__subTitle">Choose from a plethora of verified <br></br> student home listings</div>
                </div><img height="6" width="25.2"
                  src="./Discover the Best Student Accommodation and Housing _ Amber_files/step-arrow-right.svg"
                  data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
              </div>
              <div class="StepCard-module__stepCardOuter">
                <div class="StepCard-module__stepCardInnerDesktop" data-testid="Get your paperwork done">
                  <div class="StepCard-module__stepNumber">2</div><img height="46" width="46"
                    src="./Discover the Best Student Accommodation and Housing _ Amber_files/bookings-steps-hd-2.svg"
                    data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                  <h4 class="StepCard-module__title">Get your paperwork done</h4>
                  <div class="StepCard-module__subTitle">Paperwork’s on us, no need to fuss.</div>
                </div><img height="6" width="25.2"
                  src="./Discover the Best Student Accommodation and Housing _ Amber_files/step-arrow-right.svg"
                  data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
              </div>
              <div class="StepCard-module__stepCardOuter">
                <div class="StepCard-module__stepCardInnerDesktop" data-testid="Accommodation Booked!">
                  <div class="StepCard-module__stepNumber">3</div><img height="46" width="46"
                    src="./Discover the Best Student Accommodation and Housing _ Amber_files/bookings-steps-hd-3.svg"
                    data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                  <h4 class="StepCard-module__title">Accommodation Booked!</h4>
                  <div class="StepCard-module__subTitle">Relax, pack your bags,<br></br> and unravel a new life chapter!</div>
                </div>
              </div>
            </div>
          </div>
        </div>

             <div class="TrustPilotBanner-module__trustPilotBannerHome TrustPilotBanner-module__trustpilotHomeDesktopSection"
          data-testid="homepage-trustpilot-section">
          <div class="TrustPilotBanner-module__radialGradient"></div>
          <div className="TrustPilotBanner-module__trustPilotContainer " style={{ marginBottom: '40px' }}>
            <div class="[object Object] subsection-module__subsectionContainer [object Object] subsection-module__desktop">
              <div class='subsection-module__header subsection-module__headerDesktop'>
                <h3 class="subsection-module__title">What do students have to say about us?</h3>
                <h4 class="subsection-module__subtitle">Thousands of students trust JAES for their
                  housing needs.</h4>
              </div>
         
            </div>
            <div class="TrustPilotBanner-module__trustpilotDesktopContainer">
              <div class="TrustPilotBanner-module__homepageHorizontalScrollTP">
                <div class="HorizontalScroll-module__hrContainer">
                  <div class="TrustPilotBanner-module__horizontalScroll HorizontalScroll-module__horizontalScroll">
                    <div
                      class="TrustPilotBanner-module__trustPilotCarouselItem TrustPilotBanner-module__trustpilotHomeItem"
                      data-testid="homepage-tp-review-carousel-item">
                      <div class="TrustPilotBanner-module__carouselItemBorder">
                        <div>
                          <div class="TrustPilotBanner-module__trustPilotCarouselReviewText "
                            data-testid="homepage-tp-customer-review">JAES helped me with shortlisting accommodations
                            according to my preferences, cleared all doubts and arranged a viewing.</div>
                        </div>
                        <div class="TrustPilotBanner-module__trustPilotBottom">
                          <div><img height="40" width="40"
                              src="https://images.unsplash.com/photo-1589156280159-27698a70f29e?q=80&w=1586&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              alt="" class="img-fluids radius-image-full"/></div>
                          <div>
                            <div class="TrustPilotBanner-module__trustPilotUser" data-testid="homepage-tp-user-name">
                              Amahle</div>
                            <div class="TrustPilotBanner-module__trustPilotUniHome"
                              data-testid="homepage-tp-university-name">Varisty College</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="TrustPilotBanner-module__trustPilotCarouselItem TrustPilotBanner-module__trustpilotHomeItem"
                      data-testid="homepage-tp-review-carousel-item">
                      <div class="TrustPilotBanner-module__carouselItemBorder">
                        <div>
                          <div class="TrustPilotBanner-module__trustPilotCarouselReviewText "
                            data-testid="homepage-tp-customer-review">Very quick response and helpful. Thanks
                            JAES Homes . </div>
                        </div>
                        <div class="TrustPilotBanner-module__trustPilotBottom">
                        <div><img height="40" width="40"
                              src="https://images.unsplash.com/photo-1531945086322-64e2ffae14a6?q=80&w=1586&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              alt="" class="img-fluids radius-image-full"/></div>
                          <div>
                            <div class="TrustPilotBanner-module__trustPilotUser" data-testid="homepage-tp-user-name">
                              Tise</div>
                            <div class="TrustPilotBanner-module__trustPilotUniHome"
                              data-testid="homepage-tp-university-name"> University of Kwa-Zulu Natal</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="TrustPilotBanner-module__trustPilotCarouselItem TrustPilotBanner-module__trustpilotHomeItem"
                      data-testid="homepage-tp-review-carousel-item">
                      <div class="TrustPilotBanner-module__carouselItemBorder">
                        <div>
                          <div class="TrustPilotBanner-module__trustPilotCarouselReviewText "
                            data-testid="homepage-tp-customer-review">Very quick and easy! Absolutley lovely staff
                            and an overall brilliant experience.</div>
                        </div>
                        <div class="TrustPilotBanner-module__trustPilotBottom">
                        <div><img height="40" width="40"
                              src="https://images.unsplash.com/photo-1531901599143-df5010ab9438?q=80&w=1587&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              alt="" class="img-fluids radius-image-full"/></div>
                          <div>
                            <div class="TrustPilotBanner-module__trustPilotUser" data-testid="homepage-tp-user-name">
                              Yamkela</div>
                            <div class="TrustPilotBanner-module__trustPilotUniHome"
                              data-testid="homepage-tp-university-name">Durban University of Technology</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="TrustPilotBanner-module__trustPilotCarouselItem TrustPilotBanner-module__trustpilotHomeItem"
                      data-testid="homepage-tp-review-carousel-item">
                      <div class="TrustPilotBanner-module__carouselItemBorder">
                        <div>
                          <div class="TrustPilotBanner-module__trustPilotCarouselReviewText "
                            data-testid="homepage-tp-customer-review">Received great help from the JAES Homes
                            executive in finding and booking a place.</div>
                        </div>
                        <div class="TrustPilotBanner-module__trustPilotBottom">
                        <div><img height="40" width="40"
                              src="https://images.unsplash.com/photo-1728231808086-3b67659b1feb?q=80&w=1460&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              alt="" class="img-fluids radius-image-full"/></div>
                          <div>
                            <div class="TrustPilotBanner-module__trustPilotUser" data-testid="homepage-tp-user-name">
                              Asanda</div>
                            <div class="TrustPilotBanner-module__trustPilotUniHome"
                              data-testid="homepage-tp-university-name">University of Kwa-Zulu Natal </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      class="TrustPilotBanner-module__trustPilotCarouselItem TrustPilotBanner-module__trustpilotHomeItem"
                      data-testid="homepage-tp-review-carousel-item">
                      <div class="TrustPilotBanner-module__carouselItemBorder">
                        <div>
                          <div class="TrustPilotBanner-module__trustPilotCarouselReviewText "
                            data-testid="homepage-tp-customer-review">A great experience while finding accommodation
                            through JAES. Ten out of ten service to JAES.</div>
                        </div>
                        <div class="TrustPilotBanner-module__trustPilotBottom">
                          <div><img height="40" width="40"
                              src="https://images.unsplash.com/photo-1652344259866-18320349a9db?q=80&w=1287&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                              alt="" class="img-fluids radius-image-full"/></div>
                          <div>
                            <div class="TrustPilotBanner-module__trustPilotUser" data-testid="homepage-tp-user-name">
                              Bradley</div>
                            <div class="TrustPilotBanner-module__trustPilotUniHome"
                              data-testid="homepage-tp-university-name">Vega College</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
               <div data-testid="Book your Perfect Accommodation"
          class="[object Object] subsection-module__subsectionContainer [object Object] subsection-module__desktop">
          <div class="subsection-module__header subsection-module__headerDesktop">
            <h4 class="subsection-module__title">Book your Perfect Accommodation</h4>
            <h4 class="subsection-module__subtitle">Take the hassle out of securing your student home for the best years
              of your life.</h4>
          </div>
          <div>
            <div class="bookYourPerfectAccommodation-module__insightsContainer"
              data-testid="book-your-perfect-accomodation-section">
              <div
                class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withPinkGradient bookYourPerfectAccommodation-module__desktopInsight"
                data-testid="Quick &amp; Easy Bookings"><img height="46" width="46"
                  src="./Discover the Best Student Accommodation and Housing _ Amber_files/fast-and-easy-bookings-insight.svg"
                  data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                <div class="bookYourPerfectAccommodation-module__detailsContainer"><span
                    class="bookYourPerfectAccommodation-module__title">Quick &amp; Easy Bookings</span><span
                    class="bookYourPerfectAccommodation-module__subtitle">Time is money. Save both when you book with
                    us </span></div>
              </div>
              <div
                class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withPinkGradient bookYourPerfectAccommodation-module__desktopInsight"
                data-testid="Price-Match Guarantee"><img height="46" width="46"
                  src="./Discover the Best Student Accommodation and Housing _ Amber_files/expert-assistance-insight.svg"
                  data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                <div class="bookYourPerfectAccommodation-module__detailsContainer"><span
                    class="bookYourPerfectAccommodation-module__title">Price-Match Guarantee</span><span
                    class="bookYourPerfectAccommodation-module__subtitle">Find a lower price and we'll match it.</span></div>
              </div>
              <div
                class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withPinkGradient bookYourPerfectAccommodation-module__desktopInsight"
                data-testid="24x7 Assistance"><img height="46" width="46"
                  src="./Discover the Best Student Accommodation and Housing _ Amber_files/verified-listings-insight.svg"
                  data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                <div class="bookYourPerfectAccommodation-module__detailsContainer"><span
                    class="bookYourPerfectAccommodation-module__title">24x7 Assistance</span><span
                    class="bookYourPerfectAccommodation-module__subtitle">If you have a doubt or a query, we’re always a
                    call away </span></div>
              </div>
              <div
                class="bookYourPerfectAccommodation-module__insight bookYourPerfectAccommodation-module__withGreenGradient bookYourPerfectAccommodation-module__desktopInsight"
                data-testid="100% Verified Listings"><img height="46" width="46"
                  src="./Discover the Best Student Accommodation and Housing _ Amber_files/price-match-guarantee-insight.svg"
                  data-testid="" class="Image-module__animateOpacity Image-module__show" aria-hidden="true"/>
                <div class="bookYourPerfectAccommodation-module__detailsContainer"><span
                    class="bookYourPerfectAccommodation-module__title">100% Verified Listings</span><span
                    class="bookYourPerfectAccommodation-module__subtitle">We promise to deliver what you see on the
                    website </span></div>
              </div>
            </div>
          </div>
        </div>
        <footer className="w3l-footer-29-main py-10">
        <div className="container pt-lg-5 pt-sm-4 pt-2 pb-2">
          <div className="row footer-top-29">
            {/* Logo on the left */}
            <div className="logoFooterItem">
              <Link className="btn-group-vertical" to="/">
                <img src={logo} alt="Tours Logo" className="" />
              </Link>
            </div>

            {/* Footer content side by side (Quick Links, Explore, Contact Info) */}
            <div className="w3l-promocode">
              <div className="row footer-list-29">
                <div className="col-lg-4 col-md-6 col-sm-12">
                  <ul>
                    <h6 className="footer-title-29">Quick Links</h6>
                    <li>
                      <a href="about.html" style={{ color: "white" }}>
                        About Us
                      </a>
                    </li>
                    <li>
                      <a href="#services" style={{ color: "white" }}>
                        Services
                      </a>
                    </li>
                    <li>
                      <a href="/ContactUs" style={{ color: "white" }}>
                        Contact Us
                      </a>
                    </li>
                    <li>
                      <a href="#rewards" style={{ color: "white" }}>
                        Rewards
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-12">
                  <ul>
                    <h6 className="footer-title-29" style={{ color: "white" }}>
                      Explore
                    </h6>
                    <li>
                      <a href="#blog" style={{ color: "white" }}>
                        Blog Posts
                      </a>
                    </li>
                    <li>
                      <a href="#privacy" style={{ color: "white" }}>
                        Privacy Policy
                      </a>
                    </li>
                    <li>
                      <a href="#partners" style={{ color: "white" }}>
                        Our Partners
                      </a>
                    </li>
                    <li>
                      <a href="#events" style={{ color: "white" }}>
                        Events
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-12 col-sm-12">
                  <h6 className="footer-title-29">Contact Info</h6>
                  <p className="mb-2" style={{ color: "white" }}>
                    Phone Number:{" "}
                    <a href="tel:+27(81)5006508" style={{ color: "white" }}>
                      +27(81) 500 6508
                    </a>
                  </p>
                  <p className="mb-2" style={{ color: "white" }}>
                    Email:{" "}
                    <a href="mailto:Info@jaes.co.za" style={{ color: "white" }}>
                      Info@jaes.co.za
                    </a>
                  </p>
                  <div className="mt-2">
                    <ul className="social">
                      <li>
                        <a
                          href="https://web.whatsapp.com/send?phone=27815006508"
                          target="_blank"
                        >
                          <i className="fab fa-whatsapp"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@jaesproperties"
                          target="_blank"
                        >
                          <i className="fab fa-tiktok"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/jaesproperties"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="copy-footer-234">
                  <p style={{ color: "white" }}>
                    © 2024 Jae's Homes. All rights reserved. Design by{" "}
                    <a
                      href="https://vingamedia.co.za"
                      target="_blank"
                      style={{ color: "white" }}
                    >
                      VingaMedia
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
        </div>
    );
};

export default AllApartments;
