import React, { useEffect, useState } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2'

import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './SignupTransport.css';
import './reserveNew.css';

function SignupTransport() {
    const { isLoggedIn, userId } = useAuth();
    const [userData, setUserData] = useState(null);
    const [selectedPrice, setSelectedPrice] = useState('R250');
    const [selectedUniversity, setSelectedUniversity] = useState('');
    const [address, setAddress] = useState('');
    const [suggestions, setSuggestions] = useState([]);
    const [paymentStatus, setPaymentStatus] = useState('');
    const [step, setStep] = useState(1);
    const [transport, setTransport] = useState('');
    const nextStep = () => {
      if (step === 1 && !selectedUniversity) {
          Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please select a university.",
              showConfirmButton: false,
              timer: 12000
          });
          return;
      }
  
      if (step === 2 && !address) {
          Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please enter an address.",
              showConfirmButton: false,
              timer: 12000
          });
          return;
      }
  
      setStep((prevStep) => Math.min(prevStep + 1, 3));
  };
      const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1));
    const navigate = useNavigate();

    
    const handleTransportChange = (event) => {
      setTransport(event.target.value);
    };
    // Coordinates for Ukzn Howard University
    const ukznHowardCoordinates = L.latLng(-29.8667, 30.9784);

    // Bounding box for Durban, South Africa (approximate)
    const durbanBoundingBox = {
        minLon: 30.8577,
        minLat: -30.0761,
        maxLon: 31.1295,
        maxLat: -29.7454
    };

    useEffect(() => {
        if (isLoggedIn && userId) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    
                    if (!response.ok) throw new Error('Failed to fetch user data');
                    const data = await response.json();
                    setUserData(data.length ? data[0] : data);
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
    }, [isLoggedIn, userId]);

    const handleProceedToPayment = async () => {
        if (!address  || !selectedUniversity) {
            //alert("Please select all options.");
            Swal.fire({
              position: "top-end",
              icon: "error",
              title: "Please select all options.",
              showConfirmButton: false,
              timer: 3500
          });
            return;
        }

        const amountInCents = parseInt(selectedPrice.replace('R', '')) * 100;
        const successUrl = `https://jaesstudenthomes.co.za/transportpayment?userId=${encodeURIComponent(userId)}&address=${encodeURIComponent(address)}&price=${encodeURIComponent(selectedPrice)}&university=${encodeURIComponent(selectedUniversity)}`;

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel',
                    room: `${address}-${userId}`,
                }),
            });

            if (!response.ok) throw new Error('Payment initiation failed');

            const data = await response.json();
            const paymentUrl = data.paymentUrl || data.redirectUrl || data.url || null;

            if (paymentUrl) {
                setPaymentStatus('Redirecting to payment gateway...');
                window.location.href = paymentUrl;
            } else {
                setPaymentStatus('No payment URL received. Please contact support.');
                console.error('No payment URL received:', data);
            }
        } catch (error) {
            console.error('Payment initiation error:', error);
            setPaymentStatus('Payment initiation failed. Please try again.');
        }
    };

    const handleAddressChange = async (event) => {
        const query = event.target.value;
        setAddress(query);
    
        if (query.length > 2) {
            const apiKey = 'f4c51dd4a35e4aeda050b373039cfc83';
            const url = `https://api.geoapify.com/v1/geocode/autocomplete?text=${encodeURIComponent(query)}&filter=countrycode:za&bias=rect:${durbanBoundingBox.minLon},${durbanBoundingBox.minLat},${durbanBoundingBox.maxLon},${durbanBoundingBox.maxLat}&limit=5&apiKey=${apiKey}`;
    
            try {
                const response = await fetch(url);
                const data = await response.json();
                const filteredSuggestions = data.features.map((feature) => {
                    const { housenumber, street, suburb } = feature.properties;
                    const displayName = `${housenumber || ''} ${street || ''}, ${suburb || ''}`.trim();
                    return {
                        ...feature,
                        display_name: displayName,
                        lat: feature.geometry.coordinates[1],
                        lon: feature.geometry.coordinates[0],
                    };
                });
                setSuggestions(filteredSuggestions);
            } catch (error) {
                console.error("Error fetching suggestions:", error);
                setSuggestions([]);
            }
        } else {
            setSuggestions([]);
        }
    };
    
    
    
    const handleSuggestionClick = (suggestion) => {
      const selectedLatLng = L.latLng(suggestion.lat, suggestion.lon);
      
      if (selectedUniversity === "Ukzn Howard") {
          const distance = selectedLatLng.distanceTo(ukznHowardCoordinates);
          
          if (distance <= 2500) {
              setAddress(suggestion.display_name); // Use the filtered display_name
              setSuggestions([]); // Clear suggestions after selection
          } else {
              Swal.fire({
                  position: "top-end",
                  icon: "error",
                  title: "Selected address is more than 2.5 km away from Ukzn Howard University.",
                  showConfirmButton: false,
                  timer: 1500
              });
              setAddress(''); // Clear address if it's out of range
          }
      } else if (selectedUniversity === "DUT Steve Biko") {
          // No radius check for DUT, just set the address
          setAddress(suggestion.display_name);
          setSuggestions([]);
      }
  };
  
    

    return (
        <div>
             <div className="full-page-container">
      <div className="full-page-content">
        <span className="close-btn" >×</span>
        {userData ? (
                <div>
                    <h1 class="subsection-module__title">Hi {userData.Name}, let's complete your booking</h1>

                </div>
            ) : (
                <p>Loading user information...</p>
            )}
       {/* Step Count */}
       <div className="amber-form-group2">
<p style={{textAlign:'left'}}>Step {step} / 3</p>
   
    </div>


        {/* Progress Bar */}
        <div className="progress-container">
          {[1, 2, 3].map((bar) => (
            <div key={bar} className={`progress-bar ${step >= bar ? 'filled' : ''}`} />
          ))}
        </div>

        {/* Step Content */}
        {step === 1 && (
          <div>
        <h2 class="subsection-module__title"> University </h2>
        

        <div className="amber-form-group2">
<p style={{textAlign:'left'}}>Please select your school to ensure accurate pricing, route planning, and availability of our transport service tailored specifically for your institution.</p>
   
    </div>
    <style>{`
    .step-count {
    text-align: left;
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom: 10px; /* Adjust spacing as needed */
}
    .checkbox-wrapper input[type="checkbox"] {
  display: none;
}

.checkbox-wrapper .terms-label {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.checkbox-wrapper .terms-label .label-text {
  margin-right: 10px;
}

.checkbox-wrapper .checkbox-svg {
  width: 30px;
  height: 30px;
}

.checkbox-wrapper .checkbox-box {
  fill: rgba(207, 205, 205, 0.425);
  stroke: #ff5a5f;
  stroke-dasharray: 800;
  stroke-dashoffset: 800;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper .checkbox-tick {
  stroke: #ff5a5f;
  stroke-dasharray: 172;
  stroke-dashoffset: 172;
  transition: stroke-dashoffset 0.6s ease-in;
}

.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-box,
.checkbox-wrapper input[type="checkbox"]:checked + .terms-label .checkbox-tick {
  stroke-dashoffset: 0;
}.divider-line {
    min-width: 400px;
    border: 0;
    border-top: 10px solid #000;
    margin: 10px 0;
}

.button-group {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.button-group button {
    background-color: #3498db;
    color: white;
    padding: 10px 20px;
    margin: 0 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
}

`}</style>
<label style={{ display: 'block', textAlign: 'left', fontSize:'17px' }}>Choose University:</label>
<div className="form-grid">
            
<div style={{ display: 'flex', gap: '15px', marginTop: '8px' }}>
  <div className="checkbox-wrapper" onClick={() => setSelectedUniversity("Ukzn Howard")}>
    <input
      id="ukzn-checkbox"
      name="checkbox"
      type="checkbox"
      checked={selectedUniversity === "Ukzn Howard"}
      readOnly
    />
    <label className="terms-label" htmlFor="ukzn-checkbox">
      <span className="label-text">Ukzn Howard</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
        <mask fill="white" id="path-1-inside-1_476_5-ukzn">
          <rect height="200" width="200"></rect>
        </mask>
        <rect mask="url(#path-1-inside-1_476_5-ukzn)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
        <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
      </svg>
    </label>
  </div>

  <div className="checkbox-wrapper" onClick={() => setSelectedUniversity("DUT Steve Biko")}>
    <input
      id="dut-checkbox"
      name="checkbox"
      type="checkbox"
      checked={selectedUniversity === "DUT Steve Biko"}
      readOnly
    />
    <label className="terms-label" htmlFor="dut-checkbox">
      <span className="label-text">DUT</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 200 200" className="checkbox-svg">
        <mask fill="white" id="path-1-inside-1_476_5-dut">
          <rect height="200" width="200"></rect>
        </mask>
        <rect mask="url(#path-1-inside-1_476_5-dut)" strokeWidth="40" className="checkbox-box" height="200" width="200"></rect>
        <path strokeWidth="15" d="M52 111.018L76.9867 136L149 64" className="checkbox-tick"></path>
      </svg>
    </label>
  </div>
</div>


            <button className="btn" onClick={nextStep}>Next</button>
          </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 class="subsection-module__title" > Accomodation Address </h2>
            <div className="amber-form-group2">
<p style={{textAlign:'left'}}>please enter your home pickup address. If you've chosen Ukzn Howard, make sure the address is within 2.5 km of the campus to keep our routes quick and affordable. If you've chosen DUT, you can enter any address.</p>
   
    </div>
            <label>
                Enter Address:
                <input
                    type="text"
                    value={address}
                    onChange={handleAddressChange}
                    placeholder="Enter your address..."
                />
                <ul className="suggestions-list">
                    {suggestions.map((suggestion, index) => (
                        <li
                            key={index}
                            onClick={() => handleSuggestionClick(suggestion)}
                        >
                            {suggestion.display_name}
                        </li>
                    ))}
                </ul>
            </label>
            <div className="button-group">
    <button className="btn back-btn" onClick={prevStep}>Back</button>
    <button className="btn next-btn" onClick={nextStep}>Next</button>
</div>

          </div>
        )}
 {step === 3 && (
    <div>
        <h2 class="subsection-module__title"> Confirmation</h2>
       
        <div className="amber-form-group2">
            <p style={{ textAlign: 'left' }}>Review your booking details before confirming:</p>
        </div>

        <div className="review-container">
            <div className="review-left" style={{ textAlign: 'left' }}>
                <p><strong>University:</strong> {selectedUniversity || 'Not selected'}</p>
                <p><strong>Address:</strong> {address || 'Not entered'}</p>
            </div>
            
            <div className="separator"></div>
            
            <div className="review-right">
                <div className="amount-details">
                    <p style={{ textAlign: 'left' }}><strong>Amount:</strong></p>
                    <p style={{ textAlign: 'left' }} className="strikethrough">R450</p>

                    <p style={{ textAlign: 'left' }}><strong>Discount:</strong></p>
                    <p style={{ textAlign: 'left' }}>45% off</p><hr className="divider-line" /><br/>
                    
 
                    <p style={{ textAlign: 'left' }}><strong>Total:</strong></p>
                    <p style={{ textAlign: 'left' }}>R250</p>
                </div>
            </div>
        </div>
<button className="btn" style={{backgroundColor:'#76964c'}} onClick={handleProceedToPayment}>Proceed to Payment</button>
  {/* Add download pdf  */}
        <button className="btn" onClick={prevStep}>Back</button>
          </div>
)}
<style>{`

.review-container {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.review-left,
.review-right {
    flex: 1;
}

.separator {
    width: 2px;
    background-color: #000;
    height: 100%;
    margin: 0 20px;
}

.amount-details {
    display: grid;
    grid-template-columns: auto auto; /* Two columns: one for labels and one for values */
    row-gap: 5px;
    column-gap: 20px; /* Adjusts spacing between labels and values */
}

.amount-labels,
.amount-values {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.discount-highlight {
    font-weight: bold;
}

.strikethrough {
    text-decoration: line-through;
    color: #999;
}

`}</style>

      </div>
    </div>
        </div>
    );
}

export default SignupTransport;
