import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import './loaders.css';

function YocoPayment() {
    const location = useLocation();

    // Destructuring the data passed via location.state
    const { date, apartment, totalAmount, serviceFee, chosenPlan, nextPaymentDate, apartmentandbuilding, ApartmentPrice, Email } = location.state || {};
console.log("my gym total amount",totalAmount)
    // Calculate the next payment date
    let nextPaymentDate1 = new Date(date);
    nextPaymentDate1.setMonth(nextPaymentDate1.getMonth() + 1, 1);

    useEffect(() => {
        // Function to handle the Yoco payment submission
        const submitYocoForm = async () => {
            try {
                const amountInCents = Math.round(parseFloat(totalAmount) * 100); // Convert amount to cents

                // Prepare the success URL with encoded data as query parameters
                const successUrl = `https://jaesstudenthomes.co.za/Payment?date=${encodeURIComponent(date)}&apartment=${encodeURIComponent(apartment.ApartmentId)}&totalAmount=${encodeURIComponent(totalAmount)}&chosenPlan=${encodeURIComponent(chosenPlan)}&serviceFee=${encodeURIComponent(serviceFee)}&nextPaymentDate=${encodeURIComponent(nextPaymentDate1.toISOString())}&apartmentandbuilding=${encodeURIComponent(apartmentandbuilding)}&apartmentnumber=${encodeURIComponent(apartment.ApartmentNumber)}&apartmenttype=${encodeURIComponent(apartment.ApartmentType)}&apartmentpreferedgender=${encodeURIComponent(apartment.ApartmentPreferedGender)}&apartmentdeposit=${encodeURIComponent(apartment.ApartmentDeposit)}&apartmentprice=${encodeURIComponent(apartment.ApartmentPrice)}&apartmentrating=${encodeURIComponent(apartment.ApartmentRating)}&livingarrangement=${encodeURIComponent(apartment.LivingArrangement)}&bedroomstatus=${encodeURIComponent(apartment.BedRoomStatus)}&bedroomcount=${encodeURIComponent(apartment.BedRoomCount)}&buildingmanager=${encodeURIComponent(apartment.BuildingManager)}&guest=${encodeURIComponent(apartment.Guest)}&apartmentloungeimage=${encodeURIComponent(apartment.ApartmentLoungeImage)}&apartmentkitchenimage=${encodeURIComponent(apartment.ApartmentKitchenImage)}&apartmentbathroomimage=${encodeURIComponent(apartment.ApartmentBathroomImage)}&apartmentroomimage=${encodeURIComponent(apartment.ApartmentRoomImage)}&apartmentsize=${encodeURIComponent(apartment.ApartmentSize)}&livingspace=${encodeURIComponent(apartment.LivingSpace)}&totalnumofbeds=${encodeURIComponent(apartment.TotalNumOfBeds)}&priceb=${encodeURIComponent(apartment.PriceB)}&createddate=${encodeURIComponent(apartment.CreatedDate)}&createdby=${encodeURIComponent(apartment.CreatedBy)}&capacity2=${encodeURIComponent(apartment.Capacity2)}&roomidentifier=${encodeURIComponent(apartment.RoomIdentifier)}&ApartmentPrice=${encodeURIComponent(apartment.ApartmentPrice)}`;

                // Send the payment details to your backend for Yoco processing
                const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        amount: amountInCents,
                        currency: 'ZAR',
                        successUrl,  // Pass the dynamic successUrl with the encoded state
                        cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel',
                         room: `${apartment.RoomIdentifier}-${Email}`
                    }),
                });

                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Error: ${response.status} - ${errorText}`);
                }

                const data = await response.json();
                console.log('Payment response:', data);

                // After successful payment, navigate to Yoco's redirect URL
                if (data.redirectUrl) {
                    window.location.href = data.redirectUrl;  // Redirect the user to Yoco's payment page
                }
            } catch (error) {
                console.error('Payment error:', error);
                alert(`Payment error: ${error.message}`);
            }
        };

        // Trigger the payment form submission when the component loads
        submitYocoForm();
    }, [date, apartment, totalAmount, serviceFee, chosenPlan, nextPaymentDate1]);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures the component takes up the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>
        </div>
    );
}

export default YocoPayment;
