import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import './userPayment.css';
import { useNavigate, useParams } from 'react-router-dom';

function AccessedUserPayment() {
    const [walletData, setWalletData] = useState([]);
    const { userId } = useParams();
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (userId) {
            fetch(`https://backendjaes.passiontrials.co.za/wallet/history/${userId}`, {
                headers: { 'Content-Type': 'application/json' }
            })
                .then(res => {
                    if (!res.ok) { throw new Error('Failed to get wallet data'); }
                    return res.json();
                })
                .then(wallet => {
                    if (wallet) {
                        // Filter out duplicates based on AmountPaid and PaymentDate
                        const filteredWallet = wallet.filter(
                            (item, index, self) =>
                                index === self.findIndex(t => t.AmountPaid === item.AmountPaid && t.PaymentDate === item.PaymentDate)
                        );
                        setWalletData(filteredWallet);
                        console.log('Filtered wallet data:', filteredWallet);
                    } else {
                        console.log('No wallet data found for this user');
                    }
                })
                .catch(err => {
                    console.error('Error:', err);
                    setError(err.message);
                });
        }
    }, [userId]);

    // Function to format the date
    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', options); // Example: '23 July 2024'
    };

    return (
        <>  <h2>Statement</h2>

            <div className="container" style={{ marginTop: '19px' }}>



                {error && <p className='error'>{error}</p>}
                <table className='payment-table'>
                    <thead>
                        <tr>
                            <th>Payment Date</th>
                            <th>Amount</th>

                            <th>Payment Method</th>
                            <th>month</th>
                        </tr>
                    </thead>
                    <tbody>
                        {walletData.map(pay => (
                            <tr key={pay.WalletId}>
                                <td className='date'>{formatDate(pay.PaymentDate)}</td>
                                <td className='amount'>R{pay.AmountPaid}</td>

                                <td>{pay.PaymentMethod || 'N/A'}</td>
                                <td>{pay.MonthYear || 'N/A'}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
            <p>Use Reference: 076BWN-R01
                <br />
                Pay into the following account:   <br />
                JAES Properties   <br />
                FNB   <br />
                Savings Account   <br />
                Acc#: 62793916192</p>
        </>

    );
}

export default AccessedUserPayment;
