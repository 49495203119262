import React, { useState } from "react";
import "./WaterSlideshow.css";

const WaterSlideshow = () => {
  const images = [
    "https://backendjaes.passiontrials.co.za/src/Building_images/rickturner1.jpg",
    "https://backendjaes.passiontrials.co.za/src/Building_images/Rickturner_RoomA5.jpg",
    "https://backendjaes.passiontrials.co.za/src/Building_images/rickturner2.jpg",
    "https://backendjaes.passiontrials.co.za/src/Building_images/rickturner4.JPG",
    "https://backendjaes.passiontrials.co.za/src/Building_images/rickturner5.jpg"
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const changeImage = (direction) => {
    const newIndex = (currentIndex + direction + images.length) % images.length;
    setCurrentIndex(newIndex);
  };

  const openModal = (index) => {
    setModalIndex(index);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const changeModalImage = (direction) => {
    const newIndex = (modalIndex + direction + images.length) % images.length;
    setModalIndex(newIndex);
  };

  return (
    <div className="ocean-slideshow-container">
      <div className="spring-large-image-container">
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className={index === currentIndex ? "spring-active" : ""}
            style={{ display: index === currentIndex ? "block" : "none" }}
          />
        ))}
        <button className="spring-arrow left" onClick={() => changeImage(-1)}>
          ‹
        </button>
        <button className="spring-arrow right" onClick={() => changeImage(1)}>
          ›
        </button>
      </div>

      <div className="pond-thumbnail-container">
        {images.slice(0, 3).map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Thumbnail ${index + 1}`}
            onClick={() => openModal(index)}
            className="pond-thumbnail"
          />
        ))}
      </div>

      {isModalOpen && (
        <div className="lake-modal" onClick={closeModal}>
          <div className="lake-modal-content" onClick={(e) => e.stopPropagation()}>
            <button className="lake-modal-close" onClick={closeModal}>
              ×
            </button>
            <img src={images[modalIndex]} alt={`Modal Image ${modalIndex + 1}`} className="lake-modal-image" />
            <button
              className="lake-modal-arrow left"
              onClick={() => changeModalImage(-1)}
            >
              ‹
            </button>
            <button
              className="lake-modal-arrow right"
              onClick={() => changeModalImage(1)}
            >
              ›
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default WaterSlideshow;
