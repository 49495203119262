import React, { useState } from 'react';

function PDFPreview() {
    const [pdfBase64, setPdfBase64] = useState(null);

    const fetchPdfPreview = async () => {
        const response = await fetch('https://backendjaes.passiontrials.co.za/sendBookingEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                userData: { name: 'John Doe', idNumber: '123456789' } // Example data
            })
        });

        const data = await response.json();
        setPdfBase64(data.pdfBase64);
    };

    return (
        <div>
            <button onClick={fetchPdfPreview}>Preview PDF</button>
            {pdfBase64 && (
    <iframe
        src={`data:application/pdf;base64,${pdfBase64}`}
        width="100%"
        height="500px"
        title="PDF Preview"
    />
)}

        </div>
    );
}

export default PDFPreview;
