import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function TransportPayment() {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('userId');
    const building = queryParams.get('address');
    const price = queryParams.get('price');
    const university = queryParams.get('university');
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [userData, setUserData] = useState(null); // State to store user data


    useEffect(() => {
        if (userData) {
            sendBookingEmail();
        } else {
            console.log('userData not loaded yet.');
        }
    }, [userData]);
    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                setUserData(data); // Store user data in state
                console.log('Fetched user data:', data); // Check if userData is set correctly

            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        if (userId) {
            fetchUserData(); // Fetch user data if userId exists
        }
    }, [userId]);
    // Save payment details to the database after a successful payment
    useEffect(() => {
        const savePaymentDetails = async () => {
            const numericPrice = parseFloat(price.replace('R', ''));
            await updateUserRoleToReserve(); 
            await updateInstituteName(); 
            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/save_payment', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        userId,
                        building,
                        price: numericPrice,  // Send the numeric price
                        university,
                        paymentStatus: "Active",
                        transRole: "usertransport"
                    }),
                });

                if (!response.ok) throw new Error('Failed to save payment details');
                console.log('Payment details saved successfully.');
                
                // Set payment status and navigate to userDashboard after successful save
                setPaymentStatus('Success');
                navigate('/TransportuserDashboard');
            } catch (error) {
                console.error('Error saving payment details:', error);
                setPaymentStatus('Failed to save payment details. Please try again.');
            }
        };

        savePaymentDetails();
    }, [paymentStatus, userId, building, price, university, navigate]);
    const updateUserRoleToReserve = async () => {
        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/updateUserRole', {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ userId, newRole: 'TransportOnly' })
            });
    
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Failed to update user role: ${errorText}`);
            }
    
            console.log('User role updated to reserve successfully!');
        } catch (error) {
            console.error(`Error updating user role: ${error.message}`);
        }
    };
        // Function to update InstituteName
        const updateInstituteName = async () => {
            try {
                const response = await fetch('https://backendjaes.passiontrials.co.za/updateInstitute', {
                    method: 'PUT',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({ userId, university })
                });
    
                if (!response.ok) {
                    const errorText = await response.text();
                    throw new Error(`Failed to update InstituteName: ${errorText}`);
                }
    
                console.log('InstituteName updated successfully!');
            } catch (error) {
                console.error(`Error updating InstituteName: ${error.message}`);
            }
        };
        const getShortRoomIdentifier = (address) => {
            const commaIndex = address.indexOf(',');
            return commaIndex !== -1 ? address.substring(0, commaIndex) : address;
        };
        
          // New function to send booking email
  const sendBookingEmail = async () => {
    try {
        if (!userData || !userData[0]?.Name || !userData[0]?.Surname) {
            console.error('userData or required fields are missing');
            return;
        }
        
        console.log('Preparing to send booking email with data:', {
            
            userId,
            name: `${userData[0]?.Name} ${userData[0]?.Surname}`, // Concatenate Name and Surname
            IdNumber: userData[0]?.IdNumber,
            RoomIdentifier: getShortRoomIdentifier(building), // Shortened RoomIdentifier
            reservedate: new Date().toLocaleDateString('en-GB'), // Format as DD/MM/YYYY
            cell: userData[0]?.PhoneNumber,
            BuildingLocation: userData[0]?.BuildingLocation,        
            Transport: "Yes",
            Homes: "Transport",
            userEmail: userData[0]?.Email
        });

        const response = await fetch('https://backendjaes.passiontrials.co.za/sendBookingEmail', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                apartmentId: 'transport',
                userId,
                name: `${userData[0]?.Name} ${userData[0]?.Surname}`, // Concatenate Name and Surname
                IdNumber: userData[0]?.IdNumber,
                BookingNo: getShortRoomIdentifier(building), // Shortened BookingNo
                Date: new Date().toLocaleDateString('en-GB'), // Format as DD/MM/YYYY
                Cell: userData[0]?.PhoneNumber,
                Address: building,
                
                Transport: "Yes",
                Homes: "Transport",
                userEmail: userData[0]?.Email
            })
        });

        if (!response.ok) {
            const errorText = await response.text();
            throw new Error(`Failed to send booking email: ${errorText}`);
        }
        console.log('Booking email sent successfully!');
    } catch (error) {
        console.error('Error sending booking email:', error.message);
    }
};
    return (
        <div>
            <h2>Transport Payment</h2>
            <p>Building: {building}</p>
            <p>Price: {price}</p>
            <p>University: {university}</p>
            <p>{paymentStatus === 'Success' ? 'Payment completed successfully.' : paymentStatus}</p>
        </div>
    );
}

export default TransportPayment;
