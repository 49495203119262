import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Customers.css'; // Import the CSS for card styling
import { useAuth } from './AuthProvider';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

function LandLord() {
    const [cust, setCust] = useState([]);
    const [tenantDetails, setTenantDetails] = useState([]);
    const [walletData, setWalletData] = useState({});// Store tenant details with building information
    const [buildingFilter, setBuildingFilter] = useState('');
    const [roomTypeFilter, setRoomTypeFilter] = useState('');
    const { userId } = useAuth();
    const [areaFilter, setAreaFilter] = useState('');
    const [searchTerm, setSearchTerm] = useState(''); // State for search input
    const [sortOrder, setSortOrder] = useState('asc'); // Sorting order
    const [showTenantOnly, setShowTenantOnly] = useState(false);
    const [colorFilter, setColorFilter] = useState(''); // Show tenants toggle
    const [buildings, setBuildings] = useState([]); // Stores buildings for the filter
    const [areas, setAreas] = useState([]); // Stores areas for the filter
    const [roomTypes, setRoomTypes] = useState([]); // Stores room types for the filter
    const [error, setError] = useState(null);
    const [attachedBuilding, setAttachedBuilding] = useState([]);
    const [displayUsers, setDisplayUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [showNoCustomersMessage, setShowNoCustomersMessage] = useState(false); // New state


    useEffect(() => {
        fetch('https://backendjaes.passiontrials.co.za/admin_tb')
            .then(res => res.json())
            .then(data => setAttachedBuilding(data))
            .catch(err => { console.log('An error occured:', err) });
        // Fetch customer data
        fetch('https://backendjaes.passiontrials.co.za/user_tb', {
            headers: { 'Content-Type': 'application/json' }
        })
            .then(res => res.json())
            .then(data => {
                if (data) {
                    setCust(data);
                    data.forEach(user => {
                        fetchWalletData(user.UserId); // Fetch wallet data for each user
                    });
                } else {
                    console.log('No users found');
                }
            })
            .catch(err => {
                console.log('There was an error while fetching users:', err);
                setError('There was an error with fetching users');
            });

        // Fetch detailed tenant data from all_user_full_details
        // Fetch areas for area filter
        fetch('https://backendjaes.passiontrials.co.za/areas')
            .then(res => res.json())
            .then(data => setAreas(data))
            .catch(err => console.error('Error fetching areas:', err));
        // Fetch room types for room type filter
        fetch('https://backendjaes.passiontrials.co.za/room_types')
            .then(res => res.json())
            .then(data => setRoomTypes(data))
            .catch(err => console.error('Error fetching room types:', err));
    }, []);

    useEffect(() => {
        setLoading(true);
        if (attachedBuilding) {
            fetch('https://backendjaes.passiontrials.co.za/all_user_full_details')
                .then(res => res.json())
                .then(data => {
                    const filter = data.filter((tenant) => tenant.CreatedBy == attachedBuilding.AdminEmail);
                    console.log('this is the tenant info', filter);
                    setTenantDetails(filter); // Store detailed information for all tenants
                    // Extract unique buildings from the data
                    const actualUsers = cust.filter((user) => { return !data.some(tenant => tenant.UserId == user.UserId); });
                    const allUsers = [...actualUsers, ...filter];
                    const allUniqueUsers = Array.from(new Set(allUsers.map(user => user.UserId))).map(id => allUsers.find(user => user.UserId === id));
                    setDisplayUsers(allUniqueUsers);
                    // console.log('the final array', displayUsers)
                    //  console.log('the tenants', filter)
                    // console.log('these are no associated users', actualUsers);
                    const uniqueBuildings = [...new Set(data.map(user => user.BuildingName))];
                    //const theBuildings = Array.from(new Set(filter.map(t => t.BuildingName)));
                    setBuildings(uniqueBuildings);
                    //console.log("this are the buildings in the list", theBuildings);
                    setLoading(false);
                })
                .catch(err => console.error('Error fetching all user details:', err));
        }
    }, [attachedBuilding, cust])

    useEffect(() => {
        if (userId && attachedBuilding.length > 0) {
            const filtered = attachedBuilding.filter((admin) => admin.AdminId == userId);
            setAttachedBuilding(filtered[0]);
            console.log('this is the admin info', filtered);
        }
    }, [userId, attachedBuilding]);
    // Function to get tenant details by UserId
    const getTenantDetails = (userId) => {
        return tenantDetails.find(tenant => tenant.UserId === userId);
    };


    const getWalletDaysPaidFor = (userId) => {
        const wallet = walletData[userId];
        return wallet ? wallet.DaysPaidFor : null;
    };

    // Get color code for sorting by status
    const getColorCode = (userId) => {
        const daysPaidFor = getWalletDaysPaidFor(userId);
        return daysPaidFor <= -1 ? 'red' : 'green';
    };

    const fetchWalletData = (userId) => {
        fetch(`https://backendjaes.passiontrials.co.za/wallet/${userId}`)
            .then(res => res.json())
            .then(data => {
                setWalletData(prev => ({
                    ...prev,
                    [userId]: data.length > 0 ? data[0] : null
                }));
            })
            .catch(err => console.error(`Error fetching wallet data for user ${userId}:`, err));
    };

    // Filter customers based on search, tenant status, building, room type, and area
    const filteredCustomers = displayUsers
        .filter(cus =>
            `${cus.Name} ${cus.Surname} ${cus.IdNumber} ${cus.Email} ${cus.PhoneNumber}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        )
        .filter(cus => !showTenantOnly || getTenantDetails(cus.UserId)) // Show only tenants if the toggle is on
        .filter(cus => !buildingFilter || (getTenantDetails(cus.UserId)?.BuildingName === buildingFilter)) // Filter by building
        .filter(cus => !roomTypeFilter || getTenantDetails(cus.UserId)?.LivingArrangement === roomTypeFilter) // Filter by room type
        .filter(cus => !areaFilter || getTenantDetails(cus.UserId)?.BuildingLocation === areaFilter) // Filter by area
        .sort((a, b) => {
            if (colorFilter) {
                const colorA = getColorCode(a.UserId);
                const colorB = getColorCode(b.UserId);
                return colorFilter === 'redFirst'
                    ? colorA.localeCompare(colorB)
                    : colorB.localeCompare(colorA);
            }
            if (sortOrder === 'asc') {
                return a.Name.localeCompare(b.Name);
            } else {
                return b.Name.localeCompare(a.Name);
            }
        });

    useEffect(() => {
        if (!loading && filteredCustomers.length === 0) {
            const timer = setTimeout(() => {
                setShowNoCustomersMessage(true);
            }, 3000); // 3 seconds delay

            return () => clearTimeout(timer); // Cleanup timer if component unmounts
        } else {
            setShowNoCustomersMessage(false); // Hide message if customers are found or loading is true
        }
    }, [loading, filteredCustomers]);

    return (
        <div style={{ padding: '20px' }}>
            <h2>All Registered Users</h2>

            {/* Search bar */}
            <input
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                //className="search-bar"
                style={{
                    width: "100%",
                    padding: '10px',
                    marginBottom: '20px',
                    fontSize: '16px',
                    borderRadius: '25px',
                    border: '1px solid #ddd',
                    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)'
                }}
            />

            {/* Filter buttons */}
            <div style={{
                display: "flex",
                gap: "10px",
                marginBottom: "20px"
            }}>
                {/* Tenant Filter */}
                <button
                    // className={`filter-btn ${showTenantOnly ? 'active' : ''}`}

                    onClick={() => setShowTenantOnly(!showTenantOnly)}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: showTenantOnly ? '#ff5a5f' : '#333',
                        color: 'white',
                        borderRadius: '20px',
                        border: '1px solid #eee',
                        cursor: 'pointer',
                        transition: 'background-color 0.3s ease',
                    }}
                >
                    {showTenantOnly ? 'Show All' : 'Show Tenants'}
                </button>

                {/* Building Filter
                <select
                    value={buildingFilter}
                    onChange={(e) => setBuildingFilter(e.target.value)}
                    className="filter-dropdown"
                >
                    <option value="">All Buildings</option>
                    {buildings.map(building => (
                        <option key={building} value={building}>
                            {building}
                        </option>
                    ))}
                </select> */}

                {/* Area Filter */}
                <select
                    value={areaFilter}
                    onChange={(e) => setAreaFilter(e.target.value)}
                    className="filter-dropdown"
                >
                    <option value="">All Areas</option>
                    {areas.map(area => (
                        <option key={area.AreaId} value={area.AreaName}>
                            {area.AreaName}
                        </option>
                    ))}
                </select>

                {/* Room Type Filter */}
                <select
                    value={roomTypeFilter}
                    onChange={(e) => setRoomTypeFilter(e.target.value)}
                    className="filter-dropdown"
                >
                    <option value="">All Room Types</option>
                    {roomTypes.map(type => (
                        <option key={type.RoomTypeId} value={type.RoomType}>
                            {type.RoomType}
                        </option>
                    ))}
                </select>


                <select
                    value={colorFilter}
                    onChange={(e) => setColorFilter(e.target.value)}
                    style={{
                        padding: '10px',
                        borderRadius: '8px',
                        border: '1px solid #ddd',
                        cursor: 'pointer',
                    }}
                >
                    <option value="">select color</option>
                    <option value="redFirst">Sort By: Paid </option>
                    <option value="greenFirst">Sort By: Unpaid</option>
                </select>
                {/* Sorting Button */}
                <button
                    style={{
                        padding: '10px 20px',
                        backgroundColor: '#333',
                        color: 'white',
                        borderRadius: '20px',
                        border: '1px solid #eee',
                        cursor: 'pointer'
                    }}
                    onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}
                >
                    Sort By Name: {sortOrder === 'asc' ? 'Ascending' : 'Descending'}
                </button>
            </div>
            {/* // customer-cards */}
            {/* Customer Cards */}
            <div style={{
                display: 'flex',
                flexWrap: 'wrap',
                gap: '20px'

            }}>
                {loading ? (
                    <ShimmerSimpleGallery card imageHeight={300} />
                ) :
                    filteredCustomers.length > 0 ? (
                        filteredCustomers.map(cus => (
                            <Link to={`/UserAccess/${cus.UserId}`}
                                key={cus.UserId}
                                style={{
                                    backgroundColor: getWalletDaysPaidFor(cus.UserId) <= -1 ? '#fd5563' : '#00fa3a', // Red for overdue, green for up-to-date
                                    padding: '14px',
                                    borderRadius: '12px',
                                    border: 'none',
                                    width: '320px',
                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                                    textDecoration: 'none', // Ensure the link looks like a card without default underline
                                    display: 'block',
                                }}>
                                <div key={cus.UserId} style={{
                                    backgroundColor: '#fff',
                                    padding: '20px',
                                    border: '1px solid #ddd',
                                    borderRadius: '8px',
                                    width: '300px',
                                    boxShadow: ' 0 4px 10px rgba(0, 0, 0, 0.1)',
                                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                    marginLeft: '-3px',
                                    marginTop: '12px'

                                }}>
                                    <h3 style={{
                                        fontSize: "1.2rem",
                                        marginBottom: '8px',
                                        color: '#333',
                                        fontWeight: '600'
                                    }}>ID #{cus.IdNumber}</h3>
                                    <p>
                                        Customer: <span>{cus.Name} {cus.Surname}</span>
                                    </p>
                                    <p>User ID: <span>{cus.UserId}</span></p>
                                    <p>Email: <span>{cus.Email}</span></p>
                                    <p>Contact No.: <span>{cus.PhoneNumber}</span></p>
                                    <p>Gender: <span>{cus.Gender}</span></p>
                                    {getTenantDetails(cus.UserId) && (
                                        <p>Building: <span>{getTenantDetails(cus.UserId).BuildingName}</span></p>
                                    )}
                                    {getWalletDaysPaidFor(cus.UserId) !== null && (
                                        <p
                                            style={{
                                                color: getWalletDaysPaidFor(cus.UserId) <= -1 ? '#fd5563' : '#00fa3a',
                                            }}
                                        >
                                            Days Paid For: {getWalletDaysPaidFor(cus.UserId)}
                                        </p>
                                    )}


                                </div>
                            </Link>
                        ))
                    ) : (
                        // // <>
                        // //     <ShimmerSimpleGallery card imageHeight={300} />
                        // //     <p>No customers found.</p>
                        // // </>
                        // <p>No customers found.</p>

                        <>
                            {showNoCustomersMessage ? (
                                <p>No customers found.</p>
                            ) : (
                                <ShimmerSimpleGallery card imageHeight={300} />
                            )}
                        </>

                    )}
            </div>

            {error && <div style={{ color: "red" }}>{error}</div>}
        </div >
    );
}

export default LandLord;
