import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthProvider';
import './LoginModal.css'; // Add your modal styling here

function LoginModal({clickedAction, onClose, onSuccess, date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();
    const { login } = useAuth();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const loginResponse = await fetch('https://backendjaes.passiontrials.co.za/loginn', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ Email: email, Password: password }),
            });

            const loginData = await loginResponse.json();

            if (loginResponse.ok) {
                login(loginData.userId, loginData.Role);

                // Close the modal
                onClose();

                // Trigger the success callback and navigate to Yoco Payment
                onSuccess();
                if (clickedAction === 'downloadPDF') {
                    onSuccess(); // Trigger PDF download
                } else if (clickedAction === 'pay'){                navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding, totalAmount, serviceFee, chosenPlan, nextPaymentDate } });
            }
            } else {
                alert(loginData.message || 'Failed to login');
            }
        } catch (error) {
            console.error('Login error:', error);
            alert('An error occurred during login');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-modal-overlay">
            <div className="login-modal-content">
                <h2>Login</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <label>Email</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Enter your email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Password</label>
                        <input
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            placeholder="Enter your password"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <button onClick={onClose}>Close</button>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Logging in...' : 'Login'}
                        </button>
                    </div>
                </form>

            </div>
        </div>
    );
}

export default LoginModal;
