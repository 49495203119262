import React, { useState, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';

const EventDetails = () => {
    const [eventDetails, setEventDetails] = useState([]);
    const [error, setError] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [isLoading, setIsLoading] = useState(false); // Add a loading state

    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const qrEmail = query.get('email');

    // Function to fetch event details (can be used for both search and QR code fetching)
    const fetchEventDetails = async (searchValue = '') => {
        setIsLoading(true);
        try {
            const query = new URLSearchParams({ search: searchValue });
            const response = await fetch(`https://backendjaes.passiontrials.co.za/event-details?${query.toString()}`);

            if (!response.ok) {
                throw new Error('Unable to fetch event details');
            }

            const data = await response.json();
            setEventDetails(data);
            setError(null);
        } catch (err) {
            setError('Unable to fetch event details. Please try again.');
            setEventDetails([]);
        } finally {
            setIsLoading(false);
        }
    };

    // Automatically fetch event details if the page is loaded via a QR code or on initial load
    useEffect(() => {
        fetchEventDetails(qrEmail || ''); // Fetch all if no email is present
    }, [qrEmail]);

    // Debounced function to avoid too many API calls while typing
    const debounceFetch = useCallback(
        (value) => {
            const delayDebounceFn = setTimeout(() => {
                fetchEventDetails(value);
            }, 300); // Adjust the debounce time as needed

            return () => clearTimeout(delayDebounceFn);
        },
        [fetchEventDetails]
    );

    // Handle input change and debounce the fetch call
    const handleInputChange = (e) => {
        const value = e.target.value;
        setSearchTerm(value);
        debounceFetch(value);
    };

    // Handle marking the person as arrived
    const handleArrived = async (eventId) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/mark-arrived`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ eventId }),
            });

            if (!response.ok) {
                throw new Error('Error updating arrival status');
            }

            fetchEventDetails(searchTerm || qrEmail);
        } catch (error) {
            console.error('Error marking as arrived:', error);
        }
    };

    return (
        <div style={{ padding: '10px' }}>
            <h1>Search Event Details</h1>
            <input
                type="text"
                placeholder="Search by Full Name or Email"
                value={searchTerm}
                onChange={handleInputChange}
            />
            {isLoading && <p>Loading...</p>}

            {error && <p>{error}</p>}

            {eventDetails.length > 0 && (
                <div>
                    <h2>Event Results</h2>
                    <table border="1" style={{ width: '100%', marginTop: '10px' }}>
                        <thead>
                            <tr>
                                <th>Full Name</th>
                                <th>Phone Number</th>
                                <th>Email</th>
                                <th>Has Arrived</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {eventDetails.map((event) => (
                                <tr key={event.id}>
                                    <td>{event.full_name}</td>
                                    <td>{event.phone_number}</td>
                                    <td>{event.email}</td>
                                    <td>{event.has_arrived ? 'Yes' : 'No'}</td>
                                    <td>
                                        <button
                                            onClick={() => handleArrived(event.id)}
                                            disabled={event.has_arrived}
                                        >
                                            {event.has_arrived ? 'Already Arrived' : 'Mark as Arrived'}
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            )}

            {!eventDetails.length && !error && !isLoading && searchTerm && <p>No results found.</p>}
        </div>
    );
};

export default EventDetails;
