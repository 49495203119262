import React, { useState } from "react";
import './EventTicket.css'; // Import the CSS file for styling
import ticket from './Ticket Event2.png';

function Event_Ticket_Student() {
  const [fullName, setFullName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [confirmEmail, setConfirmEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if emails match
    if (email !== confirmEmail) {
      setErrorMessage("Email addresses do not match.");
      return;
    }

    // Clear error message
    setErrorMessage("");

    // Save user information to localStorage before redirecting to payment
    localStorage.setItem("userInfo", JSON.stringify({ fullName, phoneNumber, email }));

    // Proceed to payment with Yoco for R15 (student price)
    try {
      const amountInCents = 1500; // R15 = 1500 cents for students
      const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay/ticket', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          amount: amountInCents,
          currency: 'ZAR',
          successUrl: `https://jaesstudenthomes.co.za/ThankYou`, // Redirect to ThankYou page
          cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel',
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Error: ${response.status} - ${errorText}`);
      }

      const data = await response.json();
      console.log('Payment response:', data);

      if (data.redirectUrl) {
        window.location.href = data.redirectUrl;
      }
    } catch (error) {
      console.error('Payment error:', error);
    }
  };

  return (
    <div className="event-ticket">
      <img width={'100%'} src={ticket} alt="Event Ticket" />
      <hr />
      <form onSubmit={handleSubmit}>
        {/* Group Full Name and Phone Number */}
        <div className="form-group-inline">
          <div className="form-group">
            <input
              type="text"
              className="full-name"
              placeholder="Full Name"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="tel"
              placeholder="Phone Number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
            />
          </div>
        </div>

        {/* Group Email and Confirm Email */}
        <div className="form-group-inline">
          <div className="form-group">
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              placeholder="Confirm Email"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              required
            />
          </div>
        </div>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit">Pay R15 & Register</button>
      </form>
    </div>
  );
}

export default Event_Ticket_Student;
