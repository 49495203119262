import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function CheckOut() {
    const location = useLocation();
    const navigate = useNavigate();
    const [info, setInfo] = useState(null);
    const [trackInfo, setTrackInfo] = useState(null);

    useEffect(() => {
        const getLatestRecord = async () => {
            const queryParams = new URLSearchParams(location.search);
            const userId = queryParams.get('payid');

            if (userId) {
                console.log('User ID is:', userId);

                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_apartment_tb/${userId}`, {
                        headers: { 'Content-Type': 'application/json' }
                    });

                    if (!response.ok) {
                        console.log('Failed to fetch latest pay record');
                        return;
                    }

                    const data = await response.json();
                    if (data) {
                        setInfo(data);
                        console.log('The info:', data);

                        // const track = await fetch(`https://backendjaes.passiontrials.co.za/latest_payment_tracking/${userId}`, {
                        //     headers: { 'Content-Type': 'application/json' }
                        // });

                        // if (!track.ok) {
                        //     console.log('Failed to fetch latest pay track record');
                        //     return;
                        // }

                        // const trackingData = await track.json();
                        // if (trackingData) {
                        //     setTrackInfo(trackingData);
                        //     console.log('The tracking info:', trackingData);
                        //     status(data, trackingData, userId);
                        // }
                        status(data[0], 0, userId);
                    }
                } catch (error) {
                    console.error('Error fetching latest payment record:', error);
                }
            } else {
                console.log('User ID could not be passed');
            }
        };

        getLatestRecord();
    }, [location.search]);

    const handleReturnHome = () => {
        navigate('/');
    };

    const status = async (data, trackingData, user) => {
        try {
            console.log('User ID could not be passed', data, data[0]);

            // Remove apartment from the user
            const removeApartmentFromUser = await fetch(`https://backendjaes.passiontrials.co.za/delete-user-apartment/${user}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!removeApartmentFromUser.ok) {
                if (removeApartmentFromUser.status !== 404) { // Ignore 404 errors as this might be expected
                    const errorText = await removeApartmentFromUser.text();
                    throw new Error(`Failed to delete user apartment record: ${errorText}`);
                } else {
                    console.log('No apartment record found for the user, which is expected.');
                }
            } else {
                console.log('Apartment has been removed from user successfully');
            }

            // Increment the Capacity2 for the ApartmentId
            const incrementCapacity = await fetch(`https://backendjaes.passiontrials.co.za/increment-capacity/${data.ApartmentId}`, {
                method: 'PUT',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!incrementCapacity.ok) {
                if (incrementCapacity.status !== 404) { // Ignore 404 errors as this might be expected
                    const errorText = await incrementCapacity.text();
                    throw new Error(`Failed to increment apartment capacity: ${errorText}`);
                } else {
                    console.log('No apartment record found to increment capacity, which may be expected.');
                }
            } else {
                console.log('Apartment capacity has been incremented successfully');
            }

            // Wipe wallet records
            const wipeWallet = await fetch(`https://backendjaes.passiontrials.co.za/wallet-wipe/${user}`, {
                method: 'DELETE',
                headers: { 'Content-Type': 'application/json' },
            });

            if (!wipeWallet.ok) {
                if (wipeWallet.status !== 404) { // Ignore 404 errors as this might be expected
                    const errorText = await wipeWallet.text();
                    throw new Error(`Failed to wipe user wallet data: ${errorText}`);
                } else {
                    console.log('No wallet data found to delete, which is expected.');
                }
            } else {
                console.log('User wallet data has been wiped out successfully');
            }

            setTimeout(() => {
                handleReturnHome();
            }, 4000);

        } catch (error) {
            console.log('An error occurred while updating record');
            alert(`Error processing your checkout: ${error.message}`);
        }
    };


    return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
            <h1>Checkout Notice</h1>
            <p>Your final payment has been processed successfully!</p>
            <p>Thank you for being with us. We hope to see you again in the future.</p>
            <button onClick={handleReturnHome} style={{ padding: '10px 20px', fontSize: '16px', cursor: 'pointer', backgroundColor: '#FF5733', color: 'white', border: 'none', borderRadius: '5px' }}>
                Confirm Checkout
            </button>
        </div>
    );
}

export default CheckOut;  
