import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthProvider';
import jsPDF from 'jspdf';

const ServiceBoxAdmin = () => {
    const { isLoggedIn } = useAuth();
    const buildingName = "Vingamedia";  // Hardcoded building name
    const [serviceBoxData, setServiceBoxData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    // Fetch ServiceBox data
    // Fetch ServiceBox data
useEffect(() => {
    if (isLoggedIn) {
        const fetchServiceBoxData = async () => {
            try {
                const url = `https://backendjaes.passiontrials.co.za/api/servicebox`; // Fetch all buildings

                const response = await fetch(url);
                const data = await response.json();
                setServiceBoxData(data);
            } catch (error) {
                console.error('Error fetching ServiceBox data:', error);
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchServiceBoxData();
    }
}, [isLoggedIn]);


    // Function to format date to "02 July 2024"
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
            day: '2-digit',
            month: 'long',
            year: 'numeric'
        }).format(date);
    };

    // Function to mark service as Processing
    const handleMarkAsProcessing = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/api/serviceboxchange/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ active: 'Processing' }),
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }

            setServiceBoxData((prevData) =>
                prevData.map((entry) => (entry.id === id ? { ...entry, active: 'Processing' } : entry))
            );
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    // Function to mark service as Completed
    const handleMarkAsCompleted = async (id) => {
        try {
            const response = await fetch(`https://backendjaes.passiontrials.co.za/api/serviceboxchange/${id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ active: 'Complete' }),
            });

            if (!response.ok) {
                throw new Error('Failed to update status');
            }

            setServiceBoxData((prevData) =>
                prevData.map((entry) => (entry.id === id ? { ...entry, active: 'Complete' } : entry))
            );
        } catch (error) {
            console.error('Error updating status:', error);
        }
    };

    // Function to generate PDF
    const generatePDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        doc.text('Service Box Data', 10, 10);
        let yPos = 20;

        serviceBoxData.forEach((entry, index) => {
            if (yPos > 270) {
                doc.addPage();
                yPos = 20;
            }

            doc.text(`Name: ${entry.name || 'N/A'} ${entry.surname || 'N/A'}`, 10, yPos);
            doc.text(`Level: ${entry.level}, Group: ${entry.group}, Category: ${entry.category}`, 10, yPos + 5);
            doc.text(`Item: ${entry.item}, Status: ${entry.active}, Building: ${entry.building_name}`, 10, yPos + 10);
            doc.text(`Description: ${entry.description}`, 10, yPos + 15);
            doc.text(`Date: ${formatDate(entry.created_at)}`, 10, yPos + 20);
            yPos += 40;
        });

        doc.save('service-box-data.pdf');
    };

    if (loading) return <p>Loading service box data...</p>;
    if (error) return <p>Error: {error}</p>;

    return (
        <div style={styles.container}>
            <h2>Service Box Data</h2>
            {serviceBoxData.length > 0 ? (
                <>
                    <button onClick={generatePDF} style={styles.button}>Download PDF</button>
                    <table style={styles.table}>
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Level</th>
                                <th>Group</th>
                                <th>Category</th>
                                <th>Item</th>
                                <th>Status</th>
                                <th>Building</th>
                                <th>Description</th>
                                <th>Date</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {serviceBoxData.map((entry, index) => (
                                <tr key={index}>
                                    <td>{`${entry.name || 'N/A'} ${entry.surname || 'N/A'}`}</td>
                                    <td>{entry.level}</td>
                                    <td>{entry.group}</td>
                                    <td>{entry.category}</td>
                                    <td>{entry.item}</td>
                                    <td>{entry.active}</td>
                                    <td>{entry.building_name}</td>
                                    <td>{entry.description}</td>
                                    <td>{formatDate(entry.created_at)}</td>
                                    <td>
                                        {entry.active !== 'Complete' && (
                                            <>
                                                {entry.active !== 'Processing' && (
                                                    <button
                                                        onClick={() => handleMarkAsProcessing(entry.id)}
                                                        style={styles.buttonProcessing}
                                                    >
                                                        Process
                                                    </button>
                                                )}
                                                <button
                                                    onClick={() => handleMarkAsCompleted(entry.id)}
                                                    style={styles.button}
                                                >
                                                    Completed
                                                </button>
                                            </>
                                        )}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            ) : (
                <p>No service box data found.</p>
            )}
        </div>
    );
};

const styles = {
    container: {
        padding: '20px',
        width: '100%',
        marginTop: '10px',
    },
    table: {
        width: '100%',
        borderCollapse: 'collapse',
    },
    button: {
        padding: '5px 10px',
        backgroundColor: '#ff5a5f',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        marginBottom: '10px',
    },
    buttonProcessing: {
        padding: '5px 10px',
        backgroundColor: '#ffa500',
        color: '#fff',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
        fontSize: '14px',
        marginRight: '5px',
    },
};

export default ServiceBoxAdmin;
