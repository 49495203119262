import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

function WalletAdmin() {
    const location = useLocation();
    const navigate = useNavigate();
    const [status, setStatus] = useState(null);
    const [error, setError] = useState(null);

    const queryParams = new URLSearchParams(location.search);
    const apartmentId = queryParams.get('apartmentId');
    console.log('DumaApartment ID:', apartmentId);
    
    const apartmentPrice = parseFloat(queryParams.get('apartmentPrice'));
    console.log('DumaApartment Price:', apartmentPrice);
    
    const amount = parseFloat(queryParams.get('amount'));
    console.log('DumaAmount:', amount);
    
    const userId = parseFloat(queryParams.get('user'));
    console.log('DumaUser ID:', userId);
    

    useEffect(() => {
        // Redirect if the payment has already been processed
       

        if (!userId || !apartmentId || !apartmentPrice) {
            setError('Missing necessary data.');
            return;
        }

        const getDaysInCurrentMonth = () => {
            const now = new Date();
            return new Date(now.getFullYear(), now.getMonth() + 1, 0).getDate();
        };

        const getMonthYear = () => {
            const now = new Date();
            return `${now.toLocaleString('en-US', { month: 'long' })} ${now.getFullYear()}`;
        };

        const calculateDailyRateAndDaysPaidFor = () => {
            const daysInMonth = getDaysInCurrentMonth();
            const dailyRate = apartmentPrice / daysInMonth;
            const daysPaidFor = Math.floor(amount / dailyRate);
        
            const now = new Date();
            const formatDate = (date) => {
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const day = String(date.getDate()).padStart(2, '0');
                const hours = String(date.getHours()).padStart(2, '0');
                const minutes = String(date.getMinutes()).padStart(2, '0');
                const seconds = String(date.getSeconds()).padStart(2, '0');
                return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            };
        
            const paymentDate = formatDate(now);
            const monthYear = getMonthYear();

            return { dailyRate, daysPaidFor, paymentDate, monthYear };
        };

        const insertIntoWallet = async () => {
            try {
                const { dailyRate, daysPaidFor, paymentDate, monthYear } = calculateDailyRateAndDaysPaidFor();
                console.log("Sending data:", { userId, apartmentId, paymentDate, amount, dailyRate, daysPaidFor, monthYear });
                const response = await fetch('https://backendjaes.passiontrials.co.za/wallet/update', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        UserId: userId,
                        ApartmentId: apartmentId,
                        PaymentDate: paymentDate,
                        AmountPaid: amount,
                        DailyRate: dailyRate.toFixed(2),
                        DaysPaidFor: daysPaidFor,
                        MonthYear: monthYear,
                        PaymentMethod: "Yoco"
                    }),
                });
            
                const data = await response.json();
                if (!response.ok) {
                    console.error("Server error:", data.error);
                    throw new Error(data.error || 'Failed to update wallet');
                }
            
                console.log("Payment processed:", data);
                setStatus('Payment successfully added to your wallet.');
                
                    navigate(`/UserAccess/${userId}`, { replace: true });
               
            
            } catch (err) {
                console.error("Error updating wallet:", err);
                setError('Failed to process your wallet entry. Please try again.');
            }
            
        };

        insertIntoWallet();
    }, [userId, apartmentId, apartmentPrice, amount, navigate]);

    if (error) {
        return <div style={{ marginTop: '149px' }}>Error: {error}</div>;
    }

    return (
        <div style={{ marginTop: '149px' }}>
            <h1>{status || 'Processing your payment...'}</h1>
            <p>Please wait, you will be redirected shortly.</p>
        </div>
    );
}

export default WalletAdmin;
