import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Customers.css';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';

function Customers() {
    const [tenantDetails, setTenantDetails] = useState([]);
    const [walletData, setWalletData] = useState({});
    const [searchTerm, setSearchTerm] = useState('');
    const [sortConfig, setSortConfig] = useState({ key: 'Name', direction: 'asc' });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showNoCustomersMessage, setShowNoCustomersMessage] = useState(false);

    useEffect(() => {
        setLoading(true);
        fetch('https://backendjaes.passiontrials.co.za/new_all_user_full_details')
            .then(res => res.json())
            .then(data => {
                setTenantDetails(data);
                setLoading(false);
            })
            .catch(err => {
                console.error('Error fetching tenant details:', err);
                setError('Failed to fetch tenant details');
            });
    }, []);

    useEffect(() => {
        if (tenantDetails.length > 0) {
            tenantDetails.forEach(user => fetchWalletData(user.UserId, user.ApartmentId));
        }
    }, [tenantDetails]);

    const fetchWalletData = (userId, apartmentId) => {
        fetch(`https://backendjaes.passiontrials.co.za/wallet/${userId}/${apartmentId}`)
            .then(res => res.json())
            .then(data => {
                setWalletData(prev => ({
                    ...prev,
                    [userId]: data.length > 0 ? data[0] : null
                }));
            })
            .catch(err => console.error(`Error fetching wallet data for user ${userId}:`, err));
    };

    const getWalletDaysPaidFor = (userId) => {
        const wallet = walletData[userId];
        return wallet ? wallet.DaysPaidFor : null;
    };

    const getColorCode = (userId) => {
        const daysPaidFor = getWalletDaysPaidFor(userId);
        return daysPaidFor <= -1 ? 'red' : 'green';
    };

    const handleSort = (key) => {
        setSortConfig((prevConfig) => ({
            key,
            direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc',
        }));
    };

    const sortedCustomers = [...tenantDetails]
        .filter(user =>
            `${user.Name} ${user.Surname} ${user.IdNumber} ${user.Email} ${user.PhoneNumber}`
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
        )
        .sort((a, b) => {
            const direction = sortConfig.direction === 'asc' ? 1 : -1;
            if (sortConfig.key === 'DaysPaidFor') {
                const daysA = getWalletDaysPaidFor(a.UserId) || 0;
                const daysB = getWalletDaysPaidFor(b.UserId) || 0;
                return direction * (daysA - daysB);
            }
            return direction * (a[sortConfig.key].localeCompare(b[sortConfig.key]));
        });

    useEffect(() => {
        if (!loading && sortedCustomers.length === 0) {
            const timer = setTimeout(() => setShowNoCustomersMessage(true), 3000);
            return () => clearTimeout(timer);
        } else {
            setShowNoCustomersMessage(false);
        }
    }, [loading, sortedCustomers]);

    return (
        <div className="banana-customers-container">
            <h2>All Registered Users</h2>
            <input
                type="text"
                placeholder="Search by name..."
                value={searchTerm}
                onChange={e => setSearchTerm(e.target.value)}
                className="banana-search-bar"
            />

            <div className="banana-table-container">
                {loading ? (
                    <ShimmerSimpleGallery card imageHeight={300} />
                ) : sortedCustomers.length > 0 ? (
                    <table className="banana-customer-table">
                        <thead>
                            <tr>
                                <th onClick={() => handleSort('IdNumber')}>ID Number</th>
                                <th onClick={() => handleSort('Name')}>Name</th>
                                <th >User ID</th>
                                <th onClick={() => handleSort('Email')}>Email</th>
                                <th onClick={() => handleSort('PhoneNumber')}>Phone Number</th>
                                <th onClick={() => handleSort('Gender')}>Gender</th>
                                <th onClick={() => handleSort('BuildingName')}>Building</th>
                                <th onClick={() => handleSort('DaysPaidFor')}>Days Paid For</th>
                            </tr>
                        </thead>
                        <tbody>
                            {sortedCustomers.map(user => (
                                <tr
                                    key={user.UserId}
                                    onClick={() => window.location.href = `/UserAccess/${user.UserId}`}
                                    className={getWalletDaysPaidFor(user.UserId) <= -1 ? 'moon-red' : 'moon-green'}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <td>{user.IdNumber}</td>
                                    <td>{user.Name} {user.Surname}</td>
                                    <td>{user.UserId}</td>
                                    <td>{user.Email}</td>
                                    <td>{user.PhoneNumber}</td>
                                    <td>{user.Gender}</td>
                                    <td>{user.BuildingName || 'No Building Assigned'}</td>
                                    <td className={`banana-wallet-status ${getWalletDaysPaidFor(user.UserId) <= -1 ? 'moon-red' : 'moon-green'}`}>
                                        {getWalletDaysPaidFor(user.UserId) !== null ? getWalletDaysPaidFor(user.UserId) : 'N/A'}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    showNoCustomersMessage && <p>No customers found.</p>
                )}
            </div>

            {error && <div className="banana-error">{error}</div>}
        </div>
    );
}

export default Customers;
