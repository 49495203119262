// FullPageBooking.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './reserveNew.css';

const ReserveNew = ({ handleClose, handleSubmit }) => {
  const [step, setStep] = useState(1);
  const [transport, setTransport] = useState('');
  const nextStep = () => setStep((prevStep) => Math.min(prevStep + 1, 4));
  const prevStep = () => setStep((prevStep) => Math.max(prevStep - 1, 1));
  const navigate = useNavigate();
  const handleTransportChange = (event) => {
    setTransport(event.target.value);
  };
 


  return (
    <div className="full-page-container">
      <div className="full-page-content">
        <span className="close-btn" onClick={handleClose}>×</span>
        
        <h1 class="subsection-module__title">Hi, let's complete your booking</h1>

        {/* Progress Bar */}
        <div className="progress-container">
          {[1, 2, 3, 4].map((bar) => (
            <div key={bar} className={`progress-bar ${step >= bar ? 'filled' : ''}`} />
          ))}
        </div>

        {/* Step Content */}
        {step === 1 && (
          <div>
        <h2 class="subsection-module__title">Step 1/4: Personal Info</h2>
        

        <div className="amber-form-group2">

   
    </div>
            <div className="form-grid">
              <div className="amber-form-group">
                <label>Home Address <span style={{ color: 'red' }}>*</span></label>
                <input type="text" placeholder="Enter your home address" required />
              </div>
              <div className="amber-form-group">
                <label>Postal Code <span style={{ color: 'red' }}>*</span></label>
                <input type="text" placeholder="Enter your postal code" required />
              </div>
              <div className="amber-form-group">
                <label>State/Province <span style={{ color: 'red' }}>*</span></label>
                <input type="text" placeholder="Enter your state/province" required />
              </div>
              <div className="amber-form-group">
                <label>City <span style={{ color: 'red' }}>*</span></label>
                <input type="text" placeholder="Enter your city" required />
              </div>
              <div className="form-grid">
              <div className="amber-form-group">
                <label>Country <span style={{ color: 'red' }}>*</span></label>
                <select required><option value="">Select Country</option></select>
              </div>
              <div className="amber-form-group">
                <label>Nationality <span style={{ color: 'red' }}>*</span></label>
                <select required><option value="">Select Nationality</option></select>
              </div>
            </div>
            <button className="btn" onClick={nextStep}>Next</button>
          </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2 class="subsection-module__title" >Step 2/4: University Info</h2>
            <div className="form-grid">
              <div className="amber-form-group">
                <label>Course Name <span style={{ color: 'red' }}>*</span></label>
                <input type="text" placeholder="Enter course name" required />
              </div>
              <div className="amber-form-group">
                <label>Past Education <span style={{ color: 'red' }}>*</span></label>
                <input type="text" placeholder="Enter past institution" required />
              </div>
              <div className="amber-form-group">
                <label>Course Start Date <span style={{ color: 'red' }}>*</span></label>
                <input type="date" required />
              </div>
              <div className="amber-form-group">
                <label>Course End Date <span style={{ color: 'red' }}>*</span></label>
                <input type="date" required />
              </div>
              <div className="amber-form-group">
                <label>Year of Study <span style={{ color: 'red' }}>*</span></label>
                <select required><option value="">Select Year of Study</option></select>
              </div>
              <div className="amber-form-group">
                <label>Past Attended Course <span style={{ color: 'red' }}>*</span></label>
                <select required><option value="">Select Past Course</option></select>
              </div>
            </div>
            <button className="btn" onClick={prevStep}>Back</button>
            <button className="btn" onClick={nextStep}>Next</button>
          </div>
        )}
  {step === 3 && (
          <div>
            <h2 class="subsection-module__title">Step 3/4: Transport Option</h2>
            <div className="transport-options" style={{ marginTop: '20px' }}>
              <div
                className={`transport-option-wrapper ${transport === 'yes' ? 'selected' : ''}`}
                onClick={() => setTransport('yes')}
              >
                <input
                  type="radio"
                  name="transport"
                  id="transportYes"
                  value="yes"
                  checked={transport === 'yes'}
                  onChange={handleTransportChange}
                  className="radio-button"
                />
                <label htmlFor="transportYes" className="transport-label">
                  <div className="transport-option">
                    <p className="subsection-module__title">Yes, I need transport</p>
                    <p className="subsection-module__subtitle">
                      <span className="discount-highlight">SAVE 45%</span> R250 <span className="strikethrough">R450</span><br />
                      Enjoy safe, reliable transport to and from your institution—and save 45%! Lock in this special rate of just R250 (normally R450) when you pay before January 15. Don’t miss out—this offer ends soon, so secure your spot today!
                    </p>
                  </div>
                </label>
              </div>
              <div
                className={`transport-option-wrapper ${transport === 'no' ? 'selected' : ''}`}
                onClick={() => setTransport('no')}
              >
                <input
                  type="radio"
                  name="transport"
                  id="transportNo"
                  value="no"
                  checked={transport === 'no'}
                  onChange={handleTransportChange}
                  className="radio-button"
                />
                <label htmlFor="transportNo" className="transport-label">
                  <div className="transport-option">
                    <p className="subsection-module__title">No, I do NOT need transport</p>
                    <p className="subsection-module__subtitle">
                      Choose this option if you have your own transport arrangements or prefer to manage your travel independently.
                    </p>
                  </div>
                </label>
              </div>
            </div>
            <button className="btn" onClick={prevStep}>Back</button>
            <button className="btn" onClick={nextStep} disabled={!transport}>Next</button>
          </div>
        )}

        {step === 4 && (
          <div>
            <h2 class="subsection-module__title">Step 4/4: Confirmation</h2>
            <p>Thank you for filling out the information! When you're ready, click "Submit" to complete your booking.</p>
            <button className="btn" onClick={prevStep}>Back</button>
            <button className="btn" onClick={navigate('/invoice')}>Submit</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReserveNew;
