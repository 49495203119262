import React, { useState } from 'react';
import { useAuth } from './AuthProvider';
import { useNavigate, useParams } from 'react-router-dom';
import './userAccessedProfile.css'
import lock from './lockjaes.svg';
import locks from './lockwithnoshildjaes.svg';
import lockss from './eyes.svg';

function AccessedProfile() {

    const [loading, setLoading] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [expandedBio, setExpandedBio] = useState(false);
    const { userId } = useParams();
    const role = 'User';

    const [info, setInfo] = useState(null);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [userInfo, setUserInfo] = useState({
        UserId: '',
        ApartmentId: '',
        Name: '',
        Surname: '',
        IdNumber: '',
        Gender: '',
        Email: '',
        PhoneNumber: '',
        FaceImage: '',
        ConfirmedUser: '',
        Role: '',
        ID_Image: '',
        DateOfBirth: '',
    });

    // useNavigate hook to go back in history
    const navigate = useNavigate();
    const [error, setError] = useState('');
    let faceImage = null;
    const [successMessage, setSuccessMessage] = useState('');
    const [idImagePreview, setIdImagePreview] = useState(null);
    const [faceImagePreview, setFaceImagePreview] = useState(null);

    const handleTabChange = (index) => {
        setActiveTab(index);
    };

    const toggleBio = () => {
        setExpandedBio(!expandedBio);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
    
        if (password !== confirmPassword) {
            setError("Passwords do not match.");
            return;
        }
        setLoading(true); // Show loader
        try {
            // Logging for debugging
            console.log("User Info to update:", userInfo);
            console.log("User ID:", userInfo.UserId);
    
            const formData = new FormData();
            formData.append('Name', userInfo.Name);
            formData.append('Surname', userInfo.Surname);
            formData.append('Email', userInfo.Email);
            formData.append('PhoneNumber', userInfo.PhoneNumber);
            formData.append('IdNumber', userInfo.IdNumber);
    
            if (userInfo.FaceImage) {
                formData.append('FaceImage', userInfo.FaceImage);
            }
            if (userInfo.ID_Image) {
                formData.append('ID_Image', userInfo.ID_Image);
            }
            if (password) {
                formData.append('Password', password);
            }
    
            const response = await fetch(`https://backendjaes.passiontrials.co.za/updateUser/${userInfo.UserId}`, {
                method: 'PUT',
                body: formData,
            });
    
            const data = await response.json();
            console.log("API Response:", data); // For debugging the response
    
            if (response.ok) {
                setSuccessMessage('Information updated successfully.');
                setError('');
            } else {
                setError(data.message || 'Failed to update information.');
            }
    
        } catch (error) {
            console.error('Error updating user details', error);
            setError('An error occurred while updating information.');
        }
    
        // Clear messages after a delay
        clearMessagesAfterTimeout();
    };
    
    const clearMessagesAfterTimeout = () => {
        setTimeout(() => {
            setError('');
            setSuccessMessage('');
        }, 3000); // Clears the messages after 3 seconds (3000 milliseconds)
    };

    React.useEffect(() => {
        if (userId) {


            // fetch(`https://backendjaes.passiontrials.co.za/admin_tb/${userId}`, {
            //     headers: { 'Content-Type': 'application/json' }
            // })
            //     .then(response => response.json())
            //     .then(data => {
            //         if (data) {
            //             console.log("Pre insertion", data, `this is the userId ${userId}`);
            //             setInfo(data);
            //             console.log("Post insertion", data);
            //         }
            //         else {
            //             console.log('No admin data found');
            //         }
            //     })
            //     .catch(error => {
            //         console.error('Error', error);
            //         setError(error.message);
            //     });

            fetch(`https://backendjaes.passiontrials.co.za/profile/${userId}/${role}`, {
                headers: { 'Content-Type': 'application/json' }
            }).then(res => res.json())
                .then(data => {
                    if (data) {

                        setInfo(data[0]);
                        setUserInfo(data[0]);
                        setFaceImagePreview(data[0]?.FaceImage);
                        setIdImagePreview(data[0]?.ID_Image);
                        console.log(data);

                    }
                    else {
                        console.log('No profile was found');
                    }
                })
                .catch(error => {
                    console.error('Error', error);
                    setError(error.message);
                })



        }
    }, [userId, role]);
    faceImage = info?.FaceImage || "https://picsum.photos/200/300?random=30";

    const handleChange = (e) => {
        const { name, value } = e.target;

        // If the field is password, handle it separately
        if (name === 'Password') {
            setPassword(value);
            setShowConfirmPassword(value.length > 0);
        } else if (name === 'ConfirmPassword') {
            setConfirmPassword(value);
        } else {
            setUserInfo({
                ...userInfo,
                [name]: value,
            });
        }
    };

    const handleImageChange = (e) => {
        const { name, files } = e.target;
        const file = files[0];
        if (name === 'FaceImage') {
            setFaceImagePreview(URL.createObjectURL(file));
        } else if (name === 'ID_Image') {
            setIdImagePreview(URL.createObjectURL(file));
        }
        setUserInfo({
            ...userInfo,
            [name]: file, // Store the selected file in state
        });
    };
    // Function to mask the email
    const maskEmail = (email) => {
        if (!email) return ''; // If the email is not provided, return an empty string

        const [localPart, domain] = email.split('@');

        if (localPart.length <= 2) {
            return `${localPart}***@${domain}`;
        }

        return `${localPart[0]}***${localPart[localPart.length - 1]}@${domain}`;
    };
    return (
        <>
            <div class="containerssss">

                <div class="info-section">  <form onSubmit={handleUpdate}>   <button
                    onClick={() => navigate(-1)}
                    style={{ marginBottom: '20px', backgroundColor: '#ff5a5f', color: 'white', padding: '10px 20px', border: 'none', borderRadius: '4px', cursor: 'pointer', width: '30%' }}>
                    Back
                </button>
                    <h2>Student Personal info</h2>
                    <div class="info-item">
                        <label style={{ color: 'red' }}>Legal name</label>
                        <span>{info?.AdminName || info?.Name} {info?.AdminSurname || info?.Surname}</span>
                        <div class="input-group">
                            <input
                                type="text"
                                name="Name"
                                value={userInfo.Name}
                                onChange={handleChange}
                                required
                                placeholder="Name"
                                class="input-field"
                            />
                            <input
                                type="text"
                                name="Surname"
                                value={userInfo.Surname}
                                onChange={handleChange}
                                required
                                placeholder="Last Name" class="input-field"
                            />

                        </div>
                        <a style={{ opacity: '0' }}>Add</a>
                    </div>

                    <div class="info-item">
                        <label style={{ color: 'red' }}>Phone number</label>
                        <input
                            type="text"
                            name="PhoneNumber"
                            value={userInfo.PhoneNumber}
                            onChange={handleChange}
                            required
                            class="input-field"
                        />
                        <a style={{ opacity: '0' }}>Add</a>
                    </div>
                    <div class="info-item">
    <label style={{ color: 'red' }}>Email address</label>
    <input
        type="email"
        name="Email"
        value={userInfo.Email}
        onChange={handleChange}
        required
        class="input-field"
        placeholder="Enter email address"
    />
    <a style={{ opacity: '0' }}>Add</a>
</div>

<div class="info-item">
    <label style={{ color: 'red' }}>Gender</label>
    <input
        type="text"
        name="Gender"
        value={userInfo.Gender}
        onChange={handleChange}
        required
        class="input-field"
        placeholder="Enter gender"
    />
    <a style={{ opacity: '0' }}>Add</a>
</div>

<div class="info-item">
    <label style={{ color: 'red' }}>Government ID number</label>
    <input
        type="text"
        name="IdNumber"
        value={userInfo.IdNumber}
        onChange={handleChange}
        required
        class="input-field"
        placeholder="Enter ID number"
    />
    <a style={{ opacity: '0' }}>Add</a>
</div>


                    <div class="info-item">
                        <label style={{ color: 'red' }}>Face Image:</label>
                        <div class="file-input-group">
                            {/* if image exist remove button  */}
                            {faceImagePreview && (
                                <img
                                    src={`https://backendjaes.passiontrials.co.za/src${faceImagePreview}`}
                                    alt="Face Preview"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                            )}

                            <input
                                type="file"
                                name="FaceImage"
                                accept="image/*"

                                onChange={handleImageChange} class="file-input" id="file-upload"
                            />  <label for="file-upload" class="file-label">Choose File</label>
                            <span class="file-name">No file chosen</span>  </div>
                    </div>

                    <div class="info-item">
                        <label>ID Image:</label>
                        <div>
                            {idImagePreview && (
                                <img
                                    src={`https://backendjaes.passiontrials.co.za/src${idImagePreview}`}
                                    alt="ID Preview"
                                    style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                                />
                            )}
                        </div>
                        <input
                            type="file"
                            name="ID_Image"
                            accept="image/*"
                            onChange={handleImageChange}

                        />
                    </div>




                    <div class="info-item">
                        <label style={{ color: 'red' }}>New password:</label>
                        <input
                            type="password"
                            name="Password"
                            value={password}
                            onChange={handleChange}
                            placeholder="New password"
                            class="input-field"
                            autoComplete='off'

                        />

                        <label style={{ color: 'red' }}>Confirm Password:</label>
                        <input
                            type="password"
                            name="ConfirmPassword"
                            value={confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm new password"
                            class="input-field"
                        />
                    </div>
                    <button 
    type="submit" 
    style={{ 
        marginBottom: '20px', 
        backgroundColor: '#ff5a5f', 
        color: 'white', 
        padding: '10px 20px', 
        border: 'none', 
        borderRadius: '4px', 
        cursor: 'pointer', 
        width: '30%' 
    }}
    disabled={loading} // Disable the button when loading
>
    {loading ? 'Updated Successfully' : 'Update Information'} {/* Show loader or text */}
</button>
                </form>
                </div>



                <div class="help-section">
                    <div class="help-box">

                        <div class="help-text"> <img src={lock} alt="Help Image 1" class="help-image" />
                            <h3>Tenant info shown here?</h3>
                            <p>Please confirm before updating tenant details</p>
                        </div>
                    </div>
                    <hr />
                    <div class="help-box">

                        <div class="help-text">
                            <img src={locks} alt="Help Image 2" class="help-image" />
                            <h3>Which details can admin edited?</h3>
                            <p>As Admin you can edit every tenant details</p>
                        </div>
                    </div>
                    <hr />
                    <div class="help-box">

                        <div class="help-text">
                            <img src={lockss} alt="Help Image 3" class="help-image" />
                            <h3>What info is shared with others?</h3>
                            <p>Admin should not share tenant information with third party...</p>
                        </div>
                    </div>
                </div>
            </div>

        </>

    );
}

export default AccessedProfile;
