import { useState, useRef } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';
import './conactForm.css'; // Keeping the original CSS file name

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [disabled, setDisabled] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    display: false,
    message: '',
    type: '',
  });

  const form = useRef();

  const toggleAlert = (message, type) => {
    setAlertInfo({ display: true, message, type });

    setTimeout(() => {
      setAlertInfo({ display: false, message: '', type: '' });
    }, 5000);
  };

  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    try {
      setDisabled(true);

      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      await emailjs.sendForm(
        'service_f7lohhb',
        'template_uyfgtt7',
        form.current,
        'sp9R93pUz8ZbOG-HN'
      );

      toggleAlert('Form submission was successful!', 'success');
    } catch (e) {
      console.error(e);
      toggleAlert('Uh oh. Something went wrong.', 'danger');
    } finally {
      setDisabled(false);
      reset();
    }
  };

  return (
    <div className="flex-container">
      {/* Left Section: About Jae's Living Experience */}
      <div className="about-section">
        <header className="about-header">
          <h1>Contact Us</h1>
        </header>

        {/* Who We Are Section */}
        <section className="who-we-are">
          <img 
            src="https://media.istockphoto.com/id/1263074286/vector/youth-hostel-modern-room-bunk-comfortable-bed-student-accommodation-isolated-on-white-design.jpg?s=612x612&w=0&k=20&c=MCOrUzC4oB6sS4LWuLzzWBRrxn8N13wEn6kOSTK6Vmg=" 
            alt="Luxury Living" 
          />
          <p>We try our best to craft a beautiful living experience, tell us how we can help you.</p>
        </section>
      </div>

      {/* Right Section: Contact Form */}
      <div className="form-section">
        <form
          ref={form}
          id="cosmic-contact-form"
          className="form-container"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div className="input-group">
            <div className="field-group">
              <input
                type="text"
                name="name"
                id="name"
                className={`input-field2 ${errors.name ? 'input-error' : ''}`}
                placeholder="Your Name"
                {...register('name', {
                  required: 'Please enter your name',
                  maxLength: {
                    value: 30,
                    message: 'Name cannot exceed 30 characters',
                  },
                })}
              />
              {errors.name && <p className="error-message">{errors.name.message}</p>}
            </div>

            <div className="field-group">
              <input
                type="email"
                name="email"
                id="email"
                className={`input-field2 ${errors.email ? 'input-error' : ''}`}
                placeholder="Your Email"
                {...register('email', {
                  required: 'Please enter a valid email address',
                  pattern: {
                    value:
                      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                    message: 'Please enter a valid email address',
                  },
                })}
              />
              {errors.email && <p className="error-message">{errors.email.message}</p>}
            </div>
          </div>

          <div className="field-group">
            <input
              type="text"
              name="subject"
              id="subject"
              className={`input-field2 ${errors.subject ? 'input-error' : ''}`}
              placeholder="Subject"
              {...register('subject', {
                required: 'Please enter a subject',
                maxLength: {
                  value: 75,
                  message: 'Subject cannot exceed 75 characters',
                },
              })}
            />
            {errors.subject && (
              <p className="error-message">{errors.subject.message}</p>
            )}
          </div>

          <div className="field-group">
            <textarea
              name="message"
              id="message"
              className={`input-field2 ${errors.message ? 'input-error' : ''}`}
              rows="5"
              placeholder="Your Message"
              {...register('message', {
                required: 'Please enter a message',
              })}
            ></textarea>
            {errors.message && (
              <p className="error-message">{errors.message.message}</p>
            )}
          </div>

          <button
            type="submit"
            className="submit-button"
            disabled={disabled}
          >
            Send Message
          </button>
        </form>

        {alertInfo.display && (
          <div className={`alert alert-${alertInfo.type}`}>
            {alertInfo.message}
          </div>
        )}

        {/* Contact Info Section */}
       {/* Contact Info Section */}
<div className="contact-info">
  <div className="contact-info-item">
    <img 
      src="https://cdn.pixabay.com/photo/2017/03/17/06/47/email-2151046_960_720.png" 
      alt="Email" 
    />
    <p>info@jaes.co.za</p>
  </div>
  <div className="contact-info-item">
    <img 
      src="https://cdn.pixabay.com/photo/2016/11/17/16/06/icons-1831923_960_720.png" 
      alt="Phone" 
    />
    <p>+27 81 500 6508</p>
        </div>
       </div>
      </div>
    </div>
  );
};

export default ContactForm;
