import React, { useEffect } from "react";

function ThankYou() {
  useEffect(() => {
    // Retrieve user info from localStorage
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (userInfo) {
      // Send user data to the backend to save after successful payment
      const saveUserData = async () => {
        try {
          const response = await fetch('https://backendjaes.passiontrials.co.za/save-event', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(userInfo),
          });

          if (!response.ok) {
            throw new Error('Error saving user data');
          }

          console.log("User information saved successfully");
        } catch (error) {
          console.error("Error saving user information:", error);
        }
      };

      saveUserData();

      // Clear localStorage after saving
      localStorage.removeItem("userInfo");
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', padding: '50px' }}>
  <h1>Thank You for Your Purchase!</h1>
  <p>Your ticket has been successfully purchased. We look forward to seeing you at JAES Night Fest!</p>
  <p>Please check your email for the QR code, which you'll need for entry to the event.</p>
</div>

  );
}

export default ThankYou;
