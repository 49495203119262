import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import logo from './logowhitefooter.png';
import './slides.css';
import './building.css';
import './filterStyles.css';
import About from './E7E6162E-1C97-466B-B6E4-9C4334401A03_1_201_a.jpeg';


function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

function Building() {
    const [buildings, setBuildings] = useState([]);
    const [zone, setZone] = useState(""); // Default to no zone filter
    const [gender, setGender] = useState(""); // Default to no gender filter
    const sliderRef = useRef(null); // Ref to track the slider div

    

    const buildingRows = chunkArray(buildings, 3);

    useEffect(() => {
        buildingInfo();
        window.scrollTo(0, 0);
    }, []);

    const buildingInfo = () => {
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb`)
            .then(res => res.json())
            .then(info => {
                setBuildings(info);
                console.log('this is the data that must be revealed', info);
            })
            .catch(error => {
                console.log('An error occurred', error);
            });
    };

    

    const handleFilterChange = () => {
        // Apply the filters based on zone, gender
        fetch(`https://backendjaes.passiontrials.co.za/new_building_tb?zone=${zone}&gender=${gender}`)
            .then(res => res.json())
            .then(filteredBuildings => {
                setBuildings(filteredBuildings);
            })
            .catch(error => console.log('Error filtering buildings:', error));
    };

    const handleNext = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft += sliderRef.current.offsetWidth;
        }
    };

    const handlePrev = () => {
        if (sliderRef.current) {
            sliderRef.current.scrollLeft -= sliderRef.current.offsetWidth;
        }
    };

    return (
        <body>
            {/* Filter Section */}
            <div class="container py-lg-5 py-md-4 py-2 d-flex justify-content-center">
                <div class="d-flex align-items-center justify-content-center">
                    <div class="me-3">
                        <select
                            id="zoneFilter"
                            class="form-control"
                            value={zone}
                            onChange={(e) => setZone(e.target.value)}
                            style={{
                                width: '200px',
                                height: '48px',
                                borderRadius: '39px',
                                backgroundColor: '#ff5a5f',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            <option value="">All Zones</option>
                            <option value="1">Westridge, Manor Gardens Area</option>
                            <option value="2">Glenwood Area</option>
                            <option value="3">Umbilo, Lower Glenwood Area</option>
                            <option value="4">Glenmore, Carrington Heights Area</option>
                        </select>
                    </div>

                    <div class="me-3">
                        <select
                            id="genderFilter"
                            class="form-control"
                            value={gender}
                            onChange={(e) => setGender(e.target.value)}
                            style={{
                                width: '200px',
                                height: '48px',
                                borderRadius: '39px',
                                backgroundColor: '#ff5a5f',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            <option value="">All Genders</option>
                            <option value="0">Female</option>
                            <option value="1">Male</option>
                            <option value="2">Female & Male</option>
                        </select>
                    </div>

                    <div>
                        <button
                            class="btn btn-primary"
                            onClick={handleFilterChange}
                            style={{
                                width: '200px',
                                height: '48px',
                                borderRadius: '39px',
                                backgroundColor: '#ff5a5f',
                                color: 'white',
                                border: 'none'
                            }}
                        >
                            Apply Filters
                        </button>
                    </div>
                </div>
            </div>

            {/* Building Listings in Scroll */}
            <div className="dsasdssa" id="dsasdssa" ref={sliderRef}>
                {buildings.map((build) => (
                    <div className="dsasdssa-item" key={build.BuildingId}> <Link to={`/Apartments/${build.BuildingId}`} className="view-link">
                                   
                        <div className="room-card">
                            <img
                                src={`https://backendjaes.passiontrials.co.za/src${build.BuildingInteriorImage1}`}
                                alt="Building Exterior"
                                className="room-card-image"
                            />
                            <div className="room-card-content">
                                <h2 className="room-title">{build.BuildingName}</h2>
                                <p className="b-type">
                                    <i className="fas fa-map-marker-alt me-1"></i>
                                    {build.ZoneName}  
                                </p>
                                <div className="plan-options">
                                    <button className="plan-option">{build.PreferedGender}</button>
                                </div>
                                <i className="fas fa-bed me-1"></i>{build.Setup}
                              
                                <div className="room-details">
                                    <div className="detail-item">
                                        <p>From {build.Best_Price} per room</p>
                                        <span>{build.Capacity > 0 ? `${build.Capacity} Beds Available` : 'No Beds Available'}</span>
                                    </div>
                                </div>
                            </div>  
                        </div></Link>
                    </div>
                ))} 
            </div>
            <div className="fdsdsfdsdsv">
    <button className="dsdss prev" onClick={handlePrev}>&#10094;</button> {/* Left arrow */}
    <button className="dsdss next" onClick={handleNext}>&#10095;</button> {/* Right arrow */}
</div>

<section class="w3l-whyblock pb-5 pt-2">
        <div class="container pb-lg-5 pb-md-4 pb-2">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <img  src={About} alt="" class="img-fluid radius-image" />
            </div>
            <div class="col-lg-6 ps-xl-5 ps-lg-4 mt-lg-0 mt-5">
              <div class="title-main mb-4" style={{ maxWidth: '600px' }}>
                <p>Our Features</p>
                <h3  style={{textAlign: "center"}} class="title-style">Why Pick Us !</h3>
              </div>
              <p style={{textAlign: "center"}}> Backed by years of trust and a wide range of verified listings, we ensure hassle-free living and transportation. Simplify your student life with a platform designed for your peace of mind!</p>
              <div class="two-grids mt-5">
                <div class="grids_info">
                  <i class="fas fa-certificate"></i>
                  <div class="detail">
                    <h4> 2025 NSFAS Approved accommodations</h4>
                    <p style={{textAlign: "left"}}>Find your ideal student home with NSFAS Approved Accommodations today.</p>
                  </div>
                </div>
                <div class="grids_info mt-xl-5 mt-4">
                  <i class="fas fa-money-bill-wave"></i>
                  <div class="detail">
                    <h4>Book student accommodation instantly</h4>
                    <p style={{textAlign: "left"}}>Instant booking for a seamless experience.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
            {/* Footer */}
            <footer className="w3l-footer-29-main py-10">
  <div className="container pt-lg-5 pt-sm-4 pt-2 pb-2">
    <div className="row footer-top-29">
      {/* Logo on the left */}
      <div className="logoFooterItem">
        <Link className="btn-group-vertical" to="/">
          <img src={logo} alt="Tours Logo" className="" />
        </Link>
      </div>

      {/* Footer content side by side (Quick Links, Explore, Contact Info) */}
      <div className="w3l-promocode">
        <div className="row footer-list-29">
          <div className="col-lg-4 col-md-6 col-sm-12">
            <ul>
              <h6 className="footer-title-29">Quick Links</h6>
              <li><a href="about.html"style={{color:"white"}}>About Us</a></li>
              <li><a href="#services" style={{color:"white"}}>Services</a></li>
              <li><a href="/ContactUs" style={{color:"white"}}>Contact Us</a></li>
              <li><a href="#rewards" style={{color:"white"}}>Rewards</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-6 col-sm-12">
            <ul>
              <h6 className="footer-title-29" style={{color:"white"}}>Explore</h6>
              <li><a href="#blog" style={{color:"white"}}>Blog Posts</a></li>
              <li><a href="#privacy" style={{color:"white"}}>Privacy Policy</a></li>
              <li><a href="#partners" style={{color:"white"}}>Our Partners</a></li>
              <li><a href="#events" style={{color:"white"}}>Events</a></li>
            </ul>
          </div>

          <div className="col-lg-4 col-md-12 col-sm-12">
            <h6 className="footer-title-29">Contact Info</h6>
            <p className="mb-2" style={{color:"white"}}>Phone Number: <a href="tel:+27(81)5006508" style={{color:"white"}}>+27(81) 500 6508</a></p>
            <p className="mb-2" style={{color:"white"}}>Email: <a href="mailto:Info@jaes.co.za" style={{color:"white"}}>Info@jaes.co.za</a></p>
            <div className="mt-2">
              <ul className="social">
                <li><a href="https://web.whatsapp.com/send?phone=27815006508" target="_blank"><i className="fab fa-whatsapp"></i></a></li>
                <li><a href="https://www.tiktok.com/@jaesproperties" target="_blank"><i className="fab fa-tiktok"></i></a></li>
                <li><a href="https://www.instagram.com/jaesproperties" target="_blank"><i className="fab fa-instagram"></i></a></li>
              </ul>
            </div>
          </div>
          <div className="copy-footer-234">
      <p style={{color:"white"}}>© 2024 Jae's Homes. All rights reserved. Design by <a href="https://vingamedia.co.za" target="_blank" style={{color:"white"}}>VingaMedia</a></p>
    </div>
        </div>
      </div>
    </div>
  </div>
</footer>
        </body>
    );
}

export default Building;
