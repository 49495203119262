import React, { useState } from 'react';
import './RegistrationModal.css'; // Make sure this CSS file is properly imported to style the modal
import { useNavigate, Link } from 'react-router-dom';
import { EyeClosed, Eye } from 'lucide-react';
import ReserveLoginModal from './TransportLoginModal.js'; // Import the Login Modal

function ReserveRegistrationModal({ onClose }) {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false); // Password visibility toggle
    const [formData, setFormData] = useState({
        Name: '',
        Surname: '',
        Email: '',
        PhoneNumber: '',
        Password: '',
        ConfirmPassword: '',
        IdNumber: '',
        PassportNumber: '',
        Country: '',
        Gender: '',
        DateOfBirth: '',
        InstituteName: '',
        TypeOfFunding: "Transport" // Set initial value from selectedFunding prop

    });
    const [faceImage, setFaceImage] = useState(null); // State to hold face image
    const [idImage, setIdImage] = useState(null); // State to hold ID image
    const [loading, setLoading] = useState(false);
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false); // State to manage login modal
    const [useID, setUseID] = useState(true);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    // Handle input changes
    const handleChange = (e) => {
        const { name, value } = e.target;

        if (useID && name === 'IdNumber' && value.length === 13) {
            const gender = handleGender(value);
            const dateOfBirth = handleDateOfBirth(value);

            setFormData({
                ...formData,
                [name]: value,
                Gender: gender,
                DateOfBirth: dateOfBirth
            });
        } else if (!useID && name === 'PassportNumber') {
            setFormData({
                ...formData,
                PassportNumber: value
            });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };


    // Function to extract gender from ID number
    const handleGender = (idNumber) => {
        const genderCode = idNumber.slice(6, 10);
        return parseInt(genderCode, 10) >= 5000 ? 'Male' : 'Female';
    };

    // Function to extract date of birth from ID number
    const handleDateOfBirth = (idNumber) => {
        const year = idNumber.slice(0, 2);
        const month = idNumber.slice(2, 4);
        const day = idNumber.slice(4, 6);
        const fullYear = parseInt(year, 10) >= 0 && parseInt(year, 10) <= 22 ? `20${year}` : `19${year}`;
        const final = `${fullYear}-${month}-${day}`;

        let formated = new Date(final).toLocaleDateString(`en-Us`, { day: "numeric", month: 'long', year: 'numeric' });
        return formated;
    };

    // Handle image file selection
    const handleImageChange = (e) => {
        if (e.target.name === 'FaceImage') {
            setFaceImage(e.target.files[0]); // Set face image
        } else if (e.target.name === 'ID_Image') {
            setIdImage(e.target.files[0]); // Set ID image
        }
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true); // Start loading


        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;

        // Check if the passwords match
        if (formData.Password !== formData.ConfirmPassword) {
            alert("Passwords do not match!");
            setLoading(false); // Stop loading
            return;
        }

        // Check if the password meets the strength requirements
        if (!passwordRegex.test(formData.Password)) {
            alert("Password must be at least 8 characters long, include at least one uppercase letter, one lowercase letter, one number, and one special character.");
            setLoading(false); // Stop loading
            return;
        }

        const formDataToSend = new FormData();
        Object.keys(formData).forEach((key) => {
            if (key === 'IdNumber' && !useID) {
                return; // Do not append IdNumber if Passport is selected
            }
            if (key === 'PassportNumber' && useID) {
                return; // Do not append PassportNumber if ID is selected
            }
            formDataToSend.append(key, formData[key]);
        });

        if (faceImage) {
            formDataToSend.append('FaceImage', faceImage);
        }

        if (idImage) {
            formDataToSend.append('ID_Image', idImage);
        }

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/new_user_register', {
                method: 'POST',
                body: formDataToSend
            });

            const data = await response.json();
            if (response.ok) {
                //alert(data.message);
                // Show login modal after successful registration
                setIsLoginModalOpen(true);
            } else {
                alert('Registration failed: ' + data.message);
            }
        } catch (error) {
            console.error('Error during registration:', error);
            alert('An error occurred during registration.');
        } finally {
            setLoading(false); // Stop loading after process is complete
        }
    };

    const handleLoginSuccess = () => {
       navigate('/SignupTransport', { 
                
            });
        
    };

    
    

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <h2>Personal Details</h2>
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                    {/* Group First and Last Name */}
                    <div className="form-group">
                        <input
                            type="text"
                            name="Name"
                            value={formData.Name}
                            onChange={handleChange}
                            placeholder="First Name"
                            required
                        />
                        <input
                            type="text"
                            name="Surname"
                            value={formData.Surname}
                            onChange={handleChange}
                            placeholder="Last Name"
                            required
                        />
                    </div>

                    {/* Group Email and Phone Number */}
                    <div className="form-group">
                        <input
                            type="email"
                            name="Email"
                            value={formData.Email}
                            onChange={handleChange}
                            placeholder="Email"
                            required
                        />
                        <input
                            type="text"
                            name="PhoneNumber"
                            value={formData.PhoneNumber}
                            onChange={handleChange}
                            placeholder="Phone Number"
                            required
                        />
                    </div>

                    <div className="id-passport-form-group">
    <label className="id-passport-label">Use ID Number or Passport?</label>
    <select
        onChange={(e) => setUseID(e.target.value === 'ID')}
        value={useID ? 'ID' : 'Passport'}
        className="id-passport-select"
    >
        <option value="ID">ID Number</option>
        <option value="Passport">Passport</option>
    </select>

    <style>{`
        .id-passport-form-group {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .id-passport-label {
            margin-bottom: 0.5em;
            font-weight: bold;
        }

        .id-passport-select {
            padding: 0.5em;
            font-size: 1em;
        }
    `}</style>
</div>


                    {useID ? (
                        <div className="form-group">
                            <input
                                type="text"
                                name="IdNumber"
                                value={formData.IdNumber}
                                onChange={handleChange}
                                placeholder="ID Number (13 digits)"
                                maxLength="13"
                                required
                            />
                        </div>
                    ) : (
                        <div className="form-group">
                            <input
                                type="text"
                                name="PassportNumber"
                                value={formData.PassportNumber}
                                onChange={handleChange}
                                placeholder="Passport Number"
                                required
                            />
                            <input
                                type="text"
                                name="Country"
                                value={formData.Country}
                                onChange={handleChange}
                                placeholder="Country"
                                required
                            />
                        </div>
                    )}
                    {/* Gender and Date of Birth */}
                    <div className="form-group">
                        {useID ? (
                            <>
                                <input
                                    type="text"
                                    name="Gender"
                                    value={formData.Gender}
                                    placeholder="Gender"
                                    readOnly
                                />
                                <input
                                    type="text"
                                    name="DateOfBirth"
                                    value={formData.DateOfBirth}
                                    placeholder="Date of Birth"
                                    readOnly
                                />
                            </>
                        ) : (
                            <>
                               <div className="gender-group-wrapper">
    <label className="gender-label">Gender:</label>
    <div className="gender-button-group">
        <label 
            className={`gender-button ${formData.Gender === 'Male' ? 'selected' : ''}`}
            onClick={() => handleChange({ target: { name: 'Gender', value: 'Male' } })}
        >
            <input 
                type="radio" 
                name="Gender" 
                value="Male" 
                checked={formData.Gender === 'Male'} 
                onChange={handleChange} 
            />
            Male
        </label>
        <label 
            className={`gender-button ${formData.Gender === 'Female' ? 'selected' : ''}`}
            onClick={() => handleChange({ target: { name: 'Gender', value: 'Female' } })}
        >
            <input 
                type="radio" 
                name="Gender" 
                value="Female" 
                checked={formData.Gender === 'Female'} 
                onChange={handleChange} 
            />
            Female
        </label>
    </div>

    <style>{`
        .gender-group-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
        }

        .gender-label {
            margin-bottom: 0.5em;
            color: #888;
            font-weight: bold;
        }

        .gender-button-group {
            display: flex;
            gap: 1em;
        }

        .gender-button {
            padding: 0.5em 1em;
            border: 2px solid #ccc;
            border-radius: 5px;
            cursor: pointer;
            font-weight: bold;
            text-align: center;
            transition: border-color 0.3s;
        }

        .gender-button input {
            display: none; /* Hide the original radio input */
        }

        .gender-button.selected {
            border-color: #ff5a5f; /* Change border color when selected */
            background-color: #f0f8ff;
        }
    `}</style>
</div>


<div className="dob-group-wrapper">
    <label className="dob-label">Date of Birth:</label>
    <input
        type="date"
        name="DateOfBirth"
        value={formData.DateOfBirth}
        onChange={handleChange}
        className="dob-input"
        required
    />

    <style>{`
        .dob-group-wrapper {
            display: flex;
            flex-direction: column;
            margin-bottom: 1em;
            width: 100%;
        }

        .dob-label {
            margin-bottom: 0.5em;
            font-weight: bold;
            color: #888;
        }

        .dob-input {
            padding: 0.5em 1em;
            border: 2px solid #ccc;
            border-radius: 5px;
            font-size: 1em;
            font-weight: bold;
            text-align: center;
            transition: border-color 0.3s;
            width: 100%;
            box-sizing: border-box;
        }

        .dob-input:focus {
            border-color: #007bff;
            outline: none;
        }
    `}</style>
</div>

                            </>
                        )}
                    </div>
                    <div className="form-group" style={{display:'none'}}>
                        <label>Type of Funding:</label>
                        <input
                            type="text"
                            name="TypeOfFunding"
                            value={formData.TypeOfFunding}
                            readOnly
                        />
                    </div>
                    <div className="form-group">
                        <select
                            name="InstituteName"
                            value={formData.InstituteName}
                            onChange={handleChange}
                            required
                        >
                              <option value="">Select Institution</option>
                                    <option value="Ukzn Howard">UKZN Howard</option>
                                    <option value="DUT Steve Biko">DUT</option>
                        </select>
                    </div>

                    {/* Group Password and Confirm Password */}
                    <div className="form-group">
    <div className="grape-input-wrapper">
        <input
            type={showPassword ? 'text' : 'password'}
            name="Password"
            value={formData.Password}
            onChange={handleChange}
            placeholder="Password"
            required
        />
        <span onClick={togglePasswordVisibility} className="grape-eye-icon">
            {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
        </span>
    </div>

    <div className="grape-input-wrapper">
        <input
            type={showPassword ? 'text' : 'password'}
            name="ConfirmPassword"
            value={formData.ConfirmPassword}
            onChange={handleChange}
            placeholder="Confirm Password"
            required
        />
        <span onClick={togglePasswordVisibility} className="grape-eye-icon">
            {showPassword ? <EyeClosed size={20} /> : <Eye size={20} />}
        </span>
    </div>

    <style>{`
        .grape-input-wrapper {
            position: relative;
            width: 100%;
            margin-bottom: 1em;
        }

        .grape-input-wrapper input {
            width: 100%;
            padding-right: 2em; /* Add padding to avoid overlap with icon */
        }

        .grape-eye-icon {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            cursor: pointer;
            color: #888;
        }
    `}</style>
</div>


                    {/* Add file input for uploading face image 
                    <div className="form-group">
                        <label>Upload Face Photo:</label>
                        <input
                            type="file"
                            name="FaceImage"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div>*/}

                    {/* Add file input for uploading ID image
                    <div className="form-group">
                        <label>Upload ID Image:</label>
                        <input
                            type="file"
                            name="ID_Image"
                            accept="image/*"
                            onChange={handleImageChange}
                            required
                        />
                    </div> */}

                    <div className="form-group">
                        <button type="button" onClick={onClose}>Cancel</button>
                        <button type="submit" disabled={loading}>
                            {loading ? 'Registering...' : 'Register'}
                        </button>
                    </div>

                    
                    <p>Already have an account? <a  onClick={() => setIsLoginModalOpen(true)} className="login-link">Login</a></p>

                </form>

                {/* Show the login modal after clicking the Login button */}
                {isLoginModalOpen && (
                    <ReserveLoginModal
                    onClose={() => setIsLoginModalOpen(false)}

                    />
                )}
            </div>
        </div>
    );
}

export default ReserveRegistrationModal;
