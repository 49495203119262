import React, { useState, useEffect } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';
import Apartments from './Apartments';
import defaults from "./default_room_room.jpg";
import './invoice.css'
import './loaders.css'
import { PDFDocument, rgb } from 'pdf-lib';
import RegistrationModal from './components/RegistrationModal.js'; // Adjust the path based on your folder structure



function Invoice() {
    const location = useLocation();
    const { userId } = useAuth(); // Get userId from AuthContext
    const { date, apartment, apartmentandbuilding } = location.state || {}; // Destructure with default empty object to prevent errors
    const [loading, setLoading] = React.useState(false);
    const [showRegistrationModal, setShowRegistrationModal] = useState(false); // State to show the registration modal
    const navigate = useNavigate();
    const { isLoggedIn, role } = useAuth();
    const [selectedPlan, setSelectedPayment] = React.useState('');
    
    const [userData, setUserData] = useState(null);
    const [downloadAfterLogin, setDownloadAfterLogin] = useState(false);
    const [clickedAction, setClickedAction] = useState(null); // Track the action button pressed


    let [totalAmount, setTotalAmount] = React.useState(null);
    let [serviceFee, setServiceFee] = React.useState(null);
    let [chosenPlan, setChosenPlan] = React.useState(null);
    let amount = null;
    const [thePrice, setThePrice] = React.useState(null);
    const [selectedFile, setSelectedFile] = React.useState(null);

    const [isEmergencyChecked, setIsEmergencyChecked] = useState(false);
    const [isHouseRulesChecked, setIsHouseRulesChecked] = useState(false);
    const [isIncidentFormChecked, setIsIncidentFormChecked] = useState(false);
    const [isLeaseClauseChecked, setIsLeaseClauseChecked] = useState(false);


    const allCheckboxesChecked = isEmergencyChecked && isHouseRulesChecked && isIncidentFormChecked && isLeaseClauseChecked;
    React.useEffect(() => {
        // Check if all required data is present before trying to access it
        if (!date || !apartment || !apartmentandbuilding) {
            console.error("Missing necessary data from location.state", date, apartment, apartmentandbuilding);
            setLoading(false); // Set loading false to show error or missing data message
            return; // Prevent further execution
        }
        setLoading(false);
        window.scrollTo(0, 0); // Data is present, so you can turn off loading
    }, [date, apartment, apartmentandbuilding]); // Ensure useEffect runs when any of these dependencies update


    useEffect(() => {
        if (isLoggedIn && userId) {
            const fetchUserData = async () => {
                try {
                    const response = await fetch(`https://backendjaes.passiontrials.co.za/user_reserve/${userId}`, {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    
                    if (!response.ok) {
                        throw new Error('Failed to fetch user data');
                    }

                    const data = await response.json();
                    setUserData(data.length ? data[0] : data);
                    console.log("dsdasdasadasd", data)
                } catch (error) {
                    console.error("Error fetching user data:", error);
                }
            };
            fetchUserData();
        }
    }, [isLoggedIn, userId]);

    const handlePayment2 = async () => {
        try {
            const amountInCents = Math.round(parseFloat(totalAmount) * 100);
            const response = await fetch('https://backendjaes.passiontrials.co.za/api/pay', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    amount: amountInCents,
                    currency: 'ZAR',
                    successUrl: `https://jaesstudenthomes.co.za/Payment?status=success&user=50`,
                    cancelUrl: 'https://jaesstudenthomes.co.za/cancel?status=cancel'
                })
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const data = await response.json();
            console.log('Payment response:', data);

            if (data.redirectUrl) {
                window.location.href = data.redirectUrl;
            }
        } catch (error) {
            console.error('Payment error:', error);
        }
    };

    const generatePDF = async () => {
        setClickedAction('downloadPDF'); // Set action as 'downloadPDF'
        if (!isEmergencyChecked || !isHouseRulesChecked || !isIncidentFormChecked || !isLeaseClauseChecked) {
            alert('Please agree to all the terms by checking the boxes.');
            return;
        }
        if (!isLoggedIn) {
            // If the user is not logged in, set the flag and show the registration modal
            setDownloadAfterLogin(true);
            setShowRegistrationModal(true);
            return;
        }
    
        // If the user is logged in, proceed with generating the PDF
        if (userData) {
            try {
                // Fetch the PDF file as an array buffer
                const response = await fetch('https://jaesstudenthomes.co.za/STUDENT%20HOMES%20BOOKING%20FORM%202025.pdf');
                const pdfBytes = await response.arrayBuffer();
    
                // Load PDF using pdf-lib
                const pdfDoc = await PDFDocument.load(pdfBytes);
                const firstPage = pdfDoc.getPages()[0];
    
                // Add user data to the PDF
                firstPage.drawText(`${userData.Name} ${userData.Surname}`, { x: 230, y: 535, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.IdNumber || userData.PassportNumber, { x: 230, y: 510, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(apartmentandbuilding.RoomIdentifier, { x: 180, y: 790, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 385, y: 490, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText("X", { x: 230, y: 490, size: 12, color: rgb(0, 0, 0) });

                firstPage.drawText(apartmentandbuilding.BuildingLocation || 'N/A', { x: 230, y: 465, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.Email, { x: 265, y: 440, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(userData.PhoneNumber, { x: 255, y: 415, size: 12, color: rgb(0, 0, 0) });
                firstPage.drawText(new Date().toLocaleDateString('en-GB'), { x: 170, y: 85, size: 12, color: rgb(0, 0, 0) });
    
                // Save the modified PDF to a blob and trigger download
                const pdfDataUri = await pdfDoc.saveAsBase64({ dataUri: true });
                const link = document.createElement('a');
                link.href = pdfDataUri;
                link.download = `Booking_Form_${Date.now()}.pdf`;
                document.body.appendChild(link); // Append link to the DOM for Firefox support
                link.click();
                document.body.removeChild(link); // Remove link after download
    
                console.log('PDF successfully created and downloaded.');
            } catch (error) {
                console.error('Error creating PDF:', error.message);
            }
        } else {
            console.error("User data is not available for generating PDF");
        }
    };
    // Function to handle payment
    const handlePayment = () => {
        setClickedAction('pay'); // Set action as 'pay'

        if (role === 'Admin' || role === 'Super Admin') {
            alert('Admins are not permitted to book an apartment');
            navigate(-1);
            return;
        }

        if (selectedPlan && allCheckboxesChecked) {
            if (!isLoggedIn) {
                // Show the registration modal if user is not logged in
                setShowRegistrationModal(true);
                return;
            }

            setLoading(true);
            setTimeout(() => {
                setLoading(false);
                navigate('/YocoPayment', { state: { date, apartment, apartmentandbuilding: apartmentandbuilding.BuildingId, totalAmount, serviceFee, chosenPlan, nextPaymentDate, Email: userData.Email } });
            }, 5000);
        } else if (!allCheckboxesChecked) {
            alert('Please agree to all the terms by checking the boxes.');
        } else {
            alert('Please select a payment plan');
        }
    };

    const handleGender = (binary) => {
        if (binary) {
            if (binary == 0) {
                return 'Female';
            }

            if (binary == 1) {

                return 'Male';
            }

            if (binary == 2) {
                return 'Mixed';
            }
        }
    }

    const handleSetup = (binary) => {

        if (binary) {
            if (binary == 0) {
                return "Single";
            }

            if (binary == 1) {
                return 'Single with Ensuite';
            }

            if (binary == 2) {
                return 'Sharing Double';
            }

            if (binary == 3) {
                return 'Sharing Double with Ensuite';
            }

            if (binary == 4) {
                return 'Sharing Triple';

            }

            if (binary == 5) {
                return 'Bachelor Pods';
            }
        }
    };

    const handleSelectedPlan = (e) => {
        const { name, value } = e.target;
        let plan = e?.target.options[e?.target.selectedIndex]?.text;
        setSelectedPayment(Number(value));
        console.log('this is the plan amount chosen', Number(value) + amount);
        // setTotalAmount(Number(value) + amount);
        setServiceFee(Number(value));

        setChosenPlan(plan);


        let splited = plan.split('-')[0].trim();

        console.log('its split :', splited);
        if (splited == 'Plan A') {
            amount = apartment?.ApartmentPrice;
            //represents plan a
            console.log("plan a:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));
        }

        if (splited == 'Plan B') {
            amount = apartment?.PriceB;
            console.log("plan b:,", amount);
            setThePrice(amount);
            setTotalAmount(amount + Number(value));

        }

    };

    // React.useEffect(() => {
    //     console.log('This is the chosen plan:', chosenPlan);
    //     setChosenPlan(chosenPlan);
    // }, [chosenPlan]);

    if (loading) {
        return <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh' // This ensures it takes the full viewport height
        }}>
            <div className="🤚">
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="👉"></div>
                <div className="🌴"></div>
                <div className="👍"></div>
            </div>;     </div>// Show loading while checking for data
    }

    if (!date || !apartment || !apartmentandbuilding) {
        return <div>Error: Missing invoice data. Please ensure all data is provided.</div>; // Show error if data is missing
    }
    // Callback function after successful registration
    const handleRegistrationSuccess = () => {
        setShowRegistrationModal(false); // Close the modal
    
        // Debug log to check which action is triggered
        console.log("Registration Success:", clickedAction);
    
        if (downloadAfterLogin || clickedAction === 'downloadPDF') {
            console.log("Downloading PDF after login..."); // Check if this line is reached
            generatePDF(); // Call `generatePDF()` to download the PDF
            setDownloadAfterLogin(false); // Reset the flag
        } else if (clickedAction === 'pay') {
            console.log("Redirecting to YocoPayment...");
            navigate('/ReserveYocoPayment', { state: { /* your existing parameters */ } });
        }
    };
    // Format dates
    const pickedDate = new Date(date).toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' }); //current date no picking
    const nextPaymentDate = new Date(date);
    nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 3);

    nextPaymentDate.setDate(new Date(nextPaymentDate.getFullYear(), nextPaymentDate.getMonth() + 1, 0).getDate());

    ///= apartment.ApartmentPrice;
    let currentDay = date.toLocaleDateString('en-US', { day: 'numeric' });
    let earlyDate = 0;
    let lateDate = 0;
    let AmountAfterDeduction = 0;
    let AmountAfterIncrease = 0;

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const handleUpload = async () => {
        const formData = new FormData();
        formData.append('image', selectedFile);

        try {
            const response = await fetch('https://backendjaes.passiontrials.co.za/upload',
                {
                    headers: { 'Content-Type': 'multipart/form-data', },
                    method: "POST",
                    body: formData,
                });

            if (response.ok) {
                const data = await response.json();
                console.log('Image uploaded successfully: ', data);

            }
            else {
                console.error('Error uploading image:');
            }
        }
        catch (error) {
            console.error('error uploading image:', error);
        }

    };


    // if (currentDay > 1 && currentDay < 25) {

    //     currentDay = currentDay - 1;
    //     earlyDate = currentDay / 100 * amount;

    //     AmountAfterDeduction = amount - earlyDate;

    //     amount = AmountAfterDeduction;

    // }
    // else if (currentDay > 24 && currentDay <= 31) {
    //     currentDay = currentDay - 1;
    //     lateDate = currentDay / 100 * amount;

    //     AmountAfterIncrease = lateDate + amount;

    //     amount = AmountAfterIncrease;

    //     nextPaymentDate.setMonth(nextPaymentDate.getMonth() + 1, 1);

    // }

    return (
        <>
            <div class="containerinvoice" >
                <div class="booking-form">

                    <>
                        <div class="step2">JAES STUDENT HOMES - GENERAL HOUSE RULES</div>
                        <p>The following is the general house rules for all properties. Each property has specific added terms that are to be adhered to in addition to the following:</p>


                        <ul class='bullet-list'>
                            <li>&#8226; Communal areas should be kept clean at all times.</li>
                            <li> &#8226; Gates and house entrances should be locked AT ALL TIMES for tenants’ safety (fine of the services deposit is applicable for constant offenders).</li>
                            <li>&#8226; Rooms should be kept locked when tenant is not on the premises. Tenant is not permitted to lend his/her keys to any individuals. No sharing of the rooms unless approved of by the lessor/owner.</li>
                            <li>&#8226; Every tenant’s privacy should be respected. Noise and music should be kept at a tolerable level for everyone.</li>
                            <li>&#8226; No smoking permitted inside the premises (only outside in allocated areas) and no DRUGS in the premises. No parties allowed.</li>
                            <li>&#8226; No washing is to be hanged in the bathroom.</li>
                            <li>&#8226; No more than 2 visitors per lessee allowed in the premises at a time. Visitors are the tenant’s full responsibility. No visitors are permitted to use additional services.</li>
                            <li>&#8226; No visitors allowed before 10am and after 21:00 pm. NO SLEEPOVERS.</li>
                            <li>&#8226; Down time after 21:30 pm.</li>
                        </ul>

                        <div class="step3">Fines (of up to R950) will be charged to tenant account for each rule break. In extreme cases termination of lease will take effect.</div>





                        <hr />

                        <div class="step">EMERGENCY PROTOCOLS</div>
                        <div className='emerg-row'>
                            <div>
                                <h4>FIRE</h4>
                                <p className='emergp'>
                                    &#8226; CALL FIRE STATION - 10111<br />
                                    &#8226;  CALL ADMIN - 081 500 6508
                                </p>
                            </div>
                            <div>
                                <h4>MEDICAL</h4>
                                <p className='emergp'>
                                    &#8226; CALL ADMIN - 081 500 6508<br />
                                    &#8226;  CALL NEXT OF KIN<br />
                                    &#8226;   CALL AMBULANCE - 10177 / 082 911
                                </p>
                            </div>
                        </div>

                        <div className='emerg-row'>
                            <div>
                                <h4>SECURITY</h4>
                                <p className='emergp'>
                                    &#8226; CALL ADMIN - 081 500 6508<br />
                                    &#8226; CALL SECURITY - 031 717 5000<br />
                                    &#8226; CALL POLICE - 10111
                                </p>
                            </div>
                            <div>
                                <h4>NATURAL DISASTER</h4>
                                <p className='emergp'>
                                    &#8226; CALL ADMIN - 081 500 6508<br />
                                    &#8226; CALL LANDLORD<br />
                                    &#8226;   CALL AMBULANCE - 10177 / 082 911
                                </p>
                            </div>
                        </div>

                        <div className='emerg-row'>
                            <div>
                                <h4>OTHER</h4>
                                <p className='emergp'>&#8226; PRAY</p>
                            </div>
                            <div>
                            </div>
                        </div>

                    </>
                </div>
                <div class="booking-summary">
                    <div class="header">
                        <img    src={
                                  apartment.ApartmentRoomImage
                                    ? `https://backendjaes.passiontrials.co.za/src${apartment.ApartmentRoomImage}`
                                    : defaults
                                }
                            alt="Apartment Image" class="image" />
                        <div class="title">
                            {/* <span class="badge">Male</span> */}
                            <h2>{apartmentandbuilding.BuildingName} <br /> {apartment.ApartmentNumber}</h2>
                            <p> {handleSetup(apartment.LivingArrangement)}</p>
                        </div>
                    </div>
                    <div class="check-in-out">
                        <div class="check">
                            <p>Check In</p>
                            <strong>{pickedDate}</strong>
                        </div>
                        <div class="check-arrow">
                            <p>&gt;</p>
                        </div>
                        <div class="check">
                            <p>Check Out</p>
                            <strong> Nov 30, 2024</strong>
                        </div>
                    </div>

                    <div class="details">
                        <div class="detail-row">
                            <span class="label">Building:</span>
                            <span class="value">{apartmentandbuilding.BuildingName}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Suburb:</span>
                            <span class="value">{apartmentandbuilding.Suburb}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Apartment Type:</span>
                            <span class="value">{apartment.LivingArrangement}</span>
                        </div>
                        <div class="detail-row">
                            <span class="label">Zone</span>
                            <span class="value">{apartmentandbuilding.Zone}</span>
                        </div>
                    </div>
                    <select
                        name='selectedPlan'
                        onChange={handleSelectedPlan}
                        value={selectedPlan}
                    >
                        <option value=''>Select Payment plan </option>
                        <option name='Plan A' value="3250">Plan A  - ( R3250 )</option>
                        <option name='Plan B' value='8500'>Plan B  - ( R8500)</option>
                    </select>
                    <div class="cost">
                        <p> </p>
                        <div class="cost-row">
                            <span class="label">Service Fee:</span>
                            <span class="value">R{selectedPlan}</span>
                        </div>
                        <p><span style={{ color: 'green' }}> </span></p>
                        <div class="cost-row">
                            <span class="label">Total Due:</span>
                            <span class="value">R {selectedPlan ? `${thePrice + selectedPlan}` : '0.00'}</span>
                        </div>
                        <div class="checkbox-section">
                            <div class="checkbox-item">
                                <input type="checkbox" id="emergency-protocol" name="emergency-protocol" checked={isEmergencyChecked} onChange={() => setIsEmergencyChecked(!isEmergencyChecked)} />
                                <label for="emergency-protocol">Emergency Protocol</label>
                            </div>
                            <div class="checkbox-item">
                                <input type="checkbox" id="house-rules" name="house-rules" checked={isHouseRulesChecked} onChange={() => setIsHouseRulesChecked(!isHouseRulesChecked)} />
                                <label for="house-rules">House Rules</label>
                            </div>
                            <div class="checkbox-item">
                                <input type="checkbox" id="incident-form" name="incident-form" checked={isIncidentFormChecked} onChange={() => setIsIncidentFormChecked(!isIncidentFormChecked)} />
                                <label for="incident-form">Incident Form</label>
                            </div>
                            <div class="checkbox-item">
                                <input type="checkbox" id="lease-clause" name="lease-clause" checked={isLeaseClauseChecked} onChange={() => setIsLeaseClauseChecked(!isLeaseClauseChecked)} />
                                <label for="lease-clause">Lease Clause</label>
                            </div>
                            {/* <ul class='bullet-list'>
                                <li>&#8226; I confirm that I have read, consent, and agree to Jaes Homes' Emergency Protocol, House Rules, Incident Form, and Lease Clause (including the processing and disclosing of my personal data), and I am of legal age. I understand that I can update my preferences or submit incident reports at any time. For more information about Jaes Homes.</li>
                            </ul> */}
                        </div>

                    </div>

                    <div class="due-today">
                        <button onClick={handlePayment} disabled={loading} style={{ backgroundColor: '#ff5a5f', fontSize: '19px' }}>
                            {loading ? 'Processing...' : ` Pay R${thePrice + selectedPlan} `}
                        </button>
                        <button 
        onClick={generatePDF} 
        style={{
            fontSize: '19px',
            padding: '10px 20px',
            border: '2px solid #ffffff',
            borderRadius: '6px',
            cursor: 'pointer',
            transition: 'transform 0.2s ease, box-shadow 0.2s ease',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        }}
    >
        Pay via EFT
    </button>
                    </div>
                </div>
                {/* Render the registration modal */}
                {showRegistrationModal && (
                    <RegistrationModal
                     // Pass down clickedAction
                     clickedAction={clickedAction}
                     onSuccess={handleRegistrationSuccess}
                     onClose={() => setShowRegistrationModal(false)}
                        date={date}
                        apartment={apartment}
                        apartmentandbuilding={apartmentandbuilding}
                        totalAmount={totalAmount}
                        serviceFee={serviceFee}
                        chosenPlan={chosenPlan}
                        nextPaymentDate={nextPaymentDate}
                    
                    />

                )}
            </div>
        </>
    );
}

export default Invoice;
