import React from 'react'

const Amber = () => {
  return (
    <body>
    <div id="root"> 
      <div id="contact-container">
        <nav class="amber-1olevh4" data-testid="mobile-header-section">
          <div class="amber-z4ao7e">
            <div class="amber-Group-root amber-uhe779">
              <div class="amber-Group-root amber-kghls4" gap="12"><button
                  class="amber-UnstyledButton-root amber-ActionIcon-root amber-qx67fo" type="button"><i
                    class="icon-arrow-left amber-11nhzn5"></i></button></div>
              <div class="amber-1byyuyr">
                <div class="amber-12sbrde" role="combobox" aria-haspopup="listbox" aria-owns="downshift-0-menu"
                  aria-expanded="false">
                  <div class="amber-1avyp1d" translate="false">
                    <div class="amber-1c0zfg6" data-testid="search-input-section">
                      <div class="amber-gntq76"><span class="static-word">Search by </span><span
                          class="animate-word animate-old">City</span><span
                          class="animate-word animate-old">University</span><span
                          class="animate-word animate-old">Property</span><span class="animate-word animate-old">City,
                          University or Property</span></div><input id="header-search" class="amber-1ajsq3"
                        placeholder="Search by City, University or Property" aria-autocomplete="list"
                        aria-controls="downshift-0-menu" autocomplete="one-time-code" value="" autocorrect="off"
                        autocapitalize="off" spellcheck="false" aria-label="property-search"/><button
                        class="amber-UnstyledButton-root amber-ActionIcon-root amber-1a1b91s" type="button"
                        aria-label="search"><i class="icon-search-button"></i></button>
                    </div>
                    <ul class="amber-4pdzkq" id="downshift-0-menu" role="listbox" aria-label="property-suggestions"></ul>
                  </div>
                </div>
              </div>
            </div><button class="amber-UnstyledButton-root  amber-Button-root amber-h2c1te" type="button"
              data-button="true">
              <div class="amber-3xbgk5 amber-Button-inner"><span class="amber-Button-label amber-1bdh54i"><i
                    class="icon-menu"></i></span></div>
            </button>
          </div>
        </nav>
        <header data-testid="header-section">
          <nav id="header-search-navbar" class="amber-6l8932">
            <div class="amber-Group-root amber-9yncd5">
              <div class="amber-Group-root amber-oqe52t">
                <div class="amber-1gnwfpi"><a href="/"><img class="amber-4wm044" src="images/amber-hd.svg?auto=format"
                      alt="Amber"/></a></div>
                <div class="amber-opfoky">
                  <div class="amber-12sbrde" role="combobox" aria-haspopup="listbox" aria-owns="downshift-0-menu"
                    aria-expanded="false">
                    <div class="amber-1avyp1d" translate="false">
                      <div class="amber-1c0zfg6" data-testid="search-input-section">
                        <div class="amber-gntq76"><span class="static-word">Search by </span><span
                            class="animate-word animate-old">City</span><span
                            class="animate-word animate-old">University</span><span
                            class="animate-word animate-old">Property</span><span class="animate-word animate-old">City,
                            University or Property</span></div><input id="header-search" class="amber-1ajsq3"
                          placeholder="Search by City, University or Property" aria-autocomplete="list"
                          aria-controls="downshift-0-menu" autocomplete="one-time-code" value="" autocorrect="off"
                          autocapitalize="off" spellcheck="false" aria-label="property-search"/><button
                          class="amber-UnstyledButton-root amber-ActionIcon-root amber-1a1b91s" type="button"
                          aria-label="search"><i class="icon-search-button"></i></button>
                      </div>
                      <ul class="amber-4pdzkq" id="downshift-0-menu" role="listbox" aria-label="property-suggestions">
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="amber-ncpez8"><button class="amber-UnstyledButton-root amber-Button-root amber-1xq0w3b"
                  type="button" data-button="true" aria-haspopup="menu" aria-expanded="false" aria-controls="-dropdown"
                  id="-target">
                  <div class="amber-3xbgk5 amber-Button-inner"><span
                      class="amber-Button-icon amber-Button-leftIcon amber-1rky12q"><i
                        class="icon-support"></i></span><span class="amber-qo1k2 amber-Button-label">Siza</span></div>
                </button><a class="amber-UnstyledButton-root amber-Button-root amber-1xq0w3b" type="button"
                  data-button="true" target="_blank" href="/profile/shortlist">
                  <div class="amber-3xbgk5 amber-Button-inner"><span
                      class="amber-Button-icon amber-Button-leftIcon amber-1rky12q"><i
                        class="icon-shortlist"></i></span><span class="amber-qo1k2 amber-Button-label">Shortlist </span>
                  </div>
                </a><button class="amber-UnstyledButton-root amber-Button-root amber-1j8rkk8" type="button"
                  data-button="true">
                  <div class="amber-3xbgk5 amber-Button-inner"><span
                      class="amber-Button-icon amber-Button-leftIcon amber-1g4orza"><i class="icon-login"></i></span><span
                      class="amber-qo1k2 amber-Button-label">LLogin</span></div>
                </button><button class="amber-UnstyledButton-root amber-Button-root amber-i10m7r" type="button"
                  data-button="true" aria-label="my profile" aria-haspopup="menu" aria-expanded="false"
                  aria-controls="-dropdown" id="-target">
                  <div class="amber-3xbgk5 amber-Button-inner"><span class="amber-qo1k2 amber-Button-label">
                      <div class="amber-c6pt05"><i class="icon-user-18"></i></div>
                    </span><span class="amber-Button-icon amber-Button-rightIcon amber-1xvd4ht"><i
                        class="icon-menu"></i></span></div>
                </button></div>
            </div>
          </nav>
        </header>
       
        <div class="section-featured-container">
          <h1 class="featured-heading">About Us</h1>
        </div>
        <div class="section-nav-container">
          <div class="container-small">
            <div class="section-nav-inner-container">
              <div class="columns is-multiline">
                <div class="column has-text-centered"><a href="#section-mission">Mission</a></div>
                <div class="column has-text-centered"><a href="#section-approach">Approach</a></div>
                <div class="column has-text-centered"><a href="/contact">Get in Touch</a></div>
                <div class="is-clearfix"></div>
              </div>
            </div>
          </div>
        </div>
        <div id="section-mission" class="section-content-container">
          <div class="section-1">
            <div class="container-small">
              <h4 class="title is-3 is-font-weight-normal">Borders are the worst thing to happen to humanity after natural
                calamities</h4>
              <p class="description">Imagine a world without borders. The world looks a lot more unified on old maps. Maps
                of today look more like mosaics, like the fragile Earth fell off a shelf and had to have its broken bits
                pieced back together.</p><br/>
              <h4 class="title is-3 is-font-weight-normal"><b>We believe in a border-free world!</b></h4>
            </div>
          </div>
          <div class="section-2 container-medium">
            <div class="container-small">
              <p class="description">Every year <b>millions</b> of people leave their home country and move to foreign
                land for<b>work, education and business</b></p>
            </div>
          </div>
          <div class="section-3 container-medium">
            <div class="container-small">
              <p class="description">All face multiple <b>problems</b> ranging from cultural integration to logistics to
                regulatory problems</p>
            </div>
          </div>
          <div class="section-4 container-medium">
            <div class="container-small">
              <p class="description"><b>Amber</b> solves house hunting and booking problem for <b>80M</b> students
                crossing geographies<b>every year</b></p>
            </div>
          </div>
        </div>
        <div id="section-approach" class="section-features-container">
          <div class="container-medium">
            <h4 class="title is-2 has-text-centered is-font-weight-normal">Our Approach</h4>
            <p class="description has-text-centered">Amber is a long-term accommodation booking platform for students. We
              help 80M students worldwide, find and book full-time accommodations near their universities, without the
              hassle of negotiation, non-standardized and cumbersome paperwork, and broken payment process.</p>
          </div>
          <div class="container">
            <div class="columns">
              <div class="column service has-text-centered">
                <div class="service-inner-container">
                  <div class="icon"><i class="icon-search-button"></i></div>
                  <h4 class="title is-4">Easy search</h4>
                  <p>Search from a wide-selection of accommodations that fit your preferences.</p>
                  <div class="is-clearfix"></div>
                </div>
              </div>
              <div class="column service has-text-centered">
                <div class="service-inner-container">
                  <div class="icon"><i class="icon-double-occupancy"></i></div>
                  <h4 class="title is-4">No negotiation</h4>
                  <p>See rent upfront. No need to negotiate. Save your time and effort.</p>
                  <div class="is-clearfix"></div>
                </div>
              </div>
              <div class="column service has-text-centered">
                <div class="service-inner-container">
                  <div class="icon"><i class="icon-instant-booking"></i></div>
                  <h4 class="title is-4">Hassle-free paperwork</h4>
                  <p>Forget irritating paperwork. Complete your booking seamlessly.</p>
                  <div class="is-clearfix"></div>
                </div>
              </div>
              <div class="clearfix visible-sm-block visible-xs-block"></div>
            </div>
          </div>
        </div>
        <section class="amber-1xlvxro">
          <div class="amber-1xkj1i6" data-testid="footer-section">
            <div class="amber-bh7re6">
              <div class="amber-Grid-root amber-e7eabn">
                <div class="amber-Grid-col amber-11xgkk7"><img src="images/amber-hd-1.svg?auto=format&amp;trim=auto"
                    class="amber-1ej9d2m" loading="lazy" alt="amber-text" width="86px" height="34.05px"/>
                  <div class="amber-Text-root amber-1l1rjut">amber © 2024 All rights reserved</div>
                  <div class="amber-1avyp1d">
                    <div class="amber-1w5jcdh">
                      <div class="amber-1yonapv"><img src="images/trustpilot-rating.svg?auto=format&amp;trim=auto"
                          alt="amber-trustpilot" loading="lazy" width="124px" height="30.161px"/>
                        <div class="amber-4lvtkj"></div>
                        <div class="amber-Text-root amber-1dhrs9p">4.8/5 on Trustpilot</div>
                      </div>
                      <div class="amber-Text-root amber-10z9ara">Rated as Excellent<span
                          class="amber-10291i3">4800+</span> Reviews by students</div>
                    </div>
                  </div>
                  <div class="amber-1ld8unf">
                    <div class="amber-rq3l2r">
                      <div class="amber-Text-root amber-i67oon">Get the app</div>
                      <div class="amber-Group-root amber-1yk0txx" gap="4"><a
                          class="amber-Text-root amber-Anchor-root amber-tfeqj0"
                          href="https://play.google.com/store/apps/details?id=com.amberstudent" target="_blank"><img
                            src="images/playstore-android.svg?auto=format&amp;trim=auto" loading="lazy" alt="play store"
                            height="20px" width="20px"/></a><a class="amber-Text-root amber-Anchor-root amber-tfeqj0"
                          href="https://apps.apple.com/us/app/amber-book-student-housing/id6447250749"
                          target="_blank"><img src="images/app-store.svg?auto=format&amp;trim=auto" alt="app store"
                            loading="lazy" height="20px" width="20px"/></a></div>
                    </div>
                    <div class="amber-c9tfh6"></div>
                    <div class="amber-rq3l2r">
                      <div class="amber-Text-root amber-i67oon">Payment Options</div>
                      <div class="amber-Group-root amber-1y6fnqw" gap="4"><img loading="lazy"
                          src="images/footer_payment_partners.svg?auto=format&amp;trim=auto" alt="payment-partners"
                          class="amber-723225" height="28px" width="158px"/></div>
                    </div>
                  </div>
                </div>
                <div class="amber-Grid-col amber-1lfkgv5">
                  <div class="amber-Grid-root amber-1duln8m">
                    <div class="amber-Grid-col amber-1yiiosv">
                      <div class="amber-Grid-col amber-jr7eh6">
                        <div class="amber-Text-root amber-an0j5c">Company</div>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"About","url":"/about"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="About.htm"
                          target="_blank">About</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"How it works","url":"/how-it-works"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/how-it-works"
                          target="_blank">How it works</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Refer a Friend","url":"/referral-programme"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/referral-programme"
                          target="_blank">Refer a Friend</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Group Bookings","url":"/group-booking"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/group-booking"
                          target="_blank">Group Bookings</a><span>New</span>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"List with us","url":"/list"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/list" target="_blank">List with
                          us</a><span>New</span>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Partner with us","url":"/partner"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/partner" target="_blank">Partner
                          with us</a><span>New</span>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Universities","url":"/university-partnerships"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/university-partnerships"
                          target="_blank">Universities</a><span>New</span>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Careers","url":"/career"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/career"
                          target="_blank">Careers</a><span>We are hiring!</span>
                      </div>
                    </div>
                    <div class="amber-Grid-col amber-1yiiosv">
                      <div class="amber-Grid-col amber-jr7eh6">
                        <div class="amber-Text-root amber-an0j5c">Discover</div>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Blog","url":"/blog"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/blog" target="_blank">Blog</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Podcast","url":"/podcast"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/podcast"
                          target="_blank">Podcast</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Newsroom","url":"/news"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/news"
                          target="_blank">Newsroom</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Amber Plus","url":"/plus"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/plus" target="_blank">Amber
                          Plus</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Media Mention","url":"/media"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/media" target="_blank">Media
                          Mention</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Ambassador","url":"/ambassador"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/ambassador"
                          target="_blank">Ambassador</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Scholarships","url":"/scholarship"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/scholarship"
                          target="_blank">Scholarships</a>
                      </div>
                      <div class="amber-Grid-col amber-137asuq">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Exams","url":"/exams"}</script> */}
                        <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/exams"
                          target="_blank">Exams</a><span>New</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="amber-Grid-col amber-w3hxsd">
                  <div class="amber-Grid-root amber-1duln8m">
                    <div class="amber-Grid-col amber-14tp5i1" style={{cursor:"default"}}>
                      <div class="amber-Text-root amber-an0j5c">Support</div>
                    </div>
                    <div class="amber-Grid-col amber-137asuq"><a class="amber-Text-root amber-Anchor-root amber-16o1n1h"
                        href="/help/en" target="_blank">Help Center</a></div>
                    <div class="amber-Grid-col amber-137asuq">
                      {/* <script
                        type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Contact","url":"/contact"}</script> */}
                      <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/contact"
                        target="_blank">Contact</a>
                    </div>
                    <div class="amber-Grid-col amber-137asuq">
                      {/* <script
                        type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Terms and Conditions","url":"/terms"}</script> */}
                      <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/terms" target="_blank">T&amp;C
                      </a>
                    </div>
                    <div class="amber-Grid-col amber-137asuq">
                      {/* <script
                        type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Privacy Policy","url":"/privacy"}</script> */}
                      <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="/privacy" target="_blank">Privacy
                        Policy</a>
                    </div>
                    <div class="amber-Grid-col amber-137asuq">
                      {/* <script
                        type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Sitemap","url":"/sitemap"}</script> */}
                      <a class="amber-Text-root amber-Anchor-root amber-16o1n1h" href="sitemap"
                        target="_blank">Sitemap</a>
                    </div>
                  </div>
                </div>
                <div class="amber-Grid-col amber-7h929z">
                  <div class="amber-Text-root amber-an0j5c"> Contact us</div>
                  <div class="amber-sa4ok2">
                    <div class="amber-Text-root amber-wowa3k">Follow us on:</div>
                    <div class="amber-prreq7">
                      <div class="amber-Text-root amber-xz93zd">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"LinkedIn","url":"https://www.linkedin.com/company/amberstudent"}</script> */}
                        <img src="images/Linkedin.svg?auto=format&amp;trim=auto" alt="app store" class="amber-qwqh6u"
                          width="22px" height="22px"/>
                      </div>
                      <div class="amber-Text-root amber-1mm9djt">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Facebook","url":"https://www.facebook.com/amberstudent"}</script> */}
                        <i class="icon-facebook"></i>
                      </div>
                      <div class="amber-Text-root amber-1391p51">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Instagram","url":"https://www.instagram.com/amberstudent/"}</script> */}
                        <img src="images/Instagram.svg?auto=format&amp;trim=auto" alt="app store" class="amber-qwqh6u"
                          width="22px" height="22px"/>
                      </div>
                      <div class="amber-Text-root amber-1gkm5fm">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Youtube","url":"https://www.youtube.com/channel/UCzm8GT3xWuXqQt9O7CFpqJw"}</script> */}
                        <i class="icon-youtube"></i>
                      </div>
                      <div class="amber-Text-root amber-1k92t2e">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Twitter","url":"https://twitter.com/amberstudent_"}</script> */}
                        <img src="images/Twitter.svg?auto=format&amp;trim=auto" alt="twitter icon" class="amber-qwqh6u"
                          width="22px" height="22px"/>
                      </div>
                      <div class="amber-Text-root amber-1391p51">
                        {/* <script
                          type="application/ld+json">{"@context":"https://schema.org","@type":"SiteNavigationElement","name":"Pinterest","url":"https://www.pinterest.com/amberstudent/"}</script> */}
                        <img src="images/pinterest.svg?auto=format&amp;trim=auto" alt="pinterest icon"
                          class="amber-qwqh6u" width="22px" height="22px"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </body>
  )
}

export default Amber